/*
 * This is so a superUser can add a Bio to a registered user who does not already have one started
 * Need to get userId of user
 * <SuperUserAddABio />
 *
 * Once a user is selected, check MongoDB to see if that user already has a bio
 * < />
 */
import React, { useCallback, useEffect, useState } from 'react'
import AllGrads from '../Components/AllGrads'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import { Redirect } from 'react-router-dom'
import Select from '@material-ui/core/Select'
import Snackbar from '../Atoms/Snackbar'
import { store } from '../Redux/Store'
import Typography from '@material-ui/core/Typography'
import { userIdOfBioToAddAction } from "../Redux/Actions"

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const SuperUserAddABio = () => {

   const classes = useStyles();

   const [ allGradsList, setAllGradsList ] = useState([])
   const [ redirectToAddOneBioPage, setRedirectToAddOneBioPage ] = useState(false)
   const [ snackbarOpen, setSnackbarOpen ] = useState(false)
   const [ userIdOfBioToAdd, setUserIdOfBioToAdd ] = useState('')

   /* Check to see if user already has a bio */
   useEffect( () => {
      if(userIdOfBioToAdd > 9){
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + `bio/${userIdOfBioToAdd}`)
         .then( res => res.data )
         .then( data => {
               if(data !== ''){
                  setSnackbarOpen(true)
                  setTimeout( () => {
                     setSnackbarOpen(false)
                  }, 2500 )
               }
               else{
                  store.dispatch(userIdOfBioToAddAction(userIdOfBioToAdd))
                  setRedirectToAddOneBioPage(true)
               }
               /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
               after a GET was made to the Node server. */
               axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
         } ) }
   }, [userIdOfBioToAdd] )

   const getAllGrads = useCallback((gradsFromAllGrads) => {
      setAllGradsList(gradsFromAllGrads)
   }, [] )

   const handleChange = (e) => {
      setUserIdOfBioToAdd(e.target.value)
   }

   const menuItemsToDisplay = allGradsList.map( grad => {
      return(<MenuItem key={grad.userId} value={grad.userId}>
                  { grad.lastName + ', ' +  grad.givenFirstName + ', ' + grad.tpsClass }
               </MenuItem>)
   } )

   return (
      <Box style={{marginTop: '7rem'}}>
         { redirectToAddOneBioPage ? <Redirect to='/AddOneBioPage' /> : null }
         <AllGrads getAllGrads={getAllGrads} />
         <Container>
               <Grid container justify='center'>
                  <Grid item xs={12}>
                     <Typography variant='h4'>
                           Add a Bio (SuperUsers Only)
                     </Typography>
                  </Grid>
                  <Grid item xs={12} style={{marginTop: '2rem'}}>
                     <FormControl variant="outlined" className={classes.formControl}>
                           <InputLabel id="demo-simple-select-outlined-label">Add a Bio to User</InputLabel>
                           <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={userIdOfBioToAdd}
                              onChange={handleChange}
                              label="Select a Bio to Edit"
                              style={{width: '400px'}}
                              >
                              <MenuItem value="">
                                 <em>None</em>
                              </MenuItem>
                              { menuItemsToDisplay }
                           </Select>
                     </FormControl>
                     {snackbarOpen ? <Snackbar msg='This user already has a bio' type='error' /> : null}
                  </Grid>
               </Grid>
         </Container>
      </Box>
   )
}

export default SuperUserAddABio
