/* This is the top level Page that has three Pages below it
 * 
 * <ManagePhotos />
 *      <ManageBioPhotos />
 * 
 *          <GetBioList /> was replaced by <GetBioListPartial />
 * 
 *          <MySelect />
 *          <AddBioPhoto />
 *          <ShowBioPhotoToManage />
 * 
 *      <ManageClassPatches />
 *          <MySelect />
 *          <AddClassPatch />
 *          <Snackbar />
 *          <ShowClassPatchesToManage />
 * 
 *      <ManageClassPhotos />
 *          <MySelect />
 *          <AddClassPhoto />
 *          <Snackbar />
 *          <ShowClassPhotosToManage />
 * 
 *      <ManageHistoryPhotos />
*/
import React, { useState } from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { photoDims } from '../config'
import { node_server } from '../Helpers'
import PropTypes from 'prop-types'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const AddClassPhoto = ({addClassPhotoToLocalState, classPhoto, tpsClass}) => {

    const [ caption, setCaption ] = useState('')
    const [ classPhotoWidth, setClassPhotoWidth ] = useState(photoDims.bioPhotoWidth)
    const [ filename, setFilename ] = useState('')

    const handleCaptionChange = (e) => {
        setCaption(e.target.value)
    }

    const handleFilenameChange = (e) => {
        setFilename(e.target.value)
    }

    const handleClassPhotoWidthChange = (e)  => {
        setClassPhotoWidth(e.target.value)
    }

    const handleSubmit = () => {
        if( classPhoto.tpsClass === undefined ){
            classPhoto.tpsClass = tpsClass
            classPhoto.classPhoto = {
                classPhotoCaption: caption,
                classPhotoFilename: filename,
                classPhotoIndex: 0,
                classPhotoWidth: classPhotoWidth
            }
            axios.post(node_server + 'classPhoto/AddClassPhoto',{
                classPhoto
            })
                .then( res => res.data )
                .then( data => {
                    setFilename('')
                    setCaption('')
                    addClassPhotoToLocalState(data)
                } )
        }
        else{
            classPhoto.classPhoto.push({
                classPhotoCaption: caption,
                classPhotoFilename: filename,
                classPhotoIndex: 0,
                classPhotoWidth: classPhotoWidth
            })

            axios.put(node_server + 'classPhoto/AddClassPhoto',{
                classPhoto
            })
                .then( res => res.data )
                .then( data => {
                    setFilename('')
                    setCaption('')
                    addClassPhotoToLocalState(data)
                } )
        }
    }

    return (
        <Grid style={{marginTop: '3rem'}} >
            <Container>
                <Grid>
                    <form>
                        <Typography variant='h4' >
                            Add Class Photo
                        </Typography>
                        <Grid container justify='space-between'>
                            <Grid item xs={12} sm={10} style={{marginTop: '1rem'}} >
                                <TextField value={filename} label='Filename' placeholder='Enter Filename Here' style={{width: '100%'}} onChange={handleFilenameChange} />
                            </Grid>
                            <Grid item xs={12} sm={1} style={{marginTop: '1rem'}} >
                                <TextField value={classPhotoWidth} label='Photo Width' style={{width: '100%'}} onChange={handleClassPhotoWidthChange} />
                            </Grid>
                        </Grid>
                        <Grid>
                            <TextareaAutosize value={caption} placeholder='Enter Photo Caption Here' rowsMin={4} style={{width: '100%', marginTop: '2rem'}} onChange={handleCaptionChange} />
                        </Grid>
                        <Grid container item justify='flex-start' style={{marginTop: '1.5rem'}} >
                            {filename.length > 0 && caption.length > 0 ?
                                <Button variant="contained" color="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            :
                                <Button disabled variant="contained" color="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            }
                        </Grid>
                    </form>
                </Grid>
            </Container>
        </Grid>
    )
}

AddClassPhoto.propTypes = {
    addClassPhotoToLocalState: PropTypes.func,
    classPhoto: PropTypes.array,
    tpsClass: PropTypes.string
}

export default AddClassPhoto
