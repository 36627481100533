/*
 * This page adds a story and is used by both alumni and superUsers
 *
 * <AddAStoryPage />
 *      <AddAStoryTitleComponent />
 *      <ShowAStoryTitle />
 *      <ShowAStoryComponent />
 *      <AddAStoryTextBlockComponent />
 */
import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'

const AddAStoryTitleComponent = ({handleStoryTitleChange}) => {

    const [ author, setAuthor ] = useState('')
    const [ synopsis, setSynopsis ] = useState('')
    const [ title, setTitle ] = useState('')

    const handleAuthorChange = (e) => { setAuthor(e.target.value) }
    const handleSynopsisChange = (e) => { setSynopsis(e.target.value) }
    const handleTitleChange = (e) => { setTitle(e.target.value) }
    const handleSubmit = () => { handleStoryTitleChange(author, synopsis, title) }

    return (
        <Box>
            <Container>
                <Grid container item xs={12} justify='space-between' style={{marginTop: '1rem'}}>
                    <TextField label='Story Title' variant='outlined' style={{width: '70%'}} onChange={handleTitleChange} />
                    <TextField label='Story Author' variant='outlined' style={{width: '25%'}} onChange={handleAuthorChange} />
                </Grid>
                <Grid style={{marginTop: '1rem'}} >
                    <TextareaAutosize placeholder='Story Synopsis' rowsMin={3} style={{width: '100%'}} onChange={handleSynopsisChange}/>
                </Grid>
                <Grid container style={{marginTop: '1rem'}}>
                    {author.length > 2 && title.length > 2 ?
                        <Button color='primary' variant='contained' onClick={handleSubmit} >
                            Submit
                        </Button>
                    :
                        <Button disabled color='primary' variant='contained' >
                            Submit
                        </Button>
                    }
                </Grid>
            </Container>
        </Box>
    )
}

AddAStoryTitleComponent.propTypes = {
    handleStoryTitleChange: PropTypes.func
}

export default AddAStoryTitleComponent
