import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const ShowNewRegisters = ({regArray}) => {

  const ids = regArray.map( (a, i) => {

    return (
      <Grid key={i}>
        <Typography variant='h5' align='left'>
          {a.userId} {a.username}
        </Typography>
      </Grid>
    )
  } )

  return (
    <Grid container item xs={12} sm={6} justify='flex-start' style={{marginTop: '1rem'}}>
      <Typography variant='h5'>
        New Registrations Are: {ids}
      </Typography>
    </Grid>
  )
}

ShowNewRegisters.propTypes = {
  regArray: PropTypes.array
}

export default ShowNewRegisters
