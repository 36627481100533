import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { store } from '../Redux/Store'
import Typography from '@material-ui/core/Typography'
import { userIdOfBioToShowAction } from '../Redux/Actions'

const NameFromBioList = props => {
    
    NameFromBioList.propTypes = {
      bio: PropTypes.object,
      index: PropTypes.number,
      userId: PropTypes.number,
    };

const handleClick = () => {
    const { userId } = props
    store.dispatch(userIdOfBioToShowAction(userId))
}

const { index } = props;
const { callsign, givenFirstName, lastName, militaryRank, preferredFirstName, tpsClass } = props.bio

let infoToDisplay = ''

// Build name
if (givenFirstName !== '') {
    infoToDisplay = lastName + ', ' + givenFirstName
}
else if (preferredFirstName !== '') {
    infoToDisplay = lastName + ', ' + preferredFirstName
}
else if (callsign !== '') {
    infoToDisplay = lastName + ', ' + callsign
}
else {
    infoToDisplay = lastName
}

// Add Rank if available
if (militaryRank !== '') {
    infoToDisplay += ', ' + militaryRank
}

// Add TPS class if available
if (tpsClass !== '') {
    infoToDisplay += ', TPS Class ' + tpsClass
}

return (
    <Grid item xs={12} style={{ marginBottom: '1rem' }} >
        <Link to='/ShowOneBioPage' onClick={handleClick} style={{ textDecoration: 'none' }}>
            <Typography style={{ textAlign: 'left' }} variant='h5'>
                { index + 1 }. {infoToDisplay}
            </Typography>
        </Link>
    </Grid>
)
}

export default NameFromBioList