/*
 * This is a Component on the the Top level Page ManagePhotoCollectionPhotos
 */
import React, { useState } from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { node_server } from '../Helpers'
import PropTypes from 'prop-types'
import Snackbar from '../Atoms/Snackbar'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const AddPhotoCollection = ({CheckToSeeIfACollectionWasAdded}) => {

    const [ description, setDescription ] = useState('')
    const [ name, setName ] = useState('')
    const [ showSnackbar, setShowSnackbar ] = useState(false)
    const [ snackBarMessage, setSnackBarMessage ] = useState('')
    const [ snackbarType, setSnackbarType ] = useState('success')

    const descriptionChange = (e) => {
        setDescription(e.target.value)
    }

    const nameChange = (e) => {
        setName(e.target.value)
    }

    const submit = () => {
        axios.post(node_server + 'PhotoCollection/AddPhotoCollection', {
            photoCollectionName: name,
            photoCollectionDescription: description
        }).then(res => {
            if (res.data.message === 'NEW Collection saved') {
                setDescription('')
                setName('')
                CheckToSeeIfACollectionWasAdded()
                setSnackBarMessage('New Collection Created!')
                setSnackbarType('success')
                setShowSnackbar(true)
                setTimeout(() => {
                    setSnackBarMessage('')
                    setSnackbarType('success')
                    setShowSnackbar(false)
                }, 2000)
            } else if (res.data.message === 'Collection already exists') {
                setSnackBarMessage('Collection already exists')
                setSnackbarType('error')
                setShowSnackbar(true)
                setTimeout(() => {
                    setSnackBarMessage('')
                    setSnackbarType('success')
                    setShowSnackbar(false)
                }, 2000)
            }
        })
    }

    return (
        <Grid>
            <Grid item xs={12}>
                <Typography variant='h6'>
                    Add New Photo Collection
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    onChange={nameChange}
                    label='Photo Collection Name'
                    value={name}
                    variant='outlined'
                    style={{
                        width: '60%'
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextareaAutosize
                    onChange={descriptionChange}
                    placeholder='Photo Collection Description'
                    rowsMin={10}
                    value={description}
                    style={{
                        marginTop: '1rem',
                        width: '60%'
                    }}
                />
            </Grid>
            <Grid container item xs={12} style={{width: '100%'}}>
                <Grid item xs={4}>
                    {description.length > 0 && name.length > 0 ?
                        <Button variant='contained' color='primary' style={{marginLeft: '8.5rem', marginTop: '1rem'}} onClick={submit} >
                            Submit
                        </Button>
                        :
                        <Button variant='contained' color='primary' disabled style={{marginLeft: '8.5rem', marginTop: '1rem'}} >
                            Submit
                        </Button>
                    }
                </Grid>
                <Grid item xs={5} style={{marginTop: '8px'}} >
                    {showSnackbar ?
                        <Snackbar msg={snackBarMessage} type={snackbarType} />
                        :
                        null
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

AddPhotoCollection.propTypes = {
    CheckToSeeIfACollectionWasAdded: PropTypes.func
}

export default AddPhotoCollection
