import React, { useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { node_server } from '../Helpers'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const EditYearbookPage = ({cancelEdit, pageData, topMargin}) => {

  const [ indexOfParaToEdit, setIndexOfParaToEdit ] = useState(-1)
  // const [ indexOfPhotoToEdit, setIndexOfPhotoToEdit ] = useState(-1)
  const [ layoutView, setLayoutView ] = useState(pageData.layoutView)
  const [ pageIndex, setPageIndex ] = useState(pageData.pageIndex)
  const [ pageName, setPageName ] = useState(pageData.pageName)
  const [ pageType, setPageType ] = useState(pageData.pageType)
  const [ para, setPara ] = useState(pageData.para)
  const [ paras, setParas ] = useState(pageData.paras)
  const [ photoAlignment, setPhotoAlignment ] = useState('photoAlignment')
  const [ photoCaption, setPhotoCaption ] = useState('')
  const [ photos, setPhotos ] = useState(pageData.photos)
  const [ photoFilename, setPhotoFilename ] = useState('')
  const [ showAddNewPara, setShowAddNewPara ] = useState(false)
  const [ showAddNewPhoto, setShowAddNewPhoto ] = useState(false)
  const [ showParas, setShowParas ] = useState(true)
  const [ showParaToEdit, setShowParaToEdit ] = useState(false)

  const handleAddNewPara = () => {
    setShowParas(false)
    setShowAddNewPara(true)
  }

  const handleAddNewPhoto = () => {
    setShowParas(false)
    setShowAddNewPhoto(true)
  }

  const handleCancelEdit = () => {
    cancelEdit()
  }

  const handleCancelEditPara = () => {
    setShowParaToEdit(false)
  }

  const handleCancelEditPhoto = () => {
    setShowAddNewPhoto(false)
    setShowParas(true)
  }

  const handleCancelNewPara = () => {
    setShowAddNewPara(false)
  }

  const handleEditParaClick = (i) => {
    setIndexOfParaToEdit(i)
    setPara(paras[i].paraText)
    setShowParas(false)
    setShowParaToEdit(true)
  }

  const handleLayoutViewChange = (e) => {
    setLayoutView(e.target.value)
  }

  const handlePageIndexChange = (e) => {
    setPageIndex(e.target.value)
  }

  const handlePageNameChange = (e) => {
    setPageName(e.target.value)
  }

  const handlePageTypeChange = (e) => {
    setPageType(e.target.value)
  }

  const handleParaChange = (e) => {
    setPara(e.target.value)
  }

  const handlePhotoAlignmentChange = (e) => {
    setPhotoAlignment(e.target.value)
  }

  const handlePhotoCaptionChange = (e) => {
    setPhotoCaption(e.target.value)
  }

  const handlePhotoFilenameChange = (e) => {
    setPhotoFilename(e.target.value)
  }

  const handleSubmitToDB = () => {
    const pageForDB = {
      layoutView,
      pageIndex,
      pageName,
      pageType,
      paras,
      photos
    }
    axios.put(node_server + `yearbook/updatePage/${pageData._id}`, pageForDB)
      .then( res => {
        console.log('EditYearbookPage res.data is ', res.data)
      })
  }

  const handleSubmitNewPara = () => {
    const newParas = [...paras, {paraText: para}]
    setParas(newParas)
    setPara('')
    setShowAddNewPara(false)
    setShowParas(true)
  }

  const handleSubmitNewPhoto = () => {
    const newPhotos = [...photos, {alignment: photoAlignment, caption: photoCaption, photoFilename: photoFilename}]
    setPhotos(newPhotos)
    setPhotoAlignment('')
    setPhotoCaption('')
    setPhotoFilename('')
    setShowAddNewPhoto(false)
    setShowParas(true)
  }

  const handleSubmitUpdatePara = () => {
    const updatedParas = paras
    updatedParas[indexOfParaToEdit] = {paraText: para}
    setParas(updatedParas)
    setShowParaToEdit(false)
    setShowParas(true)
  }

  const parasToDisplay = paras.map((p, i) => {
    return (
      <Grid container key={i} style={{marginTop: '2rem'}}>
        <Grid item xs={9}>
          <Typography align='left' display='block' gutterBottom variant='h6'>
            {p.paraText}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{marginLeft: '1rem'}}>
          <Button onClick={() => handleEditParaClick(i)}>Edit</Button>
        </Grid>
      </Grid>
    )
  })

  // const photosToDisplay = photos.map((p, i) => {
  //   return (
  //     <Grid container key={i} style={{marginTop: '2rem'}}>
  //       <Grid item xs={4}>

  //           {p.photoFilename}

  //       </Grid>
  //       <Grid item xs={1} style={{marginLeft: '1rem'}}>
  //         <Button onClick={() => handleEditPhotoClick(i)}>Edit</Button>
  //       </Grid>
  //     </Grid>
  //   )
  // })

  return (
    <Box>
      <Container>
        <Grid style={{marginTop: `${topMargin}rem`}}>
          
        <Grid container>
          <Grid item xs={12} sm={4}>
              <TextField
                label='Page Name'
                onChange={handlePageNameChange}
                value={pageName}
                variant='outlined'
                style={{width: '100%'}}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <TextField
                onChange={handlePageIndexChange}
                value={pageIndex}
                variant='outlined'
                style={{width: '60%'}}
              />
            </Grid>

            <Grid >
              <Select
                onChange={handlePageTypeChange}
                value={pageType}
                variant='outlined'
                style={{width: '15rem'}}
              >
                <MenuItem value={'pageType'}>Page Type</MenuItem>
                <MenuItem value={'mainPage'}>Main Page</MenuItem>
                <MenuItem value={'subPage'}>Sub Page</MenuItem>
                <MenuItem value={'subSubPage'}>Sub Sub Page</MenuItem>
              </Select>
            </Grid>

            <Grid style={{marginLeft: '2rem'}}>
              <Select
                onChange={handleLayoutViewChange}
                value={layoutView}
                variant='outlined'
                style={{width: '15rem'}}
              >
                <MenuItem value={'layoutView'}>Layout View</MenuItem>
                <MenuItem value={'mainView'}>Main View</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Grid container style={{marginTop: '2rem'}}>
            <Grid>
              <Button variant='outlined' onClick={handleCancelEdit}>Cancel</Button>
            </Grid>
            <Grid>
              <Button variant='outlined' onClick={handleSubmitToDB} style={{marginLeft: '2rem'}}>Submit to db</Button>
            </Grid>
            <Grid>
              <Button variant='outlined' onClick={handleAddNewPara} style={{marginLeft: '2rem'}}>Add New Paragraph</Button>
            </Grid>
            <Grid>
              <Button variant='outlined' onClick={handleAddNewPhoto} style={{marginLeft: '2rem'}}>Add New Photo</Button>
            </Grid>
          </Grid>

          {showAddNewPara ?
            <Grid container style={{marginTop: '2rem'}}>
              <Grid item xs={12} style={{marginBottom: '1rem'}}>
                <TextareaAutosize rowsMin='10' onChange={handleParaChange} style={{width: '100%'}}/>
              </Grid>
              <Grid>
                <Button variant='outlined' onClick={handleCancelNewPara}>Cancel</Button>
              </Grid>
              <Grid>
                <Button variant='outlined' onClick={handleSubmitNewPara} style={{marginLeft: '2rem'}}>Add New Paragraph</Button>
              </Grid>
            </Grid>
            : null
          }

          {showParaToEdit ?
            <Grid container style={{marginTop: '2rem'}}>
              <Grid item xs={12} style={{marginBottom: '1rem'}}>
                <TextareaAutosize rowsMin='10' value={para} onChange={handleParaChange} style={{width: '100%'}}/>
              </Grid>
              <Grid>
                <Button variant='outlined' onClick={handleCancelEditPara}>Cancel</Button>
              </Grid>
              <Grid>
                <Button variant='outlined' onClick={handleSubmitUpdatePara} style={{marginLeft: '2rem'}}>Submit Paragraph Edit</Button>
              </Grid>
            </Grid>
            : null
          }

          {showAddNewPhoto ?
            <Grid container style={{marginTop: '2rem'}}>
              <Grid item xs={12}>
                <TextField
                  label='Photo Filename'
                  onChange={handlePhotoFilenameChange}
                  value={photoFilename}
                  variant='outlined'
                  style={{width: '100%'}}
                />
              </Grid>

              <Grid container style={{marginTop: '1rem', marginBottom: '1rem'}}>
                <Grid item xs={8}>
                  <TextField
                    label='Photo Caption'
                    onChange={handlePhotoCaptionChange}
                    value={photoCaption}
                    variant='outlined'
                    style={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    onChange={handlePhotoAlignmentChange}
                    value={photoAlignment}
                    variant='outlined'
                    style={{width: '20rem'}}
                  >
                    <MenuItem value={'photoAlignment'}>Photo Alignment</MenuItem>
                    <MenuItem value={'photoLeft'}>Photo Left</MenuItem>
                    <MenuItem value={'photoCenter'}>Photo Center</MenuItem>
                    <MenuItem value={'photoRight'}>Photo Right</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid>
                <Button variant='outlined' onClick={handleCancelEditPhoto}>Cancel</Button>
              </Grid>
              <Grid>
                <Button variant='outlined' onClick={handleSubmitNewPhoto} style={{marginLeft: '2rem'}}>Submit New Photo</Button>
              </Grid>
            </Grid>
            : null
          }

          {showParas ?
            <Grid style={{marginTop: '2rem'}}>
              {parasToDisplay}
            </Grid>
            : null
          }

        </Grid>
      </Container>
    </Box>
  )
}

EditYearbookPage.propTypes = {
  cancelEdit: PropTypes.func,
  pageData: PropTypes.object,
  topMargin: PropTypes.number
}

export default EditYearbookPage
