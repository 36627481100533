/*
 * This is the top level page for showing a single user story
 * <ShowAStoryPage />
 */
import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { store } from '../Redux/Store'
import Typography from '@material-ui/core/Typography'

const ShowAStoryPage = props => {

    const { idOfStoryToShow, story } = store.getState()

    const singleStoryToShow = story.stories.filter( stories => {
        if(stories._id === idOfStoryToShow){return stories}
        else{ return null}
    } )

        const textBlocksToShow = singleStoryToShow[0].storyText.map( textBlock => {
            return( <Grid key={textBlock._id} item xs={12} style={{marginTop: '1rem'}}>
                        <Typography align='left' display='block' variant='h6' >
                            {textBlock.storyTextBlock}
                        </Typography>
                    </Grid>)
        } )

    return (
        <Box>
            <Container style={{marginBottom: '4rem'}}>
                <Grid style={{marginTop: '7rem', marginBottom: '2rem'}}>
                    <Typography variant='h5'>
                        {singleStoryToShow[0].storyTitle} by {singleStoryToShow[0].storyAuthor}
                    </Typography>
                </Grid>
                {textBlocksToShow}
            </Container>
        </Box>
    )
}

ShowAStoryPage.propTypes = {

}

export default ShowAStoryPage
