import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { node_server } from '../Helpers'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

const AddYearbookPage = ({numPages}) => {

  const [ fileName, setFileName ] = useState('')
  const [ layoutView, setLayoutView ] = useState('layoutView')
  const [ pageIndex, setPageIndex ] = useState(0)
  const [ pageName, setPageName ] = useState('')
  const [ pageType, setPageType ] = useState('pageType')

  const fileNameChange = (e) => {
    setFileName(e.target.value)
  }

  const layoutViewChange = (e) => {
    setLayoutView(e.target.value)
  }

  const pageIndexChange = (e) => {
    setPageIndex(e.target.value)
  }

  const pageNameChange = (e) => {
    setPageName(e.target.value)
  }

  const pageTypeChange = (e) => {
    setPageType(e.target.value)
  }

  const submitNewPage = () => {
    axios.post(node_server + 'yearbook/addNewPage', {
      fileName,
      layoutView,
      pageIndex,
      pageName,
      pageType
    })
      .then( res => {
        if (res.data.message === 'New Yearbook Page Added') {
          setLayoutView('layoutView')
          setPageIndex(pageIndex + 1)
          setPageName('')
          setPageType('pageType')
        }
      })
  }

  useEffect(() => {
    setPageIndex(numPages)
  }, [numPages])

  return (
    <Box>
      <Container>
        <Grid container style={{marginTop: '2rem'}}>

          <Grid>
            <TextField
              label='Page Name'
              onChange={pageNameChange}
              value={pageName}
              variant='outlined'
            />
          </Grid>

          <Grid >
            <TextField
              onChange={pageIndexChange}
              value={pageIndex}
              variant='outlined'
              style={{width: '60%'}}
            />
          </Grid>

          <Grid >
            <Select
              onChange={pageTypeChange}
              value={pageType}
              variant='outlined'
              style={{width: '15rem'}}
            >
              <MenuItem value={'pageType'}>Page Type</MenuItem>
              <MenuItem value={'classPageType'}>Class Page Type</MenuItem>
              <MenuItem value={'decadePageType'}>Decade Page Type</MenuItem>
              <MenuItem value={'mainPage'}>Main Page</MenuItem>
              <MenuItem value={'subPage'}>Sub Page</MenuItem>
              <MenuItem value={'subSubPage'}>Sub Sub Page</MenuItem>
            </Select>
          </Grid>

          <Grid style={{marginLeft: '2rem'}}>
            <Select
              onChange={layoutViewChange}
              value={layoutView}
              variant='outlined'
              style={{width: '15rem'}}
            >
              <MenuItem value={'layoutView'}>Layout View</MenuItem>
              <MenuItem value={'mainView'}>Main View</MenuItem>
              <MenuItem value={'pdfView'}>PDF View</MenuItem>
            </Select>
          </Grid>

          <Grid style={{marginLeft: '2rem'}}>
            <TextField
              label='Filename'
              onChange={fileNameChange}
              value={fileName}
              variant='outlined'
            />
          </Grid>

          <Grid container item justify='flex-start' xs={12} style={{marginTop: '1rem'}}>
            <Button
              onClick={submitNewPage}
              variant='contained'
            >
              Add New Page
            </Button>
          </Grid>

        </Grid>
      </Container>
    </Box>
  )
}

AddYearbookPage.propTypes = {
  numPages: PropTypes.number
}

export default AddYearbookPage
