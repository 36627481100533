import React, { useState } from 'react'
import axios from "axios"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { forgot_username_password } from '../Helpers'
import Grid from "@material-ui/core/Grid"
import { Redirect } from "react-router-dom"
import Snackbar from "../Atoms/Snackbar"
import TextField from "@material-ui/core/TextField"

const ForgotUsernamePassword = () => {

    const [ email, setEmail ] = useState('')
    const [ sendToHomePage, setSendToHomePage ] = useState(false)
    const [ snackBarMsg, setSnackBarMsg ] = useState('')
    const [ snackBarOpen, setSnackBarOpen ] = useState(false)
    const [ snackBarType, setSnackBarType ] = useState('')

    const handleChange = (e) => {
        setEmail(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        var getUsernameFormData = new FormData()

        getUsernameFormData.append("email", email)

        axios.post(forgot_username_password, getUsernameFormData)
            .then(res => res.data)
            .then(data => {

                if(data.msg === 'Email sent'){
                    setSnackBarMsg(`E-mail sent to ${email}`)
                    setSnackBarType('success')
                    setSnackBarOpen(true)
                    setTimeout(() => {
                        setSnackBarOpen(false)
                        setSendToHomePage(true)
                    }, 2500)
                }
                else if(data.msg === 'That email address was not found in the database' || data.msg === 'Error Sending E-mail'){
                    setSnackBarMsg("Error, please contact admin@usaftpsalumni.com for assistance")
                    setSnackBarType('error')
                    setSnackBarOpen(true)
                    setTimeout(() => {
                        setSnackBarOpen(false)
                        setSendToHomePage(true)
                    }, 5000)
                }
            })
    }

        return (
            <Box style={{marginTop: '4rem'}}>
                {sendToHomePage ?  <Redirect to="/" /> : null }
                <form>
                    <Grid container >
                        <Grid item md={12} >
                            <Grid container justify="center" alignContent="center" direction="row" spacing={2} style={{ marginTop: "5rem" }} >
                                <Grid item xs={12} md={3}>
                                    <TextField className="AddGrad-text-field" label="Enter E-mail to retrieve Username" name="email" value={email} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                {snackBarOpen ?
                    <Grid container justify="center" style={{ marginTop: "2rem" }}>
                        <Grid item sm={6}>
                            <Snackbar msg={snackBarMsg} type={snackBarType} />
                        </Grid>
                    </Grid>
                    : null
                }
            </Box>
        )
}
export default ForgotUsernamePassword