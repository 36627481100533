import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import YbInworkPage from '../yb/YbInworkPage'
import YbPageOne from '../yb/YbPageOne'
import YbArpsPageOne from '../yb/YbArpsPageOne'
import YbArpsFacultyList from '../yb/YbArpsFacultyList'
import YbClass1960B from '../yb/YbClass1960B'
import Pdf from '../Components/Pdf'

const ShowOneYearbookPage = ({pageData, returnToTOC}) => {

  const [ showYbInworkPage, setShowTbInworkPage ] = useState(false)

  useEffect( () => {
    if (
        (pageData.pageName    !== '45 Years')     &&
        (pageData.layoutView  !== 'pdfView')      &&
        (pageData.pageName    !== 'Class 1960B')  &&
        (pageData.pageName    !== 'ARPS')         &&
        (pageData.pageName    !== 'ARPS Faculty List from 1967')
      ){
      setShowTbInworkPage(true)
    }
  }, [pageData.layoutView, pageData.pageName] )
  return (
    <Grid>

      {pageData.pageName    === '45 Years'                    ? <YbPageOne pageData={pageData} returnToTOC={returnToTOC} />         : null}
      {pageData.layoutView  === 'pdfView'                     ? <Pdf pageData={pageData} returnToTOC={returnToTOC} />               : null}
      {pageData.pageName    === 'Class 1960B'                 ? <YbClass1960B pageData={pageData} returnToTOC={returnToTOC} />      : null}
      {pageData.pageName    === 'ARPS'                        ? <YbArpsPageOne pageData={pageData} returnToTOC={returnToTOC} />     : null}
      {pageData.pageName    === 'ARPS Faculty List from 1967' ? <YbArpsFacultyList pageData={pageData} returnToTOC={returnToTOC} /> : null}
      {showYbInworkPage                                       ? <YbInworkPage returnToTOC={returnToTOC} />                          : null}

    </Grid>
  )
}

ShowOneYearbookPage.propTypes = {
  pageData: PropTypes.object,
  returnToTOC: PropTypes.func
}

export default ShowOneYearbookPage
