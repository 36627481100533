/*
 * This is a renderless Component that GETS Announcements for the landing page. If is currently only called by
 * <LandingPageAnnouncements />
 */
import { useEffect } from 'react'
import axios from 'axios'
import { CLIENT_PUBLIC_TOKEN, get_announcements, node_server } from '../Helpers'
import PropTypes from 'prop-types'

const GetAnnountementsData = ({getData}) => {

   /* GET Announcements for the landing page
      res.data is { msg: 'get_announcements.php Is Up Man!', allAnnouncements: [ { MySQL data objects } ] } */
   axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
   useEffect( () => {
          axios.get(node_server + 'announcements')
          .then( res => {
            console.log('res.data is', res.data)
            getData(res.data)
          } )
   }, [getData] )

   return null

}

GetAnnountementsData.propTypes = {
   getData: PropTypes.func,
 }

export default GetAnnountementsData
