import React from 'react'
import { Typography } from '@material-ui/core' 

const LandingPageTPSPainting = props => {
   return (
      <div>
         <img
            className="Landing-pic-one"
            alt="Front of USAF Test Pilot School"
            src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/6fc93556-d1b7-43a6-a95d-5cca46e1092f/daexjp9-e143afd5-11e3-4bea-82d0-ccaab49bd2a2.jpg/v1/fill/w_1106,h_723,q_70,strp/usaf_test_pilot_school_edwards_afb_and_f_104n_by_douglascastleman_daexjp9-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTA0NiIsInBhdGgiOiJcL2ZcLzZmYzkzNTU2LWQxYjctNDNhNi1hOTVkLTVjY2E0NmUxMDkyZlwvZGFleGpwOS1lMTQzYWZkNS0xMWUzLTRiZWEtODJkMC1jY2FhYjQ5YmQyYTIuanBnIiwid2lkdGgiOiI8PTE2MDAifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.I9k6xiTnuHlpZd_cI7W4l2kWXXtsm0GSxU376-Lv_o0"
         />

         <Typography>
               Courtesy of Artist Douglas Castleman @ <a href="https://douglas-castleman.pixels.com/" target="_blank" rel="noreferrer noopener">Douglas Castleman</a>
         </Typography>
      </div>
   )
}

export default LandingPageTPSPainting
