/*
 * This is the Top Level Search Page
 * 
 * <Search />
 *      <MySelect />
 *      <SearchByName />
 *          <SearchByNameDisplay />
 */

import React from 'react'
import { allGradsAction } from "../Redux/Actions"
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { store } from "../Redux/Store"
import Typography from '@material-ui/core/Typography'
import { userIdOfBioToShowAction } from '../Redux/Actions'

const SearchByNameDisplay = ({ alumniArray, alumniHasBio, searchForAllAlumni }) => {

    const handleClick = (e) => {
        store.dispatch( allGradsAction([{userId: e}]) )
        if( e > 9 ){
            store.dispatch( userIdOfBioToShowAction( e ) )
        }
    }

    const alumniToDisplay = alumniArray.map( (alum, i) => {
        /* Returns and prints username w/o a link if user is NOT active */
        if( searchForAllAlumni && !alum.isActive ){
            return(<Grid container key={i} style={{marginTop: '0.25rem'}}>
                        <Grid item xs={12}>
                            <Typography  id={alum.userId} variant='h6' align='left' style={{marginLeft: '4rem'}} >
                                { alum.username }
                            </Typography>
                        </Grid>
                   </Grid>)
        }
        /* Returns and prints username WITH a link */
        else{
            return(<Grid container key={i} style={{marginTop: '0.25rem'}}>
                        <Grid item xs={12} sm={5} >
                            <Typography  variant='h6' align='left' style={{marginLeft: '4rem'}} >
                                { alum.username }
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={2} >
                            <Link to="/GetGradsByUserId" id={alum.userId} onClick={() => handleClick(alum.userId)} style={{textDecoration: 'none'}} >
                                <Button variant='outlined' color='primary'>
                                    <Typography  variant='body2' >
                                        Contact Info
                                    </Typography>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} sm={1} >
                            { alumniHasBio.includes(alum.userId) ?
                                <Button variant='outlined' color='secondary'>
                                    <Link to="/ShowOneBioPage" id={alum.userId} onClick={() => handleClick(alum.userId)} style={{textDecoration: 'none'}} >
                                        <Typography  variant='body2' >
                                            Bio
                                        </Typography>
                                    </Link>
                                </Button>
                                : null
                            }
                        </Grid>
                   </Grid>)
        }
    } )

    return (
        <div style={{marginTop: '4rem'}} >
            { alumniToDisplay }
        </div>
    )
}

SearchByNameDisplay.propTypes = {
    alumniArray: PropTypes.array,
    alumniHasBio: PropTypes.array,
    searchForAllAlumni: PropTypes.bool
}

export default SearchByNameDisplay
