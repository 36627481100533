/*
 * This is the second level component for Showing each TPS class, reading any available
 * Bio entries, and adding Bio entries if logged in as an alumni member who
 * has CRUD authority for that TPS class
 * 
 * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
 * 
 * Puts tpsClass from dropdown in useState Hook, and sends the currently selected class to the Store
 * 
 * <ClassPages />
 *      <ShowOneClassPage />
 *          <ShowGradsFromOneClass />
 *          <ShowBioEntriesFromOneClass />
 *          <AddRemoveButtons />
 *          <AddBioEntry />
 *          <EditBioEntry />
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { charCount } from '../Helpers'

const AddBioEntry = props => {

    const { addEntry, officialTitleCount, submitBioEntry } = props

    const [sectionTitle, setSectionTitle] = useState('')
    const [sectionText, setSectionText] = useState('')

    // These count the number of characters in the title and the Bio entry
    const [sectionTextCount, setSectionTextCount] = useState(0)

    const handleSectionTitleChange = (e) => {
        setSectionTitle(e.target.value)
    }

    const handleSectionTextChange = (e) => {
        if (e.target.value.length <= charCount) {
            setSectionText(e.target.value)
        }
        setSectionTextCount(e.target.value.length)
    }

    const handleSubmit = async () => {
        submitBioEntry(sectionText, sectionTitle)
        await setSectionText('')
        await setSectionTitle('')
        await setSectionTextCount(0)
    }

    let placeHolderText = "This section is limited to 2,400 characters per entry. The intent is to enter bios in small sections with as many sections added"
    placeHolderText = placeHolderText + " as needed. Once at least one entry is made, click on Hide Bio Entry to add Military Units if desired. Pictures can"
    placeHolderText = placeHolderText + " be added as well, but due to security concerns those pictures cannot be uploaded directly to the site. They should"
    placeHolderText = placeHolderText + " be sent along with a caption to admin@usaftpsalumni.com and they will appear shortly at the bottom of the bio."

    return (
        <Box style={{ marginTop: '3rem', marginBottom: '3rem' }} >
            {addEntry ?
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='h4'>
                            Add A Bio Entry
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: '3rem', marginBottom: '1rem' }} >
                        <TextField value={sectionTitle} name='Title' label='Title' variant='outlined' style={{ width: '100%' }} onChange={handleSectionTitleChange} />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                        {sectionTextCount <= charCount ?
                            <TextareaAutosize value={sectionText} name='bioEntry' placeholder={placeHolderText} rowsMin={5} style={{ width: '100%', fontSize: '1.5rem', padding: '0.5rem' }} onChange={handleSectionTextChange} />
                            :
                            <TextareaAutosize name='bioEntry' placeholder={placeHolderText} rowsMin={5} style={{ width: '100%', color: 'red', fontSize: '1.5rem', padding: '0.5rem' }} onChange={handleSectionTextChange} />
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='h6' style={{textAlign: 'left', marginBottom: '0.5rem'}}>
                            Characters Remaining: {2400 - sectionTextCount}
                        </Typography>
                    </Grid>

                    <Grid item>
                        {officialTitleCount > 0 ?
                            <Button variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
                            :
                            <Button variant='contained' color='primary' disabled onClick={handleSubmit}>Submit</Button>
                        }
                    </Grid>

                </Grid>
            </Container>
            : null }
        </Box>
    )
}

AddBioEntry.propTypes = {
    addEntry: PropTypes.bool,
    handleIncrementNumberOfBioEntries: PropTypes.func,
    handleSectionText: PropTypes.func,
    handleSectionTitle: PropTypes.func,
    officialTitleCount: PropTypes.number,
    submitBioEntry: PropTypes.func
}

export default AddBioEntry