/*
 * This is a renderless Component that GETS Announcements for the landing page. If is currently only called by
 * <LandingPageAnnouncements />
 */
import { useEffect } from 'react'
import axios from 'axios'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import PropTypes from 'prop-types'

const GetOneBio = ({getBio, id}) => {

  useEffect( () => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + `bio/${id}`)
         .then( res => {
            getBio(res.data)

            /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
            after a GET was made to the Node server. */
            axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
         } )
  }, [getBio, id] )

  return null

}

GetOneBio.propTypes = {
   getBio: PropTypes.func,
   id: PropTypes.number
 }

export default GetOneBio
