/*
 * This page adds a story and is used by both alumni and superUsers
 *
 * <AddAStoryPage />
 *      <AddAStoryTitleComponent />
 *      <ShowAStoryTitle />
 *      <ShowAStoryComponent />
 *      <AddAStoryTextBlockComponent />
 */
import React, { useEffect, useState } from 'react'
import AddAStoryTextBlockComponent from '../Components/AddAStoryTextBlockComponent'
import AddAStoryTitleComponent from '../Components/AddAStoryTitleComponent'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { node_server } from '../Helpers'
import ShowAStoryComponent from '../Components/ShowAStoryComponent'
import ShowAStoryTitle from '../Components/ShowAStoryTitle'
import { store } from '../Redux/Store'
import Typography from '@material-ui/core/Typography'

const AddAStoryPage = () => {

    const { userIdOfStoryToAdd } = store.getState()

    const [ hideTitleComponent, setHideTitleComponent ] = useState(false)
    const [ numUserStories, setNumUserStories ] = useState(0)
    const [ showStoryComponent, setShowStoryComponent ] = useState(false)
    const [ story, setStory ] = useState({})
    const [ storyAuthor, setStoryAuthor ] = useState('')
    const [ storySynopsis, setStorySynopsis ] = useState('')
    const [ storyText ] = useState([])
    const [ storyTitle, setStoryTitle ] = useState('')

    /* Check to see if user already has a Story in the DB. Data will equal "" if not user story exists, OTW an object will be returned */
    useEffect( () => {
        axios.get(node_server + `story/GetStory/${userIdOfStoryToAdd}`)
            .then( res => res.data )
            .then( data => {
                /* If a user Story already exists */
                if(data !== ''){
                    /* Store the object */
                    setStory(data)
                    // setStoryText(data.storyText)
                    // console.log('AddAStoryPage, user ', userIdOfStoryToAdd, ' has ', data.stories.length, ' stories and that is ', data)
                    /* This is used to index in the array */
                    setNumUserStories(data.stories.length)
                }
                /* If the user does NOT have a Story yet */
                // else{ console.log('AddAStoryPage, user ', userIdOfStoryToAdd, 'has no stories yet') }
            } )
    }, [userIdOfStoryToAdd] )

    /* This gets data from <AddAStoryTitleComponent inputs for a Story */
    const handleStoryTitleChange = (storyAuthor, storySynopsis, storyTitle) => {
        setStoryAuthor(storyAuthor)
        setStorySynopsis(storySynopsis)
        setStoryTitle(storyTitle)
            /* This user DOES already have a story, so PUT */
            if( numUserStories > 0 ){
                if(storyText.length !== 0){
                    // console.log('AddAStoryPage storyText.length is not === 0')
                    // var tempStoryText = storyText
                    // tempStoryText.push({storyTextBlock: textBlock})
                    story.stories[numUserStories] = {
                        storyAuthor: storyAuthor,
                        storySynopsis: storySynopsis,
                        storyTitle: storyTitle,
                        storyText: []
                    }
                }
                else{
                    // console.log('AddAStoryPage storyText is === 0')
                    story.stories[numUserStories] = {
                        storyAuthor: storyAuthor,
                        storySynopsis: storySynopsis,
                        storyTitle: storyTitle,
                        storyText: []
                    }
                    setHideTitleComponent(true)
                }

                // story.stories[numUserStories - 1].storyText.push({storyTextBlock: "I have a second entry"})
                axios.put(node_server + 'story/UpdateStory', story)
                    .then( res => res.data )
                    .then( data => {
                        setStory(data)
                        console.log('AddAStoryPage data is ', data)
                        return 1
                    } )
            }
            /* This user does NOT yet have a Story, so POST, an object will be returned and stored in a Hook, that object will look like:
               _id
               storyUserId
               stories[0]
                    _id is populated
                    storyAuthor is populated as it is requried
                    storyTitle is populated as it is required
                    storySynopsis is populated if suppied, OTW ""
                    storyPhoto is []
                    storyText is []
            */
            else{
                axios.post(node_server + 'story/AddStory', {
                    storyAuthor, storySynopsis, storyTitle, userIdOfStoryToAdd
                })
                .then( res => res.data )
                .then( data => {
                    // console.log('AddAStoryPage data is ', data)
                    // console.log('AddAStoryPage data.stories[0] is ', data.stories[0])
                    // setNumUserStories(numUserStories + 1)
                    setStory(data)
                    setHideTitleComponent(true)
                } )
            }
        setStoryTitle(storyTitle)
    }

    /* This submits a single text block to a story */
    const handleSubmitATextBlock = (textBlock) => {
        var tempStoryText = storyText
        tempStoryText.push({storyTextBlock: textBlock})
        story.stories[numUserStories] = {
            storyAuthor: storyAuthor,
            storySynopsis: storySynopsis,
            storyTitle: storyTitle,
            storyText: tempStoryText
        }
        // story.stories[numUserStories - 1].storyText.push({storyTextBlock: "I have a second entry"})
        axios.put(node_server + 'story/UpdateStory', story)
            .then( res => res.data )
            .then( data => {
                setStory(data)
                setShowStoryComponent(true)
                // console.log('AddAStoryPage data is ', data)
                return 1
            } )
    }

    const handleSubmitAStory = (story) => {

    }

    return (
        <Box style={{marginTop: '6rem'}}>
            <Container>
                <Grid item xs={12}>
                    <Typography variant='h4'>
                        Add a Story
                    </Typography>
                </Grid>

                {hideTitleComponent ? null : <AddAStoryTitleComponent handleStoryTitleChange={handleStoryTitleChange} />}
                {hideTitleComponent ? <ShowAStoryTitle storyTitle={storyTitle} /> : null}
                {showStoryComponent ? <ShowAStoryComponent story={story.stories[numUserStories]} /> : null}
                {hideTitleComponent ? 
                    <AddAStoryTextBlockComponent
                        handleSubmitAStory={handleSubmitAStory}
                        handleSubmitATextBlock={handleSubmitATextBlock}
                    />
                    : null
                }
            </Container>
        </Box>
    )
}

export default AddAStoryPage
