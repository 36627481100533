/*
 * Yearbook Table of Contents Component
 * Called by either <YearbookDisplayPage /> or <YearbookAdminPage />
 */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { node_server } from '../Helpers'
import PropTypes from 'prop-types'
import { store } from '../Redux/Store'
import Typography from '@material-ui/core/Typography'
import YearbookTPSClasses from './YearbookTPSClasses'

const YearbookTOC = ({getIndexOfPageToEdit, getIndexOfPageToShow, pageData}) => {

  const [ moveTOCButton, setMoveTOCButton ] = useState({})
  const { superUser } = store.getState()

  /* This takes the pageIndex of the page and returns its _id that is used in MongoDB */
  const getIdByIndex = (i) => {
    return pageData[i]._id
  }

  const downClick = (i) => {
    setMoveTOCButton({"currentLoc": i, "currentId": getIdByIndex(i), "newLoc": i + 1, "newId": getIdByIndex(i + 1)})
  }

  const editClick = (i) => {
    getIndexOfPageToEdit(i)
  }

  const pageClick = (i) => {
    getIndexOfPageToShow(i)
  }

  const upClick = (i) => {
    setMoveTOCButton({"currentLoc": i, "currentId": getIdByIndex(i), "newLoc": i - 1, "newId": getIdByIndex(i - 1)})
  }

  useEffect(() => {
    if (Object.keys(moveTOCButton).length > 0) {
      axios.put(node_server + 'yearbook/updatePageIndex', moveTOCButton)
        .then( res => res.data)

      setMoveTOCButton({})
    }
  }, [moveTOCButton] )

  const pagesToDisplay = pageData.map((page) => {
      if (page.pageType === 'mainPage') {
        return (
          <Grid container key={page._id} justify='flex-start'>
          <Button onClick={() => pageClick(page.pageIndex)}>
            <Paper style={{backgroundColor: 'lightgray'}}>
              <Typography variant='h4' style={{color: 'black', padding: '0.5rem'}}>
                {page.pageName}
              </Typography>
            </Paper>
          </Button>
          {superUser ? <Button onClick={() => editClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Edit</Button> : null}
          {superUser && (page.pageIndex === 0) ? <Button disabled onClick={() => upClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Up</Button> : null}
          {superUser && (page.pageIndex > 0) ? <Button onClick={() => upClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Up</Button> : null}
          {superUser && (page.pageIndex < pageData.length) ? <Button onClick={() => downClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Down</Button> : null}
          {superUser && (page.pageIndex === pageData.length - 1) ? <Button disabled onClick={() => downClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Down</Button> : null}
        </Grid>
        )
      } else if (page.pageType === 'subPage') {
        return (
          <Grid container key={page._id} justify='flex-start' style={{marginLeft: '3rem'}}>
          <Button onClick={() => pageClick(page.pageIndex)}>
            <Paper style={{backgroundColor: 'lightgray'}}>
              <Typography variant='h4' style={{color: 'black', padding: '0.5rem'}}>
                {page.pageName}
              </Typography>
            </Paper>
          </Button>
          {superUser ? <Button onClick={() => editClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Edit</Button> : null}
          {superUser && (page.pageIndex === 0) ? <Button disabled onClick={() => upClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Up</Button> : null}
          {superUser && (page.pageIndex > 0) ? <Button onClick={() => upClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Up</Button> : null}
          {superUser && (page.pageIndex < pageData.length) ? <Button onClick={() => downClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Down</Button> : null}
          {superUser && (page.pageIndex === pageData.length - 1) ? <Button disabled onClick={() => downClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Down</Button> : null}
        </Grid>
        )
      } else {
        return (
          <Grid container key={page._id} justify='flex-start' style={{marginLeft: '6rem'}}>
          <Button onClick={() => pageClick(page.pageIndex)}>
            <Paper style={{backgroundColor: 'lightgray'}}>
              <Typography variant='h4' style={{color: 'black', padding: '0.5rem'}}>
                {page.pageName}
              </Typography>
            </Paper>
          </Button>
          {superUser ? <Button onClick={() => editClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Edit</Button> : null}
          {superUser && (page.pageIndex === 0) ? <Button disabled onClick={() => upClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Up</Button> : null}
          {superUser && (page.pageIndex > 0) ? <Button onClick={() => upClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Up</Button> : null}
          {superUser && (page.pageIndex < pageData.length) ? <Button onClick={() => downClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Down</Button> : null}
          {superUser && (page.pageIndex === pageData.length - 1) ? <Button disabled onClick={() => downClick(page.pageIndex)} style={{marginLeft: '2rem'}}>Down</Button> : null}
        </Grid>
        )
      }
  })

  return (
    <Box>
      <Container>
        <Grid >
          <Paper style={{backgroundColor: 'gray'}}>
            <Typography variant='h4' style={{color: 'white', padding: '0.5rem'}}>
              Table of Contents
            </Typography>
          </Paper>
          
          {pagesToDisplay}

          <YearbookTPSClasses />

        </Grid>
      </Container>
    </Box>
  )
}

YearbookTOC.propTypes = {
  getIndexOfPageToEdit: PropTypes.func,
  getIndexOfPageToShow: PropTypes.func,
  pageData: PropTypes.array
}

export default YearbookTOC
