/*
 * This is the second level component for Showing each TPS class, reading any available
 * journal entries, and adding journal entries if logged in as an alumni member who
 * has CRUD authority for that TPS class
 * 
 * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
 * 
 * Puts tpsClass from dropdown in useState Hook, and sends the currently selected class to the Store
 * 
 * <ClassPages />
 *      <ShowOneClassPage />
 *          <ShowGradsFromOneClass />
 *          <ShowJournalEntriesFromOneClass />
 *          <AddRemoveButtons />
 *          <AddJournalEntry />
 *          <EditJournalEntry />
 *          <ShowClassPhoto />
 */

import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const ShowClassPhoto = props => {

    const { classPhoto } = props

    const { tpsClass } = classPhoto

    var classPhotosToDisplay = []

    if( classPhoto.classPhoto ){
        classPhotosToDisplay = classPhoto.classPhoto.map( (cP, index) => {

            var photoWidth = '100%'
            
            /* Allows for smaller photo sizes in cases of low resolution */
            if( cP.classPhotoWidth !== undefined ){
                photoWidth = cP.classPhotoWidth
            }

            return(<Grid key={cP._id} container>
                <Grid container item justify='center' style={{marginTop: '1rem'}} >
                  {cP.classPhotoFilename.startsWith('https://')
                  ?
                    <img
                        style={{width: `${photoWidth}`}}
                        alt={`${cP.tpsClass} ${index + 1}`}
                        src={`${cP.classPhotoFilename}`}
                    />
                  :
                    <img
                        style={{width: `${photoWidth}`}}
                        alt={`${cP.tpsClass} ${index + 1}`}
                        src={`/images/class-pictures/${tpsClass}/${cP.classPhotoFilename}`}
                    />
                  }
                </Grid>
                <Grid container item justify='center' >
                    <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'center', width: `${photoWidth}`}} >
                        { cP.classPhotoCaption }
                    </Typography>
                </Grid>
            </Grid>)
        } )
    }

    return (
        <Box style={{marginTop: '3rem'}} >
            <Container style={{marginBottom: '2rem'}} >
                { classPhotosToDisplay }
            </Container>
        </Box>
    )
}

ShowClassPhoto.propTypes = {
    classPhoto: PropTypes.object
}

export default ShowClassPhoto