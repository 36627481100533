/*
 * This is the Top level component for Showing One Bio via Nodejs / MongoDB
 * 
 * Calls the Nodejs server and gets the bio of a single user
 * 
 * <ShowOneBioPage />
 *      <ShowOfficialTitleForOneGrad />
 *      <ShowBioEntriesForOneGrad />
 *      <ShowMilitaryUnitsForOneGrad />
 *      <ShowUserStoryButtons />
 */
import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { idOfStoryToShowAction } from '../Redux/Actions'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { store } from '../Redux/Store'
import Typography from '@material-ui/core/Typography'

const ShowUserStoryButtons = ({story}) => {

    const [ redirectToShowAStoryPage, setRedirectToShowAStoryPage ] = useState(false)
    const [ showTitle, setShowTitle ] = useState(false)

    const { goodLogin } = store.getState()

    const handleClick = (id) => {
        store.dispatch(idOfStoryToShowAction(id))
        setRedirectToShowAStoryPage(true)
    }

    const storiesToDisplay = story.stories.map( story => {
        if(story.publicStory || goodLogin ){
            /* This only runs once. This is needed or an error is generated due to too many renders */
            if(!showTitle){ setShowTitle(true) }
            
            return( <Grid item xs={12} key={story._id}>
                <Button onClick={ () => {handleClick(story._id)} } style={{border: '1px solid black', marginTop: '1rem'}}>
                    <Typography variant='h6'>
                        {story.storyTitle} by {story.storyAuthor}
                    </Typography>
                </Button>
            </Grid>)
        }
        else{ return null }
    } )

    return (
        <Box style={{marginTop: '2rem', marginBottom: '2rem'}}>
            {redirectToShowAStoryPage ? <Redirect to='/ShowAStoryPage' /> : null}
            {showTitle ?
                <Typography variant='h5'>
                    Stories from this TPS Alum
                </Typography>
            : null}
            {storiesToDisplay}
        </Box>
    )
}

ShowUserStoryButtons.propTypes = {
    story: PropTypes.object
}

export default ShowUserStoryButtons
