import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

const MoreInfoClassGradDisplayForAdmin = ({ data, type }) => {

   let displayData = ''

   if (data.moreInfo) {
      displayData = data.moreInfo.map( (d) => {
         return (
            <Grid key={d._id}>
               <Typography align='left' >
                  <a href={d.urlFilename}>{d.itemTitle}</a>
               </Typography>
            </Grid>
         )
      } )
   }

   return (
      <Grid item xs={12} sm={6}>
         {Object.keys(data).length === 0 ?
            <Typography align='left' >
               This {type} has no Additional Info
            </Typography>
            :
            <Grid>
            <Typography align='left' >
               {data.title} Additional Info
            </Typography>
            { displayData }
            </Grid>
         }
      </Grid>
   )
}

MoreInfoClassGradDisplayForAdmin.propTypes = {
   data: PropTypes.object,
   type: PropTypes.string
}

export default MoreInfoClassGradDisplayForAdmin