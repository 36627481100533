/*
 * This component is called by <Navbar />
 */
import React, { useEffect } from 'react'
import axios from 'axios'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import PropTypes from 'prop-types'

const DoesUserHaveABioComponent = ({doesUserHaveABioReport, signedInUserId}) => {

   useEffect( () => {
      if (signedInUserId > 9) {
         axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
         axios.get(node_server + `bio/DoesUserHaveABio/${signedInUserId}`)
         .then( res => res.data )
         .then( data => {
            doesUserHaveABioReport(data.hasABio)
            /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
            after a GET was made to the Node server. */
            axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
         } )
      }
   }, [doesUserHaveABioReport, signedInUserId] )

   return (
      <div>
         
      </div>
   )
}

DoesUserHaveABioComponent.propTypes = {
   doesUserHaveABioReport: PropTypes.func,
   signedInUserId: PropTypes.number
}

export default DoesUserHaveABioComponent
