import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import { Typography } from '@material-ui/core'

const MoreInfoComponent = ({tpsClass, userId}) => {

   const [ moreInfo, setMoreInfo ] = useState([])
   const [ title, setTitle ] = useState('')

   useEffect( () => {
      let route = ''
      /* if userId is undefined than it is a TPS Class */
      if(userId === undefined){route = tpsClass}
      else{route = userId}
      
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + `moreInfo/${route}`)
         .then( res => {
            const d = res.data.user[0]
            if(d !== undefined){
               setTitle(d.title)
               setMoreInfo(d.moreInfo)
            }
            /* This is so nothing shows up if the class does not have any additional info in the database */
            else{
               setTitle('')
               setMoreInfo([])
            }
            axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
         })
   }, [tpsClass, userId] )

   const itemsToDisplay = moreInfo.map( (m, i) => {
      if(m.urlFilename.includes('http')){
         return (
            <Grid key={i}>
               <Grid container style={{marginTop: '0.5rem', marginLeft: '2rem'}}>
                  <Typography variant='h6'>
                     <a href={m.urlFilename} target="_blank" rel="noopener noreferrer" >
                     {m.itemTitle} ({m.mediaType})
                     </a>
                  </Typography>
               </Grid>
            </Grid>
         )
      }
      return null
   } )
  

   return (
      <Grid container>
         {title.length > 0 ?
            <Grid container style={{marginTop: '3rem'}}>
               <Typography variant='h6'>
                  {title} Additional Info
               </Typography>
            </Grid>
            : null
         }
         <Grid>
            {itemsToDisplay}
         </Grid>
      </Grid>
   )
}

export default MoreInfoComponent
