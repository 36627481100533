/*
 * This is a 2nd level component for Showing the Bio Entries for one grad via Nodejs / MongoDB
 * 
 * <EditOneBioPage />
 *      <ShowOfficialTitleForOneGrad />
 *      <EditOfficialTitleForOneGrad />
 *      <ShowBioEntriesForOneGrad />
 *      <EditBioEntriesForOneGrad />
 *      <ShowMilitaryUnitsForOneGrad />
 *      <EditMilitaryUnitForOneGrad />
 *      <AddRemoveButtons />
 *      <AddBioEntry />
 *      <AddRemoveButtons />
 *      <AddMilitaryUnitToBio />
 */

import React from 'react'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Grid from "@material-ui/core/Grid"
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import { store } from '../Redux/Store'
import Typography from "@material-ui/core/Typography"

const ShowBioEntriesForOneGrad = props => {

    const { bioEntry, handleEditBioEntryForOneGrad, moveBioEntryDown, moveBioEntryUp, showEditButtons } = props
    const { signedInUserId, superUser, userIdOfBioToEdit } = store.getState()

    const handleEdit = (bioEntryIdToEdit) => {
        handleEditBioEntryForOneGrad(bioEntryIdToEdit)
    }

    const handleMoveUp = (index) => {
        moveBioEntryUp(index)
    }

    const handleMoveDown = (index) => {
        moveBioEntryDown(index)
    }

    var bioEntriesToDisplay = null

    if (bioEntry !== undefined) {
        bioEntriesToDisplay = bioEntry.map( (bioEntry, index, array) => {
            return (<Grid key={bioEntry._id}>
                <Grid container>

                    <Grid container item xs={10} alignItems='center'>
                        <Typography variant='h5' style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            {bioEntry.title}
                        </Typography>
                    </Grid>
                    <Grid container item xs={2} alignItems='center'>
                        {((signedInUserId === userIdOfBioToEdit) || superUser) && showEditButtons ?
                            <IconButton onClick={() => handleEdit(bioEntry._id)}>
                                <Typography variant='h6'>
                                    Edit
                                    </Typography>
                            </IconButton>
                            : null
                        }
                        {((signedInUserId === userIdOfBioToEdit) || superUser) && showEditButtons && index > 0 ?
                            <IconButton onClick={() => handleMoveUp(index)}>
                                <ArrowUpwardIcon />
                            </IconButton>
                            : null
                        }
                        {((signedInUserId === userIdOfBioToEdit) || superUser) && showEditButtons && index === 0 ?
                            <IconButton disabled >
                                <ArrowUpwardIcon />
                            </IconButton>
                            : null
                        }
                        {((signedInUserId === userIdOfBioToEdit) || superUser) && showEditButtons && (index < array.length - 1) ?
                            <IconButton onClick={() => handleMoveDown(index)}>
                                <ArrowDownwardIcon />
                            </IconButton>
                            : null
                        }
                        {((signedInUserId === userIdOfBioToEdit) || superUser) && showEditButtons && (index === array.length - 1) ?
                            <IconButton disabled >
                                <ArrowDownwardIcon />
                            </IconButton>
                            : null
                        }
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{marginBottom: '2rem'}} >
                    <Typography variant='h6' style={{ textAlign: 'left' }}>
                        {bioEntry.text}
                    </Typography>
                </Grid>
            </Grid>)
        })
    }

    return (
        <Grid>
            {bioEntriesToDisplay}
        </Grid>
    )
}

ShowBioEntriesForOneGrad.propTypes = {
    bioEntry: PropTypes.array,
    handleEditBioEntryForOneGrad: PropTypes.func,
    moveBioEntryDown: PropTypes.func,
    moveBioEntryUp: PropTypes.func,
    showEditButtons: PropTypes.bool
}

export default ShowBioEntriesForOneGrad
