/*
 * Called by <GradCard />
 * handleDelete starts the cascade
 */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import { CLIENT_PUBLIC_TOKEN, deleteUser, get_all_data_for_one_user, node_server, updateAfterDeleteGrad } from '../Helpers'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography';

const DeleteWarning = ({close, userId}) => {

   const [ deletedGrad, setDeletedGrad ] = useState({})
   const [ runDeleteMySQLData, setRunDeleteMySQLData ] = useState(false)
   const [ runSaveUserData, setRunSaveUserData ] = useState(false)
   const [ runSetPublicBioToFalse, setRunSetPublicBioToFalse ] = useState(false)

   /* A user is deleting all of their data from MySQL, get a copy and store in 
      local state for eventual dispatch to MongoDB */
   const handleDeleteGrad = async () => {
      var getUserDataForm = new FormData()
      getUserDataForm.append('userId', userId)
      await axios.post(get_all_data_for_one_user, getUserDataForm)
         .then( res => {
               setDeletedGrad(res.data)
         })
         .then( data => {
               setRunSaveUserData(true)
         })
   }

   /* useEffect #1
      Save deleted data to MongoDB, this useEffect (#1) runs after handleDeleteGrad sets a Boolean */
   useEffect( () => {
      if(runSaveUserData){
         axios.post(node_server + 'deletedGrad', deletedGrad)
               .then( res => {
                  setRunDeleteMySQLData(true)
                  return null
               })
      }
   }, [deletedGrad, runSaveUserData] )

   /* useEffect #2
      This section deletes all user data from the MySQL database except that in tbl_auth
      useEffect (#2) runs after useEfffect #1 which sets a Boolean to run this one */
   useEffect( () => {
      if(runDeleteMySQLData){
         let deleteUserForm = new FormData()

         deleteUserForm.append('userId', userId)
         
         axios.post(deleteUser, deleteUserForm)
               .then( res => res.data )
               .then( data => {
                  setRunSetPublicBioToFalse(true)
               })
      }
   }, [runDeleteMySQLData, userId] )

   /* useEffect #3
      This checks the MongoDB to see if the user deleting their info has a bio or not. If
      they do and that bio is public, it changes the bio to private so only logged in alumni
      members can see the bio.
      useEffect (#3) runs after useEffect #2 which sets a Boolean to run this one */
   useEffect( () => {
      if(runSetPublicBioToFalse){
         axios.put(node_server + `bio/publicBioToggle/${userId}`, {CLIENT_PUBLIC_TOKEN})
               .then( res => res.data )
               .then( data => {
                  updateAfterDeleteGrad(userId)
                  return null
               } )
      }
   }, [runSetPublicBioToFalse, userId] )

   return (
      <Box>
         <Grid container style={{padding: '2rem'}}>
            <Grid item xs={12}>
               <Typography data-testid='DeleteWarning-header-text'>
                  This action will delete all of your personal information from the database. Your account will remain active, and you will still be able to login
                  to this website. If you filled out an Alumni Biography, that information will be retained in the database, but will no longer be visible to the
                  general public.
               </Typography>
            </Grid>
            <Grid container style={{margin: '1rem'}} >
               <Grid item xs={6}>
                  <Button
                        onClick={ () => { close() } }
                        data-testid='DeleteWarning-cancel-button'
                  >
                        <Typography data-testid='DeleteWarning-cancel-button-text'>
                           Cancel
                  </Typography>
                  </Button>
               </Grid>
               <Grid item xs={6}>
                  <Button 
                        onClick={handleDeleteGrad}
                        data-testid='DeleteWarning-delete-button'
                  >
                        <Typography ata-testid='DeleteWarning-delete-button-text'>
                           Delete
                  </Typography>
                  </Button>
               </Grid>
            </Grid>
         </Grid>
      </Box>
   )
}

DeleteWarning.propTypes = {
   close: PropTypes.func,
   userId: PropTypes.number
}

export default DeleteWarning
