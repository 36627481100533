import React, { useEffect, useState } from 'react'
import './CommandantBio.css'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'

const CommandantBio = () => {

   const [ text, setText ] = useState([])

   // Get Text from DB
   useEffect( () => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + 'text/CommandantBio')
            .then( res => res.data )
            .then( data => {
               setText(data.pageText)
               /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
                  after a GET was made to the Node server. */
               axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
            })
   }, [] )

   return (
      <Box>
      <Container>

         <Grid container style={{marginTop: '6rem'}} justify='center'>
            <Typography variant='h4'>
            { text[0] }
            </Typography>
         </Grid>

         <Grid container justify='center'>
            <img className='commandant-bio-photos'
               alt={ text[1] }
               src={ text[2] }
            />
         </Grid>

         <Grid className='commandant-bio-photos' container justify='center'>
            <img className='commandant-bio-photos'
               alt={ text[3] }
               src={ text[4] }
            />
         </Grid>

      </Container>
      </Box>
   )
}

export default CommandantBio
