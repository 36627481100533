/*
 * This page adds a story and is used by both alumni and superUsers
 *
 * <AddAStoryPage />
 *      <AddAStoryTitleComponent />
 *      <ShowAStoryTitle />
 *      <ShowAStoryComponent />
 *      <AddAStoryTextBlockComponent />
 */
import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const ShowAStoryComponent = ({story}) => {

    // console.log('ShowAStoryComponent story is ', story)

    const storyToShow = story.storyText.map( text => {
        return( <Typography align='left' display='block' key={text._id} variant='h6' style={{marginTop: '1rem', marginBottom: '1rem'}}>
                    {text.storyTextBlock}
                </Typography>)
    } )

    return (
        <Box>
            <Container>
                <Grid>
                    {storyToShow}
                </Grid>
            </Container>            
        </Box>
    )
}

ShowAStoryComponent.propTypes = {
    story: PropTypes.object
}

export default ShowAStoryComponent
