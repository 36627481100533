/*
 * This is a renderless Component that GETS Class Leader and Class Award data
 * from the PHP server for a single TPS class. If is currently only called by
 * <ClassLeaderAwardDataPage />
 * <PersonalAndClassState />
 */
import { useEffect } from 'react'
import axios from 'axios'
import { node_server } from '../Helpers'
import PropTypes from 'prop-types'

const GetClassLeaderAwardData = ({getClassData, tpsClass}) => {

  /* GET Class Leader and Award Data for selected class
     The IF is in case a class has not been selected by ClassLeaderAwardDataPage
     res.data is { msg: 'Good Data Retrieval', classData: { MongoDB data object } } */
  useEffect( () => {
     if(tpsClass !== '') {
         axios.get(node_server + `classLeaderAwardData/${tpsClass}`)
         .then( res => {
            getClassData(res.data)
         } )
     }
  }, [getClassData, tpsClass] )

  return null

}

GetClassLeaderAwardData.propTypes = {
   getClassData: PropTypes.func,
   tpsClass: PropTypes.string
 }

export default GetClassLeaderAwardData
