export function announcementIdAction(announcementId){
    return {
        type: "ANNOUNCEMENT_ID",
        announcementId: announcementId
    }
}

export function allGradsAction(allGrads){
    return {
        type: "ALL_GRADS",
        allGrads: allGrads
    }
}

export function authorizedToCrudAnnouncementsAction(BOOL){
    return {
        type: "AUTHORIZED_TO_CRUD_ANNOUNCEMENTS",
        authorizedToCrudAnnouncements: BOOL
    }
}

export function bioTblUserDataAction(bio_tbl_user_data){
    return {
        type: "BIO_TBL_USER_DATA",
        bio_tbl_user_data: bio_tbl_user_data
    }
}

export function classDataAction(classData){
// console.log('STORE ACTION clasData is', classData)
    return {
        type: "CLASS_DATA",
        classData: classData
    }
}

export function classPageAction(BOOL){
    return {
        type: "CLASS_PAGE",
        classPage: BOOL
    }
}

export function goodLoginAction(BOOL){
    return {
        type: "GOOD_LOGIN",
        goodLogin: BOOL
    }
}

export function hasBioAction(BOOL){
    return {
        type: "HAS_BIO",
        hasBio: BOOL
    }
}

export function idOfStoryToShowAction(id){
    // console.log('Action idOfStoryToShowAction id is ', id)
    return {
        type: "ID_OF_STORY_TO_SHOW",
        idOfStoryToShow: id
    }
}

export function isActiveAction(BOOL){
    return {
        type: "IS_ACTIVE",
        isActive: BOOL
    }
}

export function isAdminAction(BOOL){
    return {
        type: "IS_ADMIN",
        admin: BOOL
    }
}

export function isAlumniAction(BOOL){
    return {
        type: "IS_ALUMNI",
        alumni: BOOL
    }
}

export function isSuperUserAction(BOOL){
    return {
        type: "IS_SUPER_USER",
        superUser: BOOL
    }
}

export function storeJwtAction(jwt){
    return {
        type: "STORE_JWT",
        jwt: jwt
    }
}

export function logoutAction(){
    return {
        type: "LOGOUT",
        logout: true
    }
}

export function phpFileNameAction(phpFileName){
    return {
        type: "PHP_FILENAME",
        phpFileName: phpFileName
    }
}

export function resetPasswordAction(BOOL){
    return {
        type: "RESET_PASSWORD",
        resetPassword: BOOL
    }
}

export function showAlumniBioNavbarButtonAction(BOOL){
    return {
        type: "SHOW_ALUMNI_BIO_NAVBAR_BUTTON",
        showAlumniBioNavbarButton: BOOL
    }
}
export function showClassPagesNavbarButtonAction(BOOL){
    return {
        type: "SHOW_CLASS_PAGE_NAVBAR_BUTTON",
        showClassPagesNavbarButton: BOOL
    }
}
export function showHistoryNavbarButtonAction(BOOL){
    return {
        type: "SHOW_HISTORY_NAVBAR_BUTTON",
        showHistoryNavbarButton: BOOL
    }
}
export function showHomeNavbarButtonAction(BOOL){
    return {
        type: "SHOW_HOME_NAVBAR_BUTTON",
        showHomeNavbarButton: BOOL
    }
}
export function showMenuNavbarButtonAction(BOOL){
    return {
        type: "SHOW_MENU_NAVBAR_BUTTON",
        showMenuNavbarButton: BOOL
    }
}

export function signedInUserIdAction(userId){
    // console.log('signedInUserIdAction userId is ', userId)
    return {
        type: "SIGNED_IN_USER_ID",
        signedInUserId: userId
    }
}

export function storyAction(story){
    // console.log('Story Action is ', story)
    return {
        type: "STORY",
        story: story
    }
}

export function tpsClassAction(tpsClass){
    return {
        type: "TPS_CLASS",
        tpsClass: tpsClass
    }
}

export function updateAbHontsAction(data){
    // console.log('ACTION: updateAbHonts data is', data)
    /* data is an object {
        abHontsName: '',
        abHontsUserId: ''
    } */
    return {
        type: "UPDATE_AB_HONTS",
        abHonts: data
    }
}

export function updateCDotAction(data){
    // console.log('ACTION: updateCDot data is', data)
    /* data is an object {
        cDotName: '',
        cDotUserId: ''
    } */
    return {
        type: "UPDATE_C_DOT",
        cDot: data
    }
}

export function updateClassLeaderAction(data){
    // console.log('ACTION: updateClassLeader data is', data)
    /* data is an object {
        classLeaderName: '',
        classLeaderUserId: ''
    } */
    return {
        type: "UPDATE_CLASS_LEADER",
        classLeader: data
    }
}

export function updateDistinguishedAlumnusAction(data){
    // console.log('ACTION: updateDistinguishedAlumnus data is', data)
    /* data is an object {
        distinguishedAlumnusName: '',
        distinguishedAlumnusUserId: ''
    } */
    return {
        type: "UPDATE_DISTINGUISHED_ALUMNUS",
        distinguishedAlumnus: data
    }
}

export function updateLiethenTittleAction(data){
    // console.log('ACTION: updateLiethenTittle data is', data)
    /* data is an object {
        liethenTittleName: '',
        liethenTittleUserId: ''
    } */
    return {
        type: "UPDATE_LIETHEN_TITTLE",
        liethenTittle: data
    }
}

export function updateOnizukaPropWashAction(data){
    // console.log('ACTION: updatePropWash data is', data)
    /* data is an object
    {
        onizukaPropWashName: '',
        onizukaPropWashUserId: ''
    }*/
    return {
        type: "UPDATE_ONIZUKA_PROP_WASH",
        onizukaPropWash: data
    }
}

export function updateOutstandingFTEAction(data){
    // console.log('ACTION: updateOutstandingFTE data is', data)
    /* data is an object
    {
        outstandingFTEName: '',
        outstandingFTEUserId: ''
    }*/
    return {
        type: "UPDATE_OUTSTANDING_FTE",
        outstandingFTE: data
    }
}

export function updatePropWashAction(data){
    // console.log('ACTION: updatePropWash data is', data)
    /* data is an object
    {
        propWashName: '',
        propWashUserId: ''
    } */
    return {
        type: "UPDATE_PROP_WASH",
        propWash: data
    }
}

export function updateRaymondJonesAction(data){
    // console.log('ACTION: updateRaymondJones data is', data)
    /* data is an object
    {
        RaymondJonesName: '',
        RaymondJonesUserId: ''
    } */
    return {
        type: "UPDATE_RAYMOND_JONES",
        raymondJones: data
    }
}

export function userIdAction(userId){
    return {
        type: "USER_ID",
        userId: userId
    }
}

export function userIdOfBioToAddAction(userId){
    // console.log('userIdOfBioToAddAction userId is ', userId)
    return {
        type: "USER_ID_OF_BIO_TO_ADD",
        userIdOfBioToAdd: userId
    }
}

export function userIdOfBioToEditAction(userId){
    // console.log('userIdOfBioToEditAction userId is ', userId)
    return {
        type: "USER_ID_OF_BIO_TO_EDIT",
        userIdOfBioToEdit: userId
    }
}

export function userIdOfBioToShowAction(userId){
    // console.log('userIdOfBioToShowAction userId is ', userId)
    return {
        type: "USER_ID_OF_BIO_TO_SHOW",
        userIdOfBioToShow: userId
    }
}

export function userIdOfProfileToEditAction(userId){
    // console.log('userIdOfProfileToEditAction userId is ', userId)
    return {
        type: "USER_ID_OF_PROFILE_TO_EDIT",
        userIdOfProfileToEdit: userId
    }
}

export function userIdOfStoryToAddAction(userId){
    // console.log('userIdOfStoryToAddAction userId is ', userId)
    return {
        type: "USER_ID_OF_STORY_TO_ADD",
        userIdOfStoryToAdd: userId
    }
}

export function userIdAuthorizedToCrudWeeklyUpdateAction(userId){
    return {
        type: "USERID_AUTHORIZED_TO_CRUD_WEEKLY_UPDATE",
        userIdAuthorizedToCrudWeeklyUpdate: userId
    }
}

export function usernameAction(username){
    return {
        type: "USERNAME",
        username: username
    }
}

export function userTypeAction(userType){
    return {
        type: "USER_TYPE",
        userType: userType
    }
}