import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
// import smGenBoyd from '../images/yb/pageOne/sm-gen.boyd.png'
// import smLtGenDonaldPutt from '../images/yb/pageOne/sm-lt-gen-donald-putt.jpeg'
// import smMcCookFieldAerialView from '../images/yb/pageOne/sm-mccook.field.aerial.view.png'
// import smMcCookFieldGroundView from '../images/yb/pageOne/sm-mccook.field.ground.view.png'
// import smTestPilotsAtWrightField from '../images/yb/pageOne/sm-test.pilots.at.wright.field.png'
// import smWrightFieldAerialView from '../images/yb/pageOne/sm-wright.field.aerial.view.png'
// import testFlightGraph from '../images/yb/pageOne/test.flight.graph.png'
// import smInformalTestInstruction from '../images/yb/pageOne/sm-informal.test.instruction.png'
// import pilotAircraftQualificationBook from '../images/yb/pageOne/pilot.aircraft.qualificationBook.png'
import { LtGenDonaldPuttOfficialUSAFBio } from '../Helpers'
import { MajGenAlbertBoydOfficialUSAFBio } from '../Helpers'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

const YbPageOne = ({pageData, returnToTOC}) => {

  const handleReturnToTOC = () => {
    returnToTOC()
  }

  return (
    <Grid container>

      {/* Page Title */}
      <Grid item xs={12}>
        <Typography variant='h4'>
          <Box letterSpacing={2}>
            {pageData.pageName}
          </Box>
        </Typography>
      </Grid>

      {/* Photo of Maj Gen Boyd */}
      <Grid container>
        <Grid item xs={12} style={{marginTop: '1rem'}}>
          <a
            href={MajGenAlbertBoydOfficialUSAFBio}
            target='_blank'
            rel="noopener noreferrer"
          >
            <img
              // src={`${smGenBoyd}`}
              src={`${pageData.photos[0].photoFilename}`}
              alt='albert-boyd'
              style={{width: '80%', height: '80%', border: '5px solid rgb(34,28,110)', padding: '0.25rem'}}
            />
          </a>
        </Grid>
        <Grid container item xs={12} justify='center' style={{marginTop: '-10rem'}}>
          <Typography variant='h6' align='justify' component='div' style={{width: '80%'}}>
            <Box fontFamily='Roboto'>
              {pageData.photos[0].caption}
            </Box>
          </Typography>
        </Grid>
      </Grid>

      {/* Two paras side-by-side */}
      <Grid container style={{marginTop: '-2rem'}} >
        <Grid item xs={12} sm={6}>
          <Grid item xs={12} style={{marginRight: '2rem'}}>
            <Typography variant='h6' align='justify' component='div'>
              <Box fontFamily='Roboto'>
                {pageData.paras[0].paraText}
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid item xs={12} style={{marginLeft: '2rem'}}>
            <Typography variant='h6' align='justify' component='div'>
              <Box fontFamily='Roboto'>
                {pageData.paras[1].paraText}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* One full width para */}
      <Grid item xs={12} style={{marginTop: '2rem'}}>
        <Typography variant='h6' align='justify' component='div'>
          <Box fontFamily='Roboto'>
            {pageData.paras[2].paraText}
          </Box>
        </Typography>
      </Grid>

      {/* Lt Gen Donald Putt photo side-by-side with a para */}
      <Grid container style={{marginTop: '2rem'}}>
        {/* Photo with caption */}
        <Grid container item xs={12} sm={2}>
          <Grid container>
            <a
              href={LtGenDonaldPuttOfficialUSAFBio}
              target='_blank'
              rel="noopener noreferrer"
            >
              <img
                // src={`${smLtGenDonaldPutt}`}
                src={`${pageData.photos[8].photoFilename}`}
                alt='lieutenant general donald putt'
                style={{width: '95%', height: '95%', border: '5px solid rgb(34,28,110)', padding: '0.25rem'}}
              />
            </a>
          </Grid>
          <Grid container justify='center'>
            <Typography variant='subtitle1' justify='center' component='div' style={{width: '80%'}}>
              <Box fontFamily='Roboto'>
                {pageData.photos[8].caption}
              </Box>
            </Typography>
          </Grid>
        </Grid>
        {/* Para to right of photo */}
        <Grid container item xs={12} sm={10}>
          <Typography variant='h6' align='justify' component='div' style={{marginLeft: '2rem', marginTop: '1rem'}}>
              <Box fontFamily='Roboto'>
                {pageData.paras[3].paraText}
              </Box>
            </Typography>
        </Grid>
      </Grid>

      {/* Para to left with Aerial View of McCook Field to the right */}
      <Grid container>
        {/* Para to right of photo */}
        <Grid container item xs={12} sm={8}>
          <Typography variant='h6' align='justify' component='div' style={{marginRight: '2rem', marginTop: '5rem'}}>
              <Box fontFamily='Roboto'>
                {pageData.paras[6].paraText}
              </Box>
            </Typography>
        </Grid>
        {/* Photo with caption */}
        <Grid container item xs={12} sm={4}>
          <Grid container>
            <img
              // src={`${smMcCookFieldAerialView}`}
              src={`${pageData.photos[1].photoFilename}`}
              alt='McCool Field, Dayton, OH, aerial view'
              style={{width: '95%', height: '95%', border: '5px solid rgb(34,28,110)', padding: '0.25rem'}}
            />
          </Grid>
          <Grid container justify='center'>
            <Typography variant='subtitle1' justify='center' component='div' style={{width: '80%'}}>
              <Box fontFamily='Roboto'>
                {pageData.photos[1].caption}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* One full width photo caption on top with the photo full width below it */}
      <Grid item xs={12} style={{marginTop: '4rem'}}>
        <Grid item xs={12}>
          <Typography  variant='h6' component='div'>
            <Box fontFamily='Roboto'>
              {pageData.photos[2].caption}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img
            // src={`${smMcCookFieldGroundView}`}
            src={`${pageData.photos[2].photoFilename}`}
            alt='mccook-field-sign'
            style={{width: '100%', height: '100%', border: '5px solid rgb(34,28,110)', padding: '0.25rem'}}
          />
        </Grid>
      </Grid>

      {/* One full width para */}
      <Grid container style={{marginTop: '2rem'}}>
        <Typography  variant='h6' align='justify' component='div'>
          <Box fontFamily='Roboto'>
            {pageData.paras[4].paraText}
          </Box>
        </Typography>
      </Grid>

      {/* One full width para */}
      <Grid container style={{marginTop: '2rem'}}>
        <Typography variant='h6' align='justify' component='div'>
          <Box fontFamily='Roboto'>
            {pageData.paras[5].paraText}
          </Box>
        </Typography>
      </Grid>

      {/* Two photo side-by-side, each with a caption below it */}
      <Grid container style={{marginTop: '2rem'}}>
        <Grid container item xs={12} sm={6}>
          <Grid container>
            <img
              // src={`${smTestPilotsAtWrightField}`}
              src={`${pageData.photos[3].photoFilename}`}
              alt='test-pilots-from-the-1930s'
              style={{width: '95%', height: '95%', border: '5px solid rgb(34,28,110)', padding: '0.25rem'}}
            />
          </Grid>
          <Grid container justify='center'>
            <Typography variant='subtitle1' align='justify' component='div' style={{width: '90%'}}>
              <Box fontFamily='Roboto'>
                {pageData.photos[3].caption}
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid container justify='center'>
            <Typography variant='subtitle1' align='justify' component='div' style={{width: '90%'}}>
              <Box fontFamily='Roboto'>
                {pageData.photos[4].caption}
              </Box>
            </Typography>
          </Grid>
          <Grid container>
            <img
              // src={`${smWrightFieldAerialView}`}
              src={`${pageData.photos[4].photoFilename}`}
              alt='wright-field'
              style={{width: '95%', height: '95%', border: '5px solid rgb(34,28,110)', padding: '0.25rem'}}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Two paras side-by-side */}
      <Grid container style={{marginTop: '2rem'}}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} style={{marginRight: '2rem'}}>
            <Typography variant='h6' align='justify' component='div'>
              <Box fontFamily='Roboto'>
                {pageData.paras[7].paraText}
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
        <Grid item xs={12} style={{marginLeft: '2rem'}}>
            <Typography variant='h6' align='justify' component='div'>
              <Box fontFamily='Roboto'>
                {pageData.paras[8].paraText}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Group of three photos */}
      <Grid container style={{marginTop: '4rem'}}>
        <Grid item xs={12} sm={7}>
          <Grid container>
            <Grid container justify='center'>
              <Typography variant='subtitle1' align='justify' component='div' style={{width: '90%'}}>
                <Box fontFamily='Roboto'>
                  {pageData.photos[5].caption}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img
                // src={`${testFlightGraph}`}
                src={`${pageData.photos[5].photoFilename}`}
                alt='flight test data graph'
                style={{width: '95%', height: '95%', border: '5px solid rgb(34,28,110)', padding: '0.25rem'}}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <img
                // src={`${smInformalTestInstruction}`}
                src={`${pageData.photos[6].photoFilename}`}
                alt='informal flight test instruction'
                style={{width: '95%', height: '95%', border: '5px solid rgb(34,28,110)', padding: '0.25rem'}}
              />
            </Grid>
            <Grid container justify='center'>
              <Typography variant='subtitle1' align='justify' component='div' style={{width: '90%'}}>
                <Box fontFamily='Roboto'>
                  {pageData.photos[6].caption}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
          <Grid item xs={12} sm={5}>
            <Grid container style={{marginTop: '12rem'}}>
              <Grid item xs={12}>
                <img
                  // src={`${pilotAircraftQualificationBook}`}
                  src={`${pageData.photos[7].photoFilename}`}
                  alt='pilot qualification card'
                  style={{width: '95%', height: '95%', border: '5px solid rgb(34,28,110)', padding: '0.25rem'}}
                />
              </Grid>
              <Grid container justify='center'>
                <Typography variant='subtitle1' align='justify' component='div' style={{width: '90%'}}>
                  <Box fontFamily='Roboto'>
                    {pageData.photos[7].caption}
                  </Box>
                </Typography>
              </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justify='space-between' style={{marginTop: '2rem', marginBottom: '5rem'}}>
        <Grid>
          <Button disabled variant='outlined'>Previous Page</Button>
        </Grid>
        <Grid>
          <Button variant='outlined' onClick={handleReturnToTOC}>Return to Table of Contents</Button>
        </Grid>
        <Grid >
          <Button disabled variant='outlined'>Next Page</Button>
        </Grid>
        <Grid >
          <Typography variant='h6'>Page {pageData.pageIndex + 1}</Typography>
        </Grid>
      </Grid>

    </Grid>
  )
}

YbPageOne.propTypes = {
  pageData: PropTypes.object
}

export default YbPageOne
