/**
 * 
 */

import React, { useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../../Helpers'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { store } from '../../Redux/Store'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const AddNewClassPage = () => {

   const { userType } = store.getState()

   const [ classNumber,       setClassNumber       ] = useState('')
   const [ classType,         setClassType         ] = useState(false)
   const [ gradNumbers,       setGradNumbers       ] = useState('')
   const [ hashes,            setHashes            ] = useState('')
   const [ startingUserId,    setStartingUserId    ] = useState(0)
   const [ numberOfStudents,  setNumberOfStudents  ] = useState(0)
   const [ usernames,         setUsernames         ] = useState('')

   const handleAddNewPageClick = (o) => {
      setClassType(o)
   }

   const handleGradNumberChange = (e) => {
      setGradNumbers(e.target.value)
   }

   const handleClassNumberChange = (e) => {
      setClassNumber(e.target.value)
   }

   const handleHashChange = (e) => {
      setHashes(e.target.value)
   }

   const handleNumberOfStudentsChange = (e) => {
      setNumberOfStudents(e.target.value)
   }

   const handleStartingUserIdChange = (e) => {
      setStartingUserId(e.target.value)
   }

   const handleUsernameChange = (e) => {
      setUsernames(e.target.value)
   }

   const handleSubmit = async () => {
      let userId = null
      if (userType === 'superUser') userId = 1
      axios.post(node_server + 'admin/newClass', 
      {
         classNumber,
         CLIENT_PUBLIC_TOKEN,
         classType,
         gradNumbers,
         hashes,
         numberOfStudents,
         startingUserId,
         userId,
         usernames
      })
      .then( res => {
         console.log('AddNewClassPage res is', res)
      })
  }

   const gradNumberPlaceHolderText = 'Enter grad numbers as comma delimitied, no spaces.'
   const hashPlaceHolderText = 'Enter hashes as comma delimitied, no spaces.'
   const usernamePlaceHolderText = 'Enter Usernames as comma delimitied, no spaces.'

   return (
      <Grid style={{marginTop: '5rem'}}>

            <Grid>
                  <Typography variant='h3' style={{marginBottom: '0rem'}} >
                     Admin - Add a New Class
                  </Typography>
            </Grid>

            <Box>
               <Container>
                  <Grid container justifyContent='center' style={{marginTop: '2rem'}}>
                     <RadioGroup row>

                        <FormControlLabel
                           control={<Radio color='primary' />}
                           label='Add a Flight Test Class'
                           labelPlacement='top'
                           onClick={() => handleAddNewPageClick('ftc')}
                           value='ftc'
                        >
                        </FormControlLabel>

                        <FormControlLabel
                           control={<Radio color='primary' />}
                           label='Add a Space Test Class'
                           labelPlacement='top'
                           onClick={() => handleAddNewPageClick('stc')}
                           value='stc'
                        >
                        </FormControlLabel>

                     </RadioGroup>
                  </Grid>
               </Container>
            </Box>

            <Container >
               <Grid container direction='row' style={{marginBottom: '2rem', marginTop: '1rem'}} >
                  <Grid item xs={12} sm={4} style={{width: '33%'}} >
                     <TextField
                           label='Class Number'
                           onChange={handleClassNumberChange}
                           style={{width: '300px'}}
                           variant='outlined'
                     />
                  </Grid>

                  <Grid item xs={12} sm={4} style={{width: '33%'}} >
                     <TextField
                           label='Starting userId'
                           onChange={handleStartingUserIdChange}
                           style={{width: '300px'}}
                           variant='outlined'
                     />
                  </Grid>

                  <Grid item xs={12} sm={4} style={{width: '33%'}} >
                     <TextField
                           label='Number of Students'
                           onChange={handleNumberOfStudentsChange}
                           style={{width: '300px'}}
                           variant='outlined'
                     />
                  </Grid>
               </Grid>

            </Container>

            <Grid item xs={12} style={{ marginBottom: '1rem', marginLeft: '5rem', marginRight: '5rem'}}>
               <TextareaAutosize name='usernames' placeholder={usernamePlaceHolderText} minRows={5} style={{ width: '100%', fontSize: '1.5rem', padding: '0.5rem' }} onChange={handleUsernameChange} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '1rem', marginLeft: '5rem', marginRight: '5rem'}}>
               <TextareaAutosize name='usernames' placeholder={gradNumberPlaceHolderText} minRows={3} style={{ width: '100%', fontSize: '1.5rem', padding: '0.5rem' }} onChange={handleGradNumberChange} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '1rem', marginLeft: '5rem', marginRight: '5rem'}}>
               <TextareaAutosize name='usernames' placeholder={hashPlaceHolderText} minRows={25} style={{ width: '100%', fontSize: '1.5rem', padding: '0.5rem' }} onChange={handleHashChange} />
            </Grid>

            <Grid item>
               <Button variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
            </Grid>
      </Grid>
   )
}

export default AddNewClassPage