/*
 * Styling is in Landing.css
 */
import React from 'react'
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import "./Landing.css"
import LandingPageTPSPainting from '../Components/LandingPageTPSPainting'
import Typography from "@material-ui/core/Typography"
import LandingPageCenterSection from '../Components/LandingPageCenterSection';
import LandingPageNotableAlumni from '../Components/LandingPageNotableAlumni';
import LandingPageAnnouncements from '../Components/LandingPageAnnouncements';

const Landing = props => {

    return (
        <Box >
            <Grid container justifyContent="center">
                <Grid container direction="row" justifyContent="space-between" style={{ margin: "2rem", marginTop: '6rem' }}>
                    <Grid item sm={12} md={4} >

                        <LandingPageTPSPainting />

                        <LandingPageAnnouncements />
                        <Typography style={{ marginTop: "1rem" }} >Site maintained by: <a href="mailto:admin@usaftpsalumni.com" target="_blank" rel="noreferrer noopener" >admin@usaftpsalumni.com</a></Typography>

                    </Grid>

                    <LandingPageCenterSection />

                    <LandingPageNotableAlumni />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Landing
