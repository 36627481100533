/* This is the top level Page that has three Pages below it
 * 
 * <ManagePhotos />
 *      <ManageBioPhotos />
 * 
 *          <GetBioList /> was replaced by <GetBioListPartial />
 * 
 *          <MySelect />
 *          <AddBioPhoto />
 *          <ShowBioPhotoToManage />
 * 
 *      <ManageClassPatches />
 *          <MySelect />
 *          <AddClassPatch />
 *          <Snackbar />
 *          <ShowClassPatchesToManage />
 * 
 *      <ManageClassPhotos />
 *          <MySelect />
 *          <AddClassPhoto />
 *          <Snackbar />
 *          <ShowClassPhotosToManage />
 * 
 *      <ManageHistoryPhotos />
*/
import React, { useState } from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { node_server } from '../Helpers'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const AddClassPatch = ({addClassPatchToLocalState, classPatch, tpsClass}) => {

    const [ filename, setFilename ] = useState('')

    const handleFilenameChange = (e) => {
        setFilename(e.target.value)
    }

    const handleSubmit = () => {
            classPatch.tpsClass = tpsClass
            classPatch.classPatchFilename = filename
            axios.post(node_server + 'classPatch/AddClassPatch',{
                classPatch
            })
                .then( res => res.data )
                .then( data => {
                    setFilename('')
                    addClassPatchToLocalState(data)
                } )
    }

    return (
        <Grid style={{marginTop: '3rem'}} >
            <Container>
                <form>
                <Typography variant='h4' >
                    Add Class Patch
                </Typography>
                <Grid>
                    <TextField value={filename} label='Filename' placeholder='Enter Filename Here' style={{width: '100%', marginTop: '1rem'}} onChange={handleFilenameChange} />
                </Grid>
                <Grid container item justify='flex-start' style={{marginTop: '1.5rem'}} >
                {filename.length > 0 ?
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                :
                    <Button disabled variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                }
                </Grid>
                </form>
            </Container>
        </Grid>
    )
}

AddClassPatch.propTypes = {
    addClassPatchToLocalState: PropTypes.func,
    classPatch: PropTypes.object,
    tpsClass: PropTypes.string
}

export default AddClassPatch
