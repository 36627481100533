import React                                    from 'react'
import Box                                      from '@material-ui/core/Box'
import Container                                from '@material-ui/core/Container'
import Grid                                     from '@material-ui/core/Grid'
import { photoDims }                            from '../config'
import PropTypes                                from 'prop-types'
import Typography                               from '@material-ui/core/Typography'

const ShowBioPhoto = props => {

    const { bio } = props

    let bioPhotosToDisplay = []

    if( bio.bioPhoto ){
        bioPhotosToDisplay = bio.bioPhoto.map( (bioPhoto, index) => {

            let photoWidth = photoDims.bioPhotoWidth

            /* Allows for smaller photo sizes in cases of low resolution */
            if( bioPhoto.bioPhotoWidth !== undefined ){
                photoWidth = bioPhoto.bioPhotoWidth
            }

            /* This is to make sure photos work for either locally soruced files or AWS files while the
               photo transition to AWS is happening. */
            const filename    = bioPhoto.bioPhotoFilename.split('://')[0] === 'https'  ? bioPhoto.bioPhotoFilename : `/images/${bio.userId}/${bioPhoto.bioPhotoFilename}`
            const filename_a  = bioPhoto.bioPhotoFilename.slice(0,4) === '9999'        ? bioPhoto.bioPhotoFilename : `/images/${bioPhoto.bioPhotoFilename}`

            return( <Grid key={bioPhoto._id} container justifyContent='center'>
                        <Grid item xs={12} style={{marginTop: '1rem'}} >

                            {/* This allows for multipls bios to use the same photo that resides in the 9999 image director */}
                            {bioPhoto.bioPhotoFilename.slice(0,4) === '9999'
                                ?
                                <img
                                    style={{width: `${photoWidth}`}}
                                    alt={`${bio.lastName} ${index + 1}`}
                                    src={filename_a}
                                />
                                :
                                <img
                                style={{width: `${photoWidth}`}}
                                alt={`${bio.lastName} ${index + 1}`}
                                src={filename}
                                />
                            }

                        </Grid>
                        <Grid item xs={12} style={{marginTop: '1rem'}} container justifyContent='center' >
                            <Typography variant='h6' align='center' style={{width: '75%'}} >
                                { bioPhoto.bioPhotoCaption }
                            </Typography>
                        </Grid>
                    </Grid>)
        } )
    }

    return (
        <Box style={{marginTop: '3rem'}} >
            <Container style={{marginBottom: '2rem'}} >
                { bioPhotosToDisplay }
            </Container>
        </Box>
    )
}

ShowBioPhoto.propTypes = {
    bio: PropTypes.object
}

export default ShowBioPhoto