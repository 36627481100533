/*
 * This is the Top level component for Editing One Bio via Nodejs / MongoDB
 * 
 * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
 * 
 * Puts tpsClass from dropdown in useState Hook, and sends the currently selected class to the Store
 * 
 * <EditOneBioPage />
 *      <ShowOfficialTitleForOneGrad />
 *      <EditOfficialTitleForOneGrad />
 *      <ShowBioEntriesForOneGrad />
 *      <EditBioEntriesForOneGrad />
 *      <ShowMilitaryUnitsForOneGrad />
 *      <EditMilitaryUnitForOneGrad />
 *      <AddRemoveButtons />
 *      <AddBioEntry />
 *      <AddRemoveButtons />
 *      <AddMilitaryUnitToBio />
 */

import React, { useEffect, useState } from 'react'
import AddBioEntry from '../Components/AddBioEntry'
import AddMilitaryUnitToBio from '../Components/AddMilitaryUnitToBio'
import AddRemoveButtons from '../Components/AddRemoveButtons'
import axios from 'axios'
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import EditBioEntryForOneGrad from '../Components/EditBioEntryForOneGrad'
import EditMilitaryUnitForOneGrad from '../Components/EditMilitaryUnitForOneGrad'
import EditOfficialTitleForOneGrad from '../Components/EditOfficialTitleForOneGrad'
import Grid from "@material-ui/core/Grid"
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import Paper from "@material-ui/core/Paper"
import ShowBioEntriesForOneGrad from '../Components/ShowBioEntriesForOneGrad'
import ShowMilitaryUnitsForOneGrad from '../Components/ShowMilitaryUnitsForOneGrad'
import ShowOfficialTitleForOneGrad from '../Components/ShowOfficialTitleForOneGrad'
import { store } from '../Redux/Store'

const EditOneBioPage = props => {

   const { userIdOfBioToEdit } = store.getState()

   // Toggles <EditOfficialTitleForOneGrad />
   const [editOfficialTitleBool, setEditOfficialTitleBool] = useState(false)

   // Toggles <EditBioEntryForOneGrad />
   const [editBioEntryForOneGradBool, setEditBioEntryForOneGradBool] = useState(false)

   // Toggles <EditMilitaryUnitForOneGrad />
   const [editMilitaryUnitForOneGradBool, setEditMilitaryUnitForOneGradBool] = useState(false)

   // Fetches data from the DB again
   const [ fetchBio, setFetchBio ] = useState(false)

   // Comes from <ShowBioEntriesForOneGrad />
   const [bioEntryIdToEdit, setBioEntryIdToEdit] = useState('')

   // Comes from <ShowMilitaryUnitsForOneGrad />
   const [bioMilitaryUnitIdToEdit, setBioMilitaryUnitIdToEdit] = useState('')

   // Toggles <AddBioEntryForOneGrad />
   const [addBioEntryBool, setAddBioEntryBool] = useState(false)

   // Toggles <AddMilitaryUnitForOneGrad />
   const [addMilitaryUnitBool, setAddMilitaryUnitBool] = useState(false)

   const [bio, setBio] = useState({})
   const [bioEntry, setBioEntry] = useState([])
   const [militaryUnit, setMilitaryUnit] = useState([])
   const [officialTitleCount, setOfficialTitleCount] = useState(0)
   const [bioIndex, setBioIndex] = useState(0)
   const [militaryUnitIndex, setMilitaryUnitIndex] = useState(0)

   // Get Bio from DB
   useEffect( () => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + `bio/${userIdOfBioToEdit}`)
         .then( res => res.data )
         .then( data => {

            setBio(data)
            setBioEntry(data.bioEntry)
            setMilitaryUnit(data.militaryUnit)
            setOfficialTitleCount(data.officialTitle.length)

            /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
            after a GET was made to the Node server. */
            axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
         })
   }, [fetchBio, userIdOfBioToEdit] )

   // Sent to <ShowOfficialTitleForOneGrad /> and shows <EditOfficialTitleForOneGrad />
   const handleEditOfficialTitle = () => {
      setEditOfficialTitleBool(!editOfficialTitleBool)
   }

   // Updates the Bio in MongoDB
   const updateBio = () => {
      axios.put(node_server + 'bio', {
         bio,
         CLIENT_PUBLIC_TOKEN
      })
         .then( res => res.data )
         .then( data => {
               setBio(data)
               setFetchBio( !fetchBio )
         })
   }

   // Sent to <EditOfficialTitleForOneGrad /> to UPDATE officialTitle and publicBio
   const handleSubmitEditOfficialTitle = (officialTitle, publicBio) => {
      bio.officialTitle = officialTitle
      bio.publicBio = publicBio

      updateBio()
   }

   // Sent to <EditOfficialTitleForOneGrad /> to hide itself upon Submit or Cancel
   const handleCancelEditOfficialTitle = () => {
      setEditOfficialTitleBool(false)
   }

   // Sent to <EditBioEntryeForOneGrad /> to UPDATE bioEntry
   const handleSubmitEditBioEntryForOneGrad = (bio) => {
      updateBio()
   }

   // Sent to <EditBioEntryeForOneGrad /> to hide itself upon Submit or Cancel
   const handleCancelEditBioEntryForOneGrad = () => {
      setEditBioEntryForOneGradBool(false)
   }

   // Sent to <ShowBioEntriesForOneGrad /> and shows <EditBioEntryForOneGrad />
   const handleEditBioEntryForOneGrad = (id) => {
      setBioEntryIdToEdit(id)
      setEditBioEntryForOneGradBool(!editBioEntryForOneGradBool)
   }

   // Sent to <EditMilitaryUnitForOneGrad /> to UPDATE bioEntry
   const handleSubmitMilitaryUnitForOneGrad = (bio) => {
      updateBio()
   }

   // Sent to <EditMilitaryUnitForOneGrad /> to hide itself upon Submit or Cancel
   const handleCancelMilitaryUnitForOneGrad = () => {
      setEditMilitaryUnitForOneGradBool(false)
   }

   // Sent to <ShowBioEntriesForOneGrad /> and shows <EditMilitaryUnitForOneGrad />
   const handleEditMilitaryUnitForOneGrad = (id) => {
      // console.log('EditOneBioPage bioMilitaryUnitIdToEdit is ', id)
      setBioMilitaryUnitIdToEdit(id)
      setEditMilitaryUnitForOneGradBool(!editMilitaryUnitForOneGradBool)
   }

   // Sent to <AddRemoveButtons />
   const handleAddRemoveButtons = () => {
      setAddBioEntryBool(!addBioEntryBool)
   }

   // Sent to <AddRemoveButtons />
   const handleAddRemoveButtonsMilitaryUnits = () => {
      setAddMilitaryUnitBool(!addMilitaryUnitBool)
   }

   // Sent to <AddBioEntry />
   const submitBioEntry = (sectionText, sectionTitle) => {
      /* 
      * ADD new bio is triggered by AddBioEntry only when
      * <AddBioHeader /> officialTitle is not blank,
      * <AddBioEntry /> sectionText and sectionTitle are not blank
      */
      // UPDATE existing bioEntry
      bio.bioEntry.push({
         bioIndex: bioIndex,
         text: sectionText,
         title: sectionTitle,
      })
      axios.put(node_server + 'bio', {
         bio,
         CLIENT_PUBLIC_TOKEN
      })
         .then( res => res.data )
         .then( data => {
               setBio(data)
               setBioIndex(bioIndex + 1)
               setBioEntry(data.bioEntry)
         })
   }

   // Sent to <AddMilitaryUnitToBio />
   const submitMilitaryUnit = (militaryUnit) => {
      bio.militaryUnit.push({militaryUnitIndex: militaryUnitIndex, militaryUnit: militaryUnit})
      axios.put(node_server + 'bio', {
         bio,
         CLIENT_PUBLIC_TOKEN
      })
         .then( res => res.data )
         .then( data => {
               setBio(data)
               setMilitaryUnitIndex(militaryUnitIndex + 1)
               setMilitaryUnit(data.militaryUnit)
         })
   }

   // Sent to ShowBioEntriesForOneGrad in order to move a Bio Entry Up One Spot
   const moveBioEntryUp = (index) => {
      const tempBioEntry = bioEntry[index - 1]
      bioEntry[index - 1] = bioEntry[index]
      bioEntry[index] = tempBioEntry

      updateBio()
   }

   // Sent to ShowBioEntriesForOneGrad in order to move a Bio Entry Down One Spot
   const moveBioEntryDown = (index) => {
      const tempBioEntry = bioEntry[index + 1]
      bioEntry[index + 1] = bioEntry[index]
      bioEntry[index] = tempBioEntry

      updateBio()
   }

   // Sent to ShowMilitaryUnitsForOneGrad in order to move a Military Unit Up One Spot
   const moveMilitaryUnitUp = (index) => {
      const tempMilitaryUnit = militaryUnit[index - 1]
      militaryUnit[index - 1] = militaryUnit[index]
      militaryUnit[index] = tempMilitaryUnit

      updateBio()
   }

   // Sent to ShowMilitaryUnitsForOneGrad in order to move a Military Unit Down One Spot
   const moveMilitaryUnitDown = (index) => {
      const tempMilitaryUnit = militaryUnit[index + 1]
      militaryUnit[index + 1] = militaryUnit[index]
      militaryUnit[index] = tempMilitaryUnit

      updateBio()
   }

   return (
      <Box style={{marginTop: '6rem'}} >
         <Container>
               <Grid>
                  <Paper style={{ backgroundColor: 'rgba(211,211,211,0.5)', padding: '1rem' }}>
                     <Grid>
                           <ShowOfficialTitleForOneGrad
                              officialTitle={bio.officialTitle}
                              handleEditOfficialTitle={handleEditOfficialTitle}
                              showEditButtons={true}
                              publicBio={bio.publicBio}
                           />
                     </Grid>

                     {editOfficialTitleBool ?
                           <EditOfficialTitleForOneGrad
                              bio={bio}
                              handleSubmitEditOfficialTitle={handleSubmitEditOfficialTitle}
                              handleCancelEditOfficialTitle={handleCancelEditOfficialTitle}
                           />
                     : null}

                     <Grid style={{marginTop: '1rem'}} >
                           <ShowBioEntriesForOneGrad
                              bioEntry={bioEntry}
                              handleEditBioEntryForOneGrad={handleEditBioEntryForOneGrad}
                              showEditButtons={true}
                              moveBioEntryUp={moveBioEntryUp}
                              moveBioEntryDown={moveBioEntryDown}
                           />
                     </Grid>

                     {editBioEntryForOneGradBool ?
                           <EditBioEntryForOneGrad
                              bio={bio}
                              bioEntryIdToEdit={bioEntryIdToEdit}
                              handleCancelEditBioEntryForOneGrad={handleCancelEditBioEntryForOneGrad}
                              handleSubmitEditBioEntryForOneGrad={handleSubmitEditBioEntryForOneGrad}
                              handleEditBioEntryForOneGrad={handleEditBioEntryForOneGrad}
                           />
                     : null}

                     <Grid style={{marginTop: '1rem'}} >
                           <ShowMilitaryUnitsForOneGrad
                              militaryUnit={militaryUnit}
                              handleEditMilitaryUnitForOneGrad={handleEditMilitaryUnitForOneGrad}
                              showEditButtons={true}
                              moveMilitaryUnitUp={moveMilitaryUnitUp}
                              moveMilitaryUnitDown={moveMilitaryUnitDown}
                           />
                     </Grid>

                     {editMilitaryUnitForOneGradBool ?
                           <EditMilitaryUnitForOneGrad
                              bio={bio}
                              bioMilitaryUnitIdToEdit={bioMilitaryUnitIdToEdit}
                              handleCancelMilitaryUnitForOneGrad={handleCancelMilitaryUnitForOneGrad}
                              handleSubmitMilitaryUnitForOneGrad={handleSubmitMilitaryUnitForOneGrad}
                              handleEditMilitaryUnitForOneGrad={handleEditMilitaryUnitForOneGrad}
                              
                           />
                     : null}

                  </Paper>

                  <AddRemoveButtons
                     addEntry={addBioEntryBool}
                     handleAddRemoveButtons={handleAddRemoveButtons}
                     msg1='Hide Bio Entry'
                     msg2='Add A Bio Entry'
                  />

                  <AddBioEntry
                     addEntry={addBioEntryBool}
                     // handleSectionText={handleSectionText}
                     // handleSectionTitle={handleSectionTitle}
                     officialTitleCount={officialTitleCount}
                     submitBioEntry={submitBioEntry}
                  />

                  <AddRemoveButtons
                     addEntry={addMilitaryUnitBool}
                     handleAddRemoveButtons={handleAddRemoveButtonsMilitaryUnits}
                     msg1='Hide Military Unit Entry'
                     msg2='Add A Military Unit'
                  />
               
                  <AddMilitaryUnitToBio
                     addEntry={addMilitaryUnitBool}
                     submitMilitaryUnit={submitMilitaryUnit}
                  />

               </Grid>
         </Container>

         {editOfficialTitleBool ?
               <Container>
                  <EditOfficialTitleForOneGrad />
               </Container>
         :null}
      </Box>
   )
}

export default EditOneBioPage
