import React from 'react';
import ReactDOM from 'react-dom';
import { store } from "./Redux/Store/"
import { BrowserRouter as Router } from "react-router-dom"
import './index.css';
import App from './App';

const render = () =>

ReactDOM.render(
  // <React.StrictMode>
    <Router>
      <App />
    </Router>,
  // </React.StrictMode>,
  document.getElementById('root')
);

render()

store.subscribe(render)