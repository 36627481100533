/* This is the top level Page that has three Pages below it
 * 
 * <ManagePhotos />
 *      <ManageBioPhotos />
 * 
 *          <GetBioList /> was replaced by <GetBioListPartial />
 * 
 *          <MySelect />
 *          <AddBioPhoto />
 *          <Snackbar />
 *          <ShowBioPhotoToManage />
 *          <EditBioPhoto />
 * 
 *      <ManageClassPatches />
 *          <MySelect />
 *          <AddClassPatch />
 *          <Snackbar />
 *          <ShowClassPatchesToManage />
 * 
 *      <ManageClassPhotos />
 *          <MySelect />
 *          <AddClassPhoto />
 *          <Snackbar />
 *          <ShowClassPhotosToManage />
 *          <EditClassPhoto />
 * 
 *      <ManageHistoryPhotos />
*/

import React, { useEffect, useState } from 'react'
import AddClassPhoto from '../Components/AddClassPhoto'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import ClassDropdown from '../Components/ClassDropdown'
import Container from '@material-ui/core/Container'
import EditClassPhoto from '../Components/EditClassPhoto'
import { getTPSClasses, node_server } from '../Helpers'
import Grid from '@material-ui/core/Grid'
import ShowClassPhotoToManage from '../Components/ShowClassPhotoToManage'
import Snackbar from '../Atoms/Snackbar'
import Typography from '@material-ui/core/Typography'

const ManageClassPhotos = () => {

    /* Item 1. Get list of TPS Classes from MySQL DB */
    const [ tpsClasses, setTpsClasses ] = useState([])
    /* Item 2. TPS class selected via <MySelect /> */
    const [ tpsClass, setTpsClass ] = useState('')
    /* Item 3. Get classPhoto from MongoDB */
    const [ classPhoto, setClassPhoto ] = useState({})
    /* If a classPhoto exists, display photo and caption */
    const [ showClassPhoto, setShowClassPhoto ] = useState(false)
    /* Fetches data from the DB again */
    const [ fetchClassPhoto, setFetchClassPhoto ] = useState(false)

    const [ photoIdToEdit, setPhotoIdToEdit ] = useState('')
    const [ photoToEdit, setPhotoToEdit ] = useState('')
    const [ showEditPhoto, setShowEditPhoto ] = useState(false)
    const [ snackbarOpen, setSnackbarOpen ] = useState(false)

    /* 
     * Item 1.
     * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
     */
    useEffect( () => {
        getTPSClasses()
            .then( classes => setTpsClasses(classes) )
            .catch( msg => console.log('There was an error on get', msg) )
    }, [] )

    /* 
     * Item 2.
     * Sent down to <ClassDropdown /> which sends back a String containing the selected TPS Class
     */
    const getSelectedClass = (e) => {
        setTpsClass(e)
    }

    /* 
     * Item 3.
     * Take TPS Class from <MySelect /> and check the MongoDB to see if an entry exists or not
     */
    useEffect( () => {
        if( tpsClass !== '' ){
            axios.get(node_server + `classPhoto/GetClassPhoto/${tpsClass}`)
                .then( res => res.data )
                .then( data => {
                    if( data ){
                        setClassPhoto( data )
                        setShowClassPhoto(true)
                    }
                    else{
                        setShowClassPhoto(false)
                        setSnackbarOpen(true)
                        setTimeout( () => {
                            setSnackbarOpen(false)
                        }, 2500 )
                    }
                } )
        }
    }, [fetchClassPhoto, tpsClass] )

    /*
     * Item 4.
     * Takes updated classPhoto from <AddClassPhoto /> and puts it in local state
     */
    const addClassPhotoToLocalState = (classWithPhotoAdded) => {
        setClassPhoto(classWithPhotoAdded)
        setShowClassPhoto(true)
    }

    // Updates the classPhoto in MongoDB
    const updateClassPhoto = () => {
        axios.put(node_server + 'classPhoto/AddClassPhoto', {
            classPhoto
        })
            .then( res => res.data )
            .then( data => {
                setClassPhoto(data)
                setFetchClassPhoto( !fetchClassPhoto )
            })
    }

    // Sent to <ShowClassPhotoToManage /> to get id of photo to edit
    const getPhotoIdToEdit = (id) => {
        setPhotoIdToEdit(id)
        /* Operating inside a Hook */
        setPhotoToEdit(
            classPhoto.classPhoto.filter( photo => {
                if(photo._id === id){
                    return photo
                }
                return null
            } )
        )
        /* This removes <ShowClassPhotoToManage while EDIT is being performed */
        setShowClassPhoto(false)
        /* This shows or hides <AddClassPhoto /> and <EditClassPhoto /> */
        setShowEditPhoto(true)
    }

        /* Send down to <EditBioPhoto />, will send back updated data */
        const updatedDataForDB = (d) => {
            /* Operating inside a Hook */
            setClassPhoto(
                classPhoto.classPhoto.filter( photo => {
                    if(photo._id === photoIdToEdit){
                        photo.classPhotoCaption = d.caption
                        photo.classPhotoWidth = d.classPhotoWidth
                        photo.classPhotoFilename = d.filename
                    }
                    return null
                } )
            )
            updateClassPhoto()
            setShowEditPhoto(false)
            setShowClassPhoto(true)
            return null
        }

    // Sent to <ShowClassPhotoToManage /> in order to move a Bio Photo Up One Spot
    const movePhotoEntryUp = (index) => {
        const tempClassPhoto = classPhoto.classPhoto[index - 1]
        classPhoto.classPhoto[index - 1] = classPhoto.classPhoto[index]
        classPhoto.classPhoto[index] = tempClassPhoto

        updateClassPhoto()
    }

    // Sent to <ShowClassPhotoToManage /> in order to move a Bio Photo Down One Spot
    const movePhotoEntryDown = (index) => {
        const tempClassPhoto = classPhoto.classPhoto[index + 1]
        classPhoto.classPhoto[index + 1] = classPhoto.classPhoto[index]
        classPhoto.classPhoto[index] = tempClassPhoto

        updateClassPhoto()
    }

    return (
        <Box style={{marginTop: '6rem'}} >
            <Typography variant='h3' >
                Manage Class Photos
            </Typography>

            <Container>
                <ClassDropdown tpsClasses={tpsClasses} getSelectedClass={getSelectedClass} />
            </Container>

            {!showEditPhoto ?
                <AddClassPhoto addClassPhotoToLocalState={addClassPhotoToLocalState} classPhoto={classPhoto} tpsClass={tpsClass} />
            :   null}
            {snackbarOpen ?
            <Grid container item style={{marginTop: '2rem', marginLeft: '30%', width: '40%'}} >
                <Snackbar type='error' msg={'That class does not yet have a class photo in the Database'} />
            </Grid>
            : null}
            {showClassPhoto ?
                <ShowClassPhotoToManage classPhoto={classPhoto} getPhotoIdToEdit={getPhotoIdToEdit} movePhotoEntryDown={movePhotoEntryDown} movePhotoEntryUp={movePhotoEntryUp} />
            : null}
            {showEditPhoto ?
                <EditClassPhoto classPhoto={classPhoto} photoToEdit={photoToEdit} updatedDataForDB={updatedDataForDB} />
            :   null}
        </Box>
    )
}

export default ManageClassPhotos