import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'

const EditOfficialTitleForOneGrad = props => {

    const { bio, handleCancelEditOfficialTitle, handleSubmitEditOfficialTitle } = props
    const [officialTitle, setOfficialTitle] = useState('')
    const [publicBio, setPublicBio] = useState(false)

    useEffect( () => {
        if(bio !== undefined){
            setOfficialTitle(bio.officialTitle)
            setPublicBio(bio.publicBio)
        }
    }, [bio] )

    const handleChange = (e) => {
        setOfficialTitle(e.target.value)
    }

    const handlePublicSwitch = (e) => {
        setPublicBio(e.target.checked)
    }

    const handleSubmit = () => {
        handleSubmitEditOfficialTitle(officialTitle, publicBio)
        handleCancelEditOfficialTitle()
    }

    const handleCancel = () => {
        handleCancelEditOfficialTitle()
    }

    return (
        <Box style={{ marginTop: '5rem', marginBottom: '2rem' }}>
            <Container>

                <Grid container>

                    <Grid container alignItems='flex-end' item xs={12} style={{marginTop: '1rem'}}>
                        <Grid container alignItems='center' item xs={12} md={2}>
                            <Grid item>Private</Grid>
                            <Grid item >
                                <Switch data-testid='AddBioHeader-switch' checked={publicBio} onChange={handlePublicSwitch} />
                            </Grid>
                            <Grid item>Public</Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField value={officialTitle} name="oficialTitle" onChange={handleChange} data-testid='AddBioHeader-officialTitle' style={{ width: '650px' }} />
                        </Grid>
                    </Grid>

                    <Grid container style={{marginTop: '2rem'}}>
                        <Grid item xs={12} md={1} style={{marginRight: '2rem'}}>
                            <Button variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Button variant='contained' color='primary' onClick={handleCancel}>Cancel</Button>
                        </Grid>
                    </Grid>

                </Grid>
                
            </Container>
        </Box>
    )
}

EditOfficialTitleForOneGrad.propTypes = {
    bio: PropTypes.object,
    handleCancelEditOfficialTitle: PropTypes.func,
    handleSubmitEditOfficialTitle: PropTypes.func
}

export default EditOfficialTitleForOneGrad
