/*
 * This is the second level component for Showing each TPS class, reading any available
 * Bio entries, and adding Bio entries if logged in as an alumni member who
 * has CRUD authority for that TPS class
 * 
 * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
 * 
 * Puts tpsClass from dropdown in useState Hook, and sends the currently selected class to the Store
 * 
 * <ClassPages />
 *      <ShowOneClassPage />
 *          <ShowGradsFromOneClass />
 *          <ShowBioEntriesFromOneClass />
 *          <AddRemoveButtons />
 *          <AddMilitaryUnitToBio />
 *          <EditBioEntry />
 */

import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'


const AddMilitaryUnitToBio = props => {

    const { addEntry, submitMilitaryUnit } = props

    const [militaryUnit, setMilitaryUnit] = useState('')

    // These count the number of characters in the title and the Bio entry
    const [militaryUnitCount, setMilitaryUnitCount] = useState(0)

    const handleMilitaryUnitChange = (e) => {
        setMilitaryUnit(e.target.value)
        setMilitaryUnitCount(e.target.value.length)
    }

    const handleSubmit = async () => {
        await setMilitaryUnit('')
        await setMilitaryUnitCount(0)
        submitMilitaryUnit(militaryUnit)
    }

    return (
        <Box style={{ marginTop: '3rem', marginBottom: '3rem' }} >
            {addEntry ?
                <Container>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='h4'>
                                Add A Military Unit
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '3rem', marginBottom: '1rem' }} >
                            <TextField value={militaryUnit} name='militaryUnit' label='Military Unit' variant='outlined' style={{ width: '100%' }} onChange={handleMilitaryUnitChange} />
                        </Grid>

                        <Grid item>
                            {militaryUnitCount > 0 ?
                                <Button variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
                                :
                                <Button variant='contained' color='primary' disabled onClick={handleSubmit}>Submit</Button>
                            }
                        </Grid>

                    </Grid>
                </Container>
            : null}
        </Box>
    )
}

AddMilitaryUnitToBio.propTypes = {
    addEntry: PropTypes.bool,
    submitMilitaryUnit: PropTypes.func
}

export default AddMilitaryUnitToBio