import React, { useState } from 'react'
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { CSVDownload } from "react-csv";
import GradCard from "./GradCard"
import Grid from "@material-ui/core/Grid"
import PropTypes from 'prop-types'
import { updateAfterDeleteGrad } from '../Helpers'

const DisplayGrads = props => {

    const [downloadEmail, setDownloadEmail] = useState(false)

    const handleClick = () => {
        setDownloadEmail(true)
    }

        const { allGrads, showDownloadEmailButton } = props

        var csvData = [["Firstname", "Lastname", "TPS Class", "Personal E-mail", "Work E-mail"]]

        const gradToDisplay = allGrads
            .filter(grad => (
                (grad.givenFirstName !== "" || grad.preferredFirstName !== "") &&
                grad.lastName !== "" &&
                grad.tpsClass !== ""
            ))
            .map(grad => {
                if (grad.isActive && (grad.userType === "alumni" || grad.userType === 'student')) {

                    // This only pushed data to csvData if there is at least one e-mail address listed for the selected alumni member
                    if(grad.personalEmail !== "" || grad.workEmail !== ""){
                        csvData.push([grad.givenFirstName, grad.lastName, grad.tpsClass, grad.personalEmail, grad.workEmail])
                    }
                    return <Grid item sm={12} md={4} key={grad.userId}>
                        <GradCard
                            key={grad.userId}
                            userId={grad.userId}
                            givenFirstName={grad.givenFirstName}
                            lastName={grad.lastName}
                            militaryRank={grad.militaryRank}
                            personalEmail={grad.personalEmail}
                            track={grad.track}
                            tpsClass={grad.tpsClass}
                            workEmail={grad.workEmail}
                            updateAfterDeleteGrad={updateAfterDeleteGrad}
                        /></Grid>
                } else { return null }
            })

        return (
            <Box>
                <Grid container style={{ marginLeft: "2rem" }}>
                    <Grid item>
                        {downloadEmail ? <CSVDownload data={csvData} target="_blank" /> : null}
                            {showDownloadEmailButton ? <Button variant="contained" color="primary" onClick={handleClick}>Download E-mail Addresses</Button> : null}
                    </Grid>
                    <Grid item>
                        <p style={{ textAlign: "left", width: "90%" }}>For privacy reasons, alumni do not show up in search results until that alumni member has logged in to
                        this site and updated their profile. This does not apply to deceased alumni members. They have no address,
                        phone or e-mail information to protect, and they will be added as time allows since each of these alumni
                        members will require some research to properly populate their profile.
                        </p>
                    </Grid>
                    {gradToDisplay}
                </Grid>
            </Box>
        )
}

DisplayGrads.propTypes = {
    allGrads: PropTypes.array
}

export default DisplayGrads
