/*
 * This is called by <BioDropdown /> and <EditABio />
 * Need to get userId of Bio To Add To
 * <MoreInfo />
 *    <GetBioListPartial /> returns the following data on all MongoDB Bios:
 *       givenFirstName
 *       lastName
 *       militaryRank
 *       officialTitle (Bio Official Title)
 *       publicBio (Boolean)
 *       tpsClass
 *       userId (Number)
 *       _id of Bio
 */
import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles((theme) => ({
   formControl: {
     margin: theme.spacing(1),
     minWidth: 120,
   },
   selectEmpty: {
     marginTop: theme.spacing(2),
   },
 }))

const BioDropdown = ({bioList, getSelectedBio}) => {

   const classes = useStyles();

   const [ userIdOfBioToEdit, setUserIdOfBioToEdit ] = useState('')

   const handleChange = (e) => {
      setUserIdOfBioToEdit(e.target.value)
      getSelectedBio(e.target.value)
   }

   const menuItemsToDisplay = bioList.map( bio => {
      return(<MenuItem key={bio.userId} value={bio.userId}>
                  { bio.lastName + ', ' +  bio.givenFirstName + ', ' + bio.tpsClass }
             </MenuItem>)
   } )

   return (
      <Grid item xs={12}>
         <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Select a Bio to Edit</InputLabel>
            <Select
               labelId="demo-simple-select-outlined-label"
               id="demo-simple-select-outlined"
               value={userIdOfBioToEdit}
               onChange={handleChange}
               label="Select a Bio to Edit"
               style={{width: '400px'}}
               >
               <MenuItem value="">
                     <em>None</em>
               </MenuItem>
               { menuItemsToDisplay }
            </Select>
         </FormControl>
      </Grid>
   )
}

BioDropdown.propTypes = {
   bioList: PropTypes.array,
   getSelectedBio: PropTypes.func
}

export default BioDropdown
