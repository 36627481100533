/* This is the top level Page that has three Pages below it
 * 
 * <ManagePhotos />
 *      <ManageBioPhotos />
 * 
 *          <GetBioList /> was replaced by <GetBioListPartial />
 * 
 *          <MySelect />
 *          <AddBioPhoto />
 *          <ShowBioPhotoToManage />
 * 
 *      <ManageClassPatches />
 *          <MySelect />
 *          <AddClassPatch />
 *          <Snackbar />
 *          <ShowClassPatchesToManage />
 * 
 *      <ManageClassPhotos />
 *          <MySelect />
 *          <AddClassPhoto />
 *          <Snackbar />
 *          <ShowClassPhotosToManage />
 * 
 *      <ManageHistoryPhotos />
*/
import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

const ShowClassPatchesToManage = ({classPatch}) => {
    return (
        <Box style={{marginTop: '3rem'}} >
            <Container>
                <Grid container item xs={3} direction='row' style={{marginTop: '1rem', marginRight: '2rem'}} >
                     {classPatch.classPatchFilename.startsWith('https://')
                     ?
                        <img
                              style={{width: '100%'}}
                              alt={classPatch.tpsClass}
                              src={`${classPatch.classPatchFilename}`}
                        />
                     :
                        <img
                              style={{width: '100%'}}
                              alt={classPatch.tpsClass}
                              src={`/images/class-patches/${classPatch.tpsClass}/${classPatch.classPatchFilename}`}
                        />
                     }
                </Grid>
            </Container>
        </Box>
    )
}

ShowClassPatchesToManage.propTypes = {
    classPatch: PropTypes.object
}

export default ShowClassPatchesToManage
