/*
 * Alum clicks on EDIT a Military Unit in their bio
 */
import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const EditMilitaryUnitForOneGrad = props => {

    const { bio, bioMilitaryUnitIdToEdit, handleCancelMilitaryUnitForOneGrad, handleSubmitMilitaryUnitForOneGrad } = props

    const [militaryUnit, setMilitaryUnit] = useState('')
    const [indexToEdit, setIndexToEdit] = useState(-1)

    useEffect( () => {
        bio.militaryUnit.filter( (militaryUnit, index) => {
            if(militaryUnit._id === bioMilitaryUnitIdToEdit){
                setMilitaryUnit(militaryUnit.militaryUnit)
                setIndexToEdit(index)
                return null
            } else {
                return null
            }
        } )
    }, [bio.militaryUnit, bioMilitaryUnitIdToEdit] )

    const handleCancel = () => {
        handleCancelMilitaryUnitForOneGrad()
    }

    const handleMilitaryUnitChange = (e) => {
        setMilitaryUnit(e.target.value)
    }

    const handleSubmit = () => {
        bio.militaryUnit[indexToEdit].militaryUnit = militaryUnit

        handleCancelMilitaryUnitForOneGrad()
        handleSubmitMilitaryUnitForOneGrad(bio)
    }

    return (
        <Box style={{ marginTop: '3rem', marginBottom: '3rem' }} >
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='h4'>
                            Add A Military Unit
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: '3rem', marginBottom: '1rem' }} >
                        <TextField value={militaryUnit} name='militaryUnit' label='Military Unit' variant='outlined' style={{ width: '100%' }} onChange={handleMilitaryUnitChange} />
                    </Grid>

                    <Grid container style={{marginTop: '2rem'}}>
                        <Grid item xs={12} md={1} style={{marginRight: '2rem'}}>
                            <Button variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Button variant='contained' color='primary' onClick={handleCancel}>Cancel</Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    )
}

EditMilitaryUnitForOneGrad.propTypes = {
    bio: PropTypes.object,
    bioMilitaryUnitIdToEdit: PropTypes.string,
    handleCancelMilitaryUnitForOneGrad: PropTypes.func,
    handleEditMilitaryUnitForOneGrad: PropTypes.func,
    handleSubmitMilitaryUnitForOneGrad: PropTypes.func
}

export default EditMilitaryUnitForOneGrad
