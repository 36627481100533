import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const AnalyticBadRegisters = ({badUserInfo}) => {

  const ids = badUserInfo.map( (u, i) => {

    return (
      <Grid key={i}>
        <Typography variant='h5' align='left'>
          {u.userId} {u.userName}
        </Typography>
      </Grid>
    )
  } )

  return (
    <Grid container item xs={12} sm={6} justify='flex-start' style={{marginTop: '1rem'}}>
      <Typography variant='h5'>
        Bad ids are: {ids}
      </Typography>
    </Grid>
  )
}

AnalyticBadRegisters.propTypes = {
  badUserInfo: PropTypes.array
}

export default AnalyticBadRegisters
