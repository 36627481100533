/*
 * This is a Component on the top level Page PhotoCollections for displaying photos to users.
 */
import React, { useCallback, useEffect, useState } from 'react'
import axios                                       from 'axios'
import Box                                         from '@material-ui/core/Box'
import Button                                      from '@material-ui/core/Button'
import Container                                   from '@material-ui/core/Container'
import Gallery                                     from 'react-photo-gallery'
import { get_notable_alumni }                      from '../Helpers'
import GetOneBio                                   from "../Data/GetOneBio"
import Grid                                        from '@material-ui/core/Grid'
import { Link }                                    from "react-router-dom"
import Popup                                       from "reactjs-popup";
import PropTypes                                   from 'prop-types'
import { store }                                   from "../Redux/Store"
import Typography                                  from '@material-ui/core/Typography'
import { userIdOfBioToShowAction }                 from '../Redux/Actions'

const ShowPhotosFromPhotoCollection = ({photos, selectedCollection}) => {

   const [ allGrads,          setAllGrads          ] = useState([])
   const [ photoDescription,  setPhotoDescription  ] = useState('')
   const [ nahfUrl,           setNahfUrl           ] = useState('')
   const [ photoSRC,          setPhotoSRC          ] = useState('')
   const [ showBioButton,     setShowBioButton     ] = useState(false)
   const [ showNAHFButton,    setShowNAHFButton    ] = useState(false)
   const [ userIdForBio,      setUserIdForBio      ] = useState(-1)

   /* If userId is 10 or higher and an BIO exists for that Grad show BIO button */
   const getBio = useCallback( (data) => {
      if (data.publicBio) {
         setShowBioButton(true)
      } else {
         setShowBioButton(false)
      }
   }, [] )

   const photoClick = (e, i) => {
      let slashIndex = -1
      let userId = -1
      setPhotoDescription(photosToShow[i.index].description)

      let photoFilename = ''
      photoFilename = photosToShow[i.index].src
      const photoDescToCheck = photosToShow[i.index].description

      /* Speed up photo loading for photo array, Re-name class photos if photoFilename starts with sm- */
      if ( photoFilename.startsWith('/images/class-pictures/') ) {
         if(photoFilename.slice(29, 32) === 'sm-') {
               photoFilename = photoFilename.slice(0,29) + photoFilename.slice(32,photoFilename.length)
         }
      }

      /* Speed up photo loading for photo array, Re-name class patches if photoFilename starts with sm- */
      if ( photoFilename.startsWith('/images/class-patches/') ) {
         if(photoFilename.slice(28, 31) === 'sm-') {
               photoFilename = photoFilename.slice(0,28) + photoFilename.slice(31,photoFilename.length)
         }
      }

      /* Speed up photo loading for photo array, Re-name astronaut photos if photoDescription contains "Astronaut qualified",
         Re-name General Officer photos if photoDescription contains "General ",
         Re-name Commandant photos if photoDescription contains "Commandant ",
         or "See Bio for more on this grad", these are of the form /images/userId/fileName,
         Buzz Aldrin photo won't be changed as he does not have a userId, but that is just a single photo */
      if (
         photoDescToCheck.includes('Astronaut qualified') ||
         photoDescToCheck.includes('ARPS Class I') ||
         photoDescToCheck.includes('General ') ||
         photoDescToCheck.includes('Admiral ') ||
         photoDescToCheck.includes('Commandant ') ||
         photoDescToCheck.includes('Prop Wash ') ||
         photoDescToCheck.includes('SETP Iven C. Kincheloe ') ||
         photoDescToCheck.includes('SETP J. H. Doolittle ') ||
         photoDescToCheck.includes('SETP Ray E. Tenhoff ') ||
         photoDescToCheck.includes('Robert J. Collier ') ||
         photoDescToCheck.includes('USAF TPS Distinguished Alumnus ') ||
         photoDescToCheck.includes('USAF TPS Robert L. Wenning ') ||
         photoDescToCheck.includes('USAF TPS David B. Barnes ') ||
         photoDescToCheck.includes('USAF TPS A. B. Honts ') ||
         photoDescToCheck.includes('USAF TPS Outstanding FTE ') ||
         photoDescToCheck.includes('USAF TPS H. M. Ekeren ') ||
         photoDescToCheck.includes('USAF TPS Empire Test Pilot School Academic Achievement ') ||
         photoDescToCheck.includes('USAF TPS R.L. Jones ') ||
         photoDescToCheck.includes('USAF TPS Liethen-Tittle ') ||
         photoDescToCheck.includes('A.B. Honts ')
      ) {
         /* This IF statement short-circuits if the photoFilename starts with https://. That allows
            photos from aws to display properly. */
         if (!photoFilename.startsWith('https://')) {
            photoFilename = photoFilename.slice(8,photoFilename.length)
            slashIndex = photoFilename.search('/')
            userId = photoFilename.slice(0,slashIndex)
            photoFilename = photoFilename.slice(slashIndex + 1, photoFilename.length)
            if ( photoFilename.startsWith('sm-') ) {
                  photoFilename = photoFilename.slice(3, photoFilename.length)
            }
            photoFilename = '/images/' + userId + '/' + photoFilename
         }
      }

      setPhotoSRC(photoFilename)

      /* Add BIO button if BIO exists, functions only for photoCollections for:
         Astronauts (Alphabetical)
         General Officers (Alphabetical)
         National Aviation Hall of Fame (Alphabetical)
         
         This relies on the file path being /images/userId/... which rules out some of the
         Commandants for now. Some Commandants are not grads, so no userId unless I decide to
         give them one */
      if (selectedCollection === 'Astronauts (Alphabetical)' ||
         // selectedCollection === 'Commandants (Alphabetical)' ||
         // selectedCollection === 'Commandants (Chronological)' ||
         selectedCollection === 'General Officers (Alphabetical)' ||
         selectedCollection === 'National Aviation Hall of Fame (Alphabetical)'
      ) {
         userId = photos[i.index].photoFilename.slice(8,13)
         slashIndex = userId.search('/')
         userId = userId.slice(0,slashIndex)
         setUserIdForBio(Number(userId))
      } else {
         /* Makes sure Bio and NAHF buttons don't show when they are not supposed to show */
         setUserIdForBio(-1)
         setShowBioButton(false)
         setShowNAHFButton(false)
      }
   }

   const photosToShow = photos.map(p => {        
      return (
         {
               description: p.photoDescription,
               height: p.height,
               src: p.photoFilename,
               width: p.width,
         }
      )
   })

   const handleClick = (e) => {
      if( e > 9 ){
         store.dispatch( userIdOfBioToShowAction( e ) )
      }
   }

   /* If userId is 10 or higher and a NAHF link exists for that Grad show
      Hall of Fame button */
   useEffect(() => {       
      let nahfUrlExists = false
      if (userIdForBio > 9) {
         nahfUrlExists = allGrads.filter((g) => {
               if (g.nahfUserId === userIdForBio && g.urlText === 'National Aviation Hall of Fame Inductee') {
                  setNahfUrl(g.nahf_url)
                  setShowNAHFButton(true)
                  return g
               }
               return null
         })
         if (nahfUrlExists.length === 0) {
               setShowNAHFButton(false)
         }
      }
   }, [allGrads, userIdForBio])

   /* GET ALL Notable Alumni via PHP Server so National Aviation Hall of Fame link is available if it exists */
   useEffect(() => {
      axios.get(get_notable_alumni)
         .then(res => res.data)
         .then(data => {
               setAllGrads(data.allGrads)
               return null
         })
   }, [] )

   return (
      <Box style={{marginTop: '2rem'}}>
      {userIdForBio > 9 ? <GetOneBio getBio={getBio} id={Number(userIdForBio)} /> : null}
         <Container>
               <Grid>
                  {true ?
                     <Popup
                           closeOnDocumentClick
                           closeOnEscape={true}
                           modal={true}
                           trigger={<Button><Gallery photos={photosToShow} onClick={photoClick} /></Button>}
                     >
                           {/* <PhotoCollectionCard photoDescription={photoDescription} photoSRC={photoSRC} /> */}
                           <Grid>
                              <img
                                 alt={photoDescription}
                                 src={photoSRC}
                                 style={{width: '75%', height: '75%'}}
                              />
                              <Typography variant='h6'>{photoDescription}</Typography>
                           </Grid>
                           {showBioButton ?
                              <Button color='primary' variant='contained'>
                                 <Link to="/ShowOneBioPage" id={userIdForBio} onClick={() => handleClick(userIdForBio)} style={{textDecoration: 'none'}} >
                                 <Typography  variant='body2' style={{color: 'white'}} >
                                          Bio
                                       </Typography>
                                 </Link>
                              </Button>
                              :
                              null
                           }
                           {showNAHFButton ?
                              <a
                                 href={nahfUrl}
                                 target='_blank'
                                 rel='noopener noreferrer'
                              >
                                 <Button color='primary' variant='contained' style={{marginLeft: '2rem'}}>
                                       <Typography  variant='body2' style={{color: 'white'}} >
                                             Hall of Fame Page
                                          </Typography>
                                 </Button>
                              </a>
                              :
                              null
                           }
                     </Popup>
                     :
                     null
                  }
               </Grid>
         </Container>
      </Box>
   )
}

ShowPhotosFromPhotoCollection.propTypes = {
   photos: PropTypes.array,
   selectedCollection: PropTypes.string
}

export default ShowPhotosFromPhotoCollection
