import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Grid, InputLabel } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import "./MySelect.css"
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function SimpleSelect(props) {

  const { label, name, options = [], onChange } = props

  const printRanks = options.map( (option, index) => <MenuItem key={index} value={option}>{option}</MenuItem> )

  const classes = useStyles();
  const [option, setOption] = useState('');

  const handleChange = (event) => {
    setOption(event.target.value);
    onChange(event)
  };

  return (
    <Grid>
      <FormControl className={classes.formControl} variant='outlined' >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          className="MySelect-select"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={option}
          name={name}
          onChange={handleChange}
        >
          {printRanks}
        </Select>
      </FormControl>
    </Grid>
  );
}

SimpleSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func
}