import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

const YbArpsFacultyPage = ({pageData, returnToTOC}) => {
  const handleReturnToTOC = () => {
    returnToTOC()
  }

  return (
    <Grid container>

      {/* Page Title */}
      <Grid item xs={12}>
        <Typography variant='h4'>
          <Box letterSpacing={2}>
            ARPS Faculty List from mid-to-late 1967
          </Box>
        </Typography>
      </Grid>

      {/* One full width para */}
      <Grid item xs={12} style={{marginTop: '2rem'}}>
        <Typography variant='h6' align='justify' component='div'>
          <Box fontFamily='Roboto'>
            {pageData.paras[0].paraText}
          </Box>
        </Typography>
      </Grid>

        {/* Photo of ARPS Faculty List */}
        <Grid container>
          <Grid item xs={12} style={{marginTop: '1rem'}}>
              <img
                src={`${pageData.photos[0].photoFilename}`}
                alt='arps-faculty-list'
                style={{width: '80%', height: '80%', border: '5px solid rgb(34,28,110)', padding: '0.25rem'}}
              />
          </Grid>
      </Grid>

      <Grid container justify='center' style={{marginTop: '2rem', marginBottom: '5rem'}}>
        <Grid>
          <Button variant='outlined' onClick={handleReturnToTOC}>Return to Table of Contents</Button>
        </Grid>
      </Grid>

    </Grid>
  )
}

YbArpsFacultyPage.propTypes = {
  pageData: PropTypes.object
}

export default YbArpsFacultyPage
