/* This is the top level Page that has three Pages below it
 * 
 * <ManagePhotos />
 *      <ManageBioPhotos />
 * 
 *          <GetBioList /> was replaced by <GetBioListPartial />
 * 
 *          <MySelect />
 *          <AddBioPhoto />
 *          <ShowBioPhotoToManage />
 * 
 *      <ManageClassPhotos />
 *          <MySelect />
 *          <AddClassPhoto />
 *          <Snackbar />
 *          <ShowClassPhotosToManage />
 * 
 *      <ManageHistoryPhotos />
*/

import React, { useState } from 'react'
import axios from 'axios'
import { photoDims } from '../config'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Typography from '@material-ui/core/Typography'

const AddBioPhoto = (props) => {

    const { addBioPhotoToLocalState, bio } = props

    const [ bioPhotoIndex ] = useState(0)
    const [ bioPhotoWidth, setBioPhotoWidth ] = useState(photoDims.bioPhotoWidth)
    const [ caption, setCaption ] = useState('')
    const [ filename, setFilename ] = useState('')

    /* Adds typed in caption to local state */
    const handleCaptionChange = (e) => {
        setCaption(e.target.value)
    }

    /* Adds typed in filename to local state */
    const handleFilenameChange = (e) => {
        setFilename(e.target.value)
    }

    /* Adds typed in photo width to local state, default is 80% */
    const handleBioPhotoWidthChange = (e) => {
        setBioPhotoWidth(e.target.value)
    }

    /* Add photo info to bio and then send to MongoDB to update it, then send
       the updated bio back up to <ManageBioPhotos />. Also, clear the input
       from the two text entry fields. */
    const handleSubmit = () => {

        bio.bioPhoto.push({
            bioPhotoCaption: caption,
            bioPhotoFilename: filename,
            bioPhotoIndex: bioPhotoIndex,
            bioPhotoWidth: bioPhotoWidth
        })

        axios.put(node_server + 'bio/AddBioPhoto', {
            bio,
            CLIENT_PUBLIC_TOKEN
        })
            .then( res => res.data )
            .then( data => {
                setFilename('')
                setCaption('')
                addBioPhotoToLocalState( data )
            } )
    }

    return (
        <Grid style={{marginTop: '3rem'}} >
            <Container>
                <Grid>
                    <form>
                        <Typography variant='h4' >
                            Add Bio Photo
                        </Typography>
                        <Grid container justify='space-between'>
                            <Grid item xs={12} sm={10} style={{marginTop: '1rem'}} >
                                <TextField value={filename} label='Filename' placeholder='Enter Filename Here' style={{width: '100%'}} onChange={handleFilenameChange} />
                            </Grid>
                            <Grid item xs={12} sm={1} style={{marginTop: '1rem'}} >
                                <TextField value={bioPhotoWidth} label='Photo Width' style={{width: '100%'}} onChange={handleBioPhotoWidthChange} />
                            </Grid>
                        </Grid>
                        <Grid>
                            <TextareaAutosize value={caption} placeholder='Enter Photo Caption Here' rowsMin={4} style={{width: '100%', marginTop: '2rem'}} onChange={handleCaptionChange} />
                        </Grid>
                        <Grid container item justify='flex-start' style={{marginTop: '1.5rem'}} >
                            {filename.length > 0 ?
                                <Button variant="contained" color="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            :
                                <Button disabled variant="contained" color="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            }
                        </Grid>
                    </form>
                </Grid>
            </Container>
        </Grid>
    )
}

AddBioPhoto.propTypes = {
    addBioPhotoToLocalState: PropTypes.func,
    bio: PropTypes.object
}

export default AddBioPhoto
