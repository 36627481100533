import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const Breitling = () => {
    return (
        <Grid container>
            <Grid item xs={12} style={{marginTop: '7rem'}} >
                <Typography variant='h4' >
                    Breitling Watch Order
                </Typography>
            </Grid>
            <Grid container style={{marginLeft: '5rem', marginRight: '5rem', marginTop: '2rem'}} >
                <Grid item xs={4}>
                    <Typography variant='h6' align='left' >
                        The time has arrived for us to try to place another customized Breitling watch order.
                        Displayed is the updated Titanium Aerospace Evo model, which is very similar
                        to that which we ordered in the past.  The watch dial is black and will be customized
                        with a small hi-res color image of the TPS Grad patch.  The retail price for this watch is $4,675.
                        Good news for you is that your cost will be about $3,100.  
                    </Typography>
                    <Typography variant='h6' style={{marginTop: '2rem'}} align='left' >
                        Before we get into any other details, I first need to see if there is enough interest in this
                        watch to meet the 25 minimum order.  If you are positively interested in participating in a
                        group order for this Breitling watch, please email <a href='mailto:Brigitte.tamashiro.1@us.af.mil'>Brigitte.tamashiro.1@us.af.mil</a> to let me know.
                        If/when we reach the 25 watch minimum requirement, then those who have shown interest will receive much 
                        more information and we’ll go from there. 
                    </Typography>
                </Grid>
                <Grid item xs={7} style={{marginLeft: '1rem'}} >
                    <img 
                        src='../images/Aerospace Evo noir 1A (005).JPG'
                        alt='breitling watch'
                        style={{width: '50%'}}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Breitling
