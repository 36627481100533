/*
 * This displays for small screen sizes instead of the Navbar buttons
 */
import React, { useState } from 'react';
import './HamburgerSmallDisplay.css'
import { bioTblUserDataAction, goodLoginAction, logoutAction, isAdminAction, isAlumniAction,
         isSuperUserAction, userIdOfBioToEditAction } from "../Redux/Actions"
import { Grid } from '@material-ui/core/';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { store } from "../Redux/Store"

const useStyles = makeStyles((theme) => ({
   root: {
     flexGrow: 1,
   },
   menuButton: {
     marginRight: theme.spacing(2),
   },
 }));

const HamburgerSmallDisplay = props => {
   
   const classes = useStyles();

   const [anchorEl, setAnchorEl] = useState(null);

   const { goodLogin, showAlumniBioNavbarButton, showClassPagesNavbarButton, showHomeNavbarButton } = store.getState()

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

   const handleClose = () => {
      setAnchorEl(null);
  };

  const handleLogout = () => {
      setAnchorEl(null);
      store.dispatch(goodLoginAction(false))
      store.dispatch(isAdminAction(false))
      store.dispatch(isAlumniAction(false))
      store.dispatch(isSuperUserAction(false))
      store.dispatch(logoutAction())
      store.dispatch(userIdOfBioToEditAction(''))
      store.dispatch(bioTblUserDataAction({}))
  };

  return (
   <Grid id='hamburger-small-display'>
      <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleClick}>
         <MenuIcon />
      </IconButton>

      <Menu
         id="simple-menu"
         anchorEl={anchorEl}
         keepMounted
         open={Boolean(anchorEl)}
         onClose={handleClose}
      >
         {showHomeNavbarButton ?
            <MenuItem onClick={handleClose}>
               <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                  Home
               </Link>
            </MenuItem>
            :
            null
         }
         {showAlumniBioNavbarButton ?
            <MenuItem onClick={handleClose}>
               <Link to="/BioList" style={{ textDecoration: "none", color: "inherit" }}>
                  Alumni Bios
               </Link>
            </MenuItem>
            :
            null
         }
         {showClassPagesNavbarButton ?
            <MenuItem onClick={handleClose}>
               <Link to="/ClassPages" style={{ textDecoration: "none", color: "inherit" }}>
                  Class Pages
               </Link>
            </MenuItem>
            :
            null
         }
         {showClassPagesNavbarButton ?
            <MenuItem onClick={handleClose}>
               <Link to="/PhotoCollections" style={{ textDecoration: "none", color: "inherit" }}>
                  Photos
               </Link>
            </MenuItem>
            :
            null
         }
         {showClassPagesNavbarButton ?
            <MenuItem onClick={handleClose}>
               <Link to="/YearbookPage" style={{ textDecoration: "none", color: "inherit" }}>
                  Yearbook
               </Link>
            </MenuItem>
            :
            null
         }
         {goodLogin ?
            <MenuItem onClick={handleLogout}>
               <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                  Logout
               </Link>
            </MenuItem>
            :
            <MenuItem onClick={handleLogout}>
               <Link to="/Login" style={{ textDecoration: "none", color: "inherit" }}>
                  Alumni Login
               </Link>
            </MenuItem>
         }
      </Menu>
   </Grid>
  )
}

export default HamburgerSmallDisplay