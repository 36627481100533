/*
 * This is the original PHP / MySQL version that is no longer used after
 * migration to the Nodejs / MongoDB version
 */

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { get_single_bio } from '../Helpers'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { store } from '../Redux/Store'
import Typography from '@material-ui/core/Typography'

const ShowOneBio = props => {

    const [bio, setBio] = useState('')

    useEffect( () => {
        const { userIdOfBioToShow } = store.getState()

        let getBioData = new FormData()

        getBioData.append('userId', userIdOfBioToShow)
        
        axios.post(get_single_bio, getBioData)
            .then(res => res.data)
            .then(data => {
                // console.log('ShowOneBio Component data is ', data)
                setBio(data.bio)
            })
    }, [] )

    return (
        <Box style={{marginTop: '6rem'}}>
            <Container>
                <Paper style={{ margin: '2rem', backgroundColor: 'rgba(211, 211, 211, 0.4)' }} >
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography variant='h4' style={{ paddingTop: '1rem' }} >
                                {bio.officialTitle}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>

                        {bio.headerPara1 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginTop: '1rem', marginLeft: '1rem' }} >
                                    {bio.headerPara1}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara1 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara1}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara2 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara2}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara2 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara2}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara3 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara3}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara3 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara3}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara4 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara4}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara4 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara4}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara5 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara5}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara5 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara5}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara6 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara6}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara6 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara6}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara7 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara7}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara7 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara7}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara8 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara8}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara8 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara8}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara9 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara9}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara9 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara9}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara10 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara10}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara10 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara10}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara11 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara11}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara11 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara11}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara12 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara12}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara12 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara12}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara13 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara13}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara13 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara13}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara14 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara14}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara14 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara14}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara15 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara15}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara15 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara15}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara16 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara16}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara16 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: 'rem' }} >
                                    {bio.bioPara16}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara17 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara17}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara17 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara17}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara18 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara18}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara18 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara18}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara19 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara19}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara19 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara19}
                                </Typography>
                            </Grid>
                            : null}

                        {bio.headerPara20 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '1rem' }} >
                                    {bio.headerPara20}
                                </Typography>
                            </Grid>
                            : null}
                        {bio.bioPara20 !== "null" ?
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginBottom: '1rem' }} >
                                    {bio.bioPara20}
                                </Typography>
                            </Grid>
                            : null}

                    </Grid>
                </Paper>
            </Container>
        </Box>
    )
}

export default ShowOneBio
