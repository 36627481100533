/*
 * This page adds a story and is used by both alumni and superUsers
 *
 * <AddAStoryPage />
 *      <AddAStoryTitleComponent />
 *      <ShowAStoryTitle />
 *      <ShowAStoryComponent />
 *      <AddAStoryTextBlockComponent />
 */
import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

const AddAStoryTextBlockComponent = ({handleSubmitAStory, handleSubmitATextBlock}) => {

    const [ textBlock, setTextBlock ] = useState('')

    const handleSubmit = () => {
        handleSubmitATextBlock(textBlock)
        setTextBlock('')
    }

    const handleTextBlockChange = (e) => {
        setTextBlock(e.target.value)
    }

    return (
        <Box>
            <Container>
                <Grid container style={{marginTop: '1rem', marginLeft: '1.5rem'}}>
                    <Grid item xs={12} style={{marginTop: '1rem'}} >
                        <TextareaAutosize rowsMin={10} style={{width: '100%'}} onChange={handleTextBlockChange}/>
                    </Grid>
                        {textBlock.length > 2 ?
                            <Button color='primary' variant='contained' onClick={handleSubmit} style={{marginTop: '1rem'}} >
                                Submit Text Block
                            </Button>
                        :
                            <Button disabled color='primary' variant='contained' style={{marginTop: '1rem'}} >
                                Submit Text Block
                            </Button>
                        }
                        {textBlock.length > 2 ?
                            <Button color='primary' variant='contained' onClick={handleSubmit} style={{marginTop: '1rem', marginLeft: '1rem'}} >
                                Submit Story
                            </Button>
                        :
                            <Button disabled color='primary' variant='contained' style={{marginTop: '1rem', marginLeft: '1rem'}} >
                                Submit Story
                            </Button>
                        }
                </Grid>
            </Container>
        </Box>
    )
}

AddAStoryTextBlockComponent.propTypes = {
    handleSubmitAStory: PropTypes.func,
    handleSubmitATextBlock: PropTypes.func
}

export default AddAStoryTextBlockComponent
