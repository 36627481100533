/*
 * Send to <AddRemoveButtons /> via props:
 *  addEntry is a Boolean
 *  handleAddRemoveButtons is a function that toggles the addEntry Boolean
 *  ms1 is a text string to display next to the switch
 *  ms2 is a text string to display next to the switch
 */

import React from 'react'
import AddIcon from '@material-ui/icons/Add';
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import PropTypes from 'prop-types'
import RemoveIcon from '@material-ui/icons/Remove'
import Typography from "@material-ui/core/Typography"

const AddRemoveButtons = props => {

    const { addEntry, handleAddRemoveButtons, msg1, msg2 } = props

    const handleClick = () => {
        handleAddRemoveButtons()
    }

    return (
        <Grid container style={{margin: '2rem'}} >
            {addEntry ?
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={3}>
                        <Typography variant='h5'>
                            { msg1 }
                            </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClick} style={{border: '1px solid lightgray'}} >
                            <RemoveIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                :
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={3}>
                        <Typography variant='h5'>
                            { msg2 }
                            </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClick} style={{border: '1px solid lightgray'}} >
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

AddRemoveButtons.propTypes = {
    addEntry: PropTypes.bool,
    handleAddRemoveButtons: PropTypes.func,
    msg1: PropTypes.string,
    msg2: PropTypes.string
}

export default AddRemoveButtons
