/**
 * This Component is called from ShowOneYearbookPage
 */
import React, { useState } from 'react'
import { Button, Grid, TextField } from '@material-ui/core/'
import { Document, Page, pdfjs } from 'react-pdf'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const Pdf = ({pageData, returnToTOC}) => {

   pdfjs.GlobalWorkerOptions.workerSrc = 
   `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
   
   const [ numPages, setNumPages ] = useState(null);
   const [ pageNumber, setPageNumber ] = useState(1);
   const [ tempPageNumber, setTempPageNumber ] = useState(1)
   const [ showPageNumberError, setShowPageNumberError ] = useState(false)

   const handleReturnToTOC = () => {
      returnToTOC()
    }
 
   function onDocumentLoadSuccess({ numPages }) {
   setNumPages(numPages);
   setPageNumber(1);
   }

   const handleBack = () => {
      setPageNumber(pageNumber - 1)
   }

   const handleChange = (e) => {
      setTempPageNumber(e.target.value)
      if (Number(e.target.value) > 0 && Number(e.target.value) <= numPages && e.target.value !== '') {
         setPageNumber(Number(e.target.value))
         setShowPageNumberError(false)
      }
      else {
         setShowPageNumberError(true)
      }
   }

   const handleNext = () => {
      setPageNumber(pageNumber + 1)
   }

   return (
      <Grid container justify='center' style={{marginTop: '-2rem'}}>
         <Grid container justify='center'>
            <Document
               file={`./pdf/${pageData.fileName}`}
               onLoadSuccess={onDocumentLoadSuccess}
               >
               <Page pageNumber={pageNumber} height={window.innerHeight - 175} />
            </Document>
         </Grid>
         <Grid container justify='space-between' style={{width: '70%', marginTop: '2rem', marginBottom: '2rem'}}>
            <Button variant='outlined' onClick={handleReturnToTOC}>Return to Table of Contents</Button>
            {pageNumber === 1 ?
               <Button color='primary' variant='contained' disabled >Back</Button>
               :
               <Button color='primary' variant='contained' onClick={handleBack} >Back</Button>
            }
            <Typography variant='h6' style={{alignSelf: 'center'}}>
               Page {pageNumber} of {numPages}
            </Typography>
            {pageNumber === numPages ?
               <Button color='primary' variant='contained' disabled >Next</Button>
               :
               <Button color='primary' variant='contained' onClick={handleNext} >Next</Button>
            }
            {showPageNumberError ?
               <TextField error variant='outlined' value={tempPageNumber} onChange={handleChange} label='No Such Page Number' />
               :
               <TextField variant='outlined' value={pageNumber} onChange={handleChange} label='Go To Page' />
            }
         </Grid>
      </Grid>
   )
}

Pdf.propTypes = {
   returnToTOC: PropTypes.func,
 }

export default Pdf