import React, { useEffect, useState }                       from 'react'
import axios                                                from 'axios'
import EditPhoto                                            from './EditPhoto'
import { Button, Container, Grid, Paper, TextField }        from '@material-ui/core'
import { Typography }                                       from '@material-ui/core'
import                                                      './PhotoSearch.css'
import MySelect                                             from '../Components/MySelect'
import { CLIENT_PUBLIC_TOKEN, node_server }                 from '../Helpers'
import { photoSearchOptions, superUserPhotoSearchOptions }  from '../config'
import Snackbar                                             from '../Atoms/Snackbar'
import { store }                                            from '../Redux/Store'

const PhotoSearch = props => {

   const { superUser } = store.getState()

   const [ caption,              setCaption              ] = useState('')
   const [ fullFilename,         setFullFilename         ] = useState('')
   const [ id,                   setId                   ] = useState('')
   const [ photos,               setPhotos               ] = useState([])
   const [ openEditPhoto,        setOpenEditPhoto        ] = useState(false)
   const [ narrowPhotoTags,      setNarrowPhotoTags      ] = useState('')
   const [ photoTags,            setPhotoTags            ] = useState([])
   const [ photoTagString,       setPhotoTagString       ] = useState([])
   const [ searchText,           setSearchText           ] = useState('')
   const [ selectedSearchOption, setSelectedSearchOption ] = useState('')
   const [ showOnePhoto,         setShowOnePhoto         ] = useState(false)
   const [ smallFilename,        setSmallFilename        ] = useState('')
   const [ snackBarOpen,         setSnackBarOpen         ] = useState(false)
   const [ tag,                  setTag                  ] = useState('')
   const [ thumbnailFilename,    setThumbnailFilename    ] = useState('')
   const [ tpsClass,             setTpsClass             ] = useState('')

   const handleSnackBar = () => {
      setSnackBarOpen(true)
      setTimeout(() => {
         setSnackBarOpen(false)
     }, 2500)
   }

   useEffect( () => {
      fetchData()
      async function fetchData() {
         axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
         await axios.get( node_server + 'photo/photoTag/photoTags' )
            .then( res => {
               setPhotoTags(res.data)
               axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
            } )
      }
   }, [])

   useEffect( () => {
      fetchData()
      async function fetchData() {
         if (selectedSearchOption === 'Show Photos that need Approval' ) {
            axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
            await axios.get( node_server + 'photo/notApproved' )
               .then( res => {
                  setPhotos(res.data)
                  if (res.data.length === 0 && selectedSearchOption !== '') handleSnackBar()
                  axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
               } )
      }
      }
  }, [selectedSearchOption] )

  
  const onChange = (e) => {
     setSelectedSearchOption(e.target.value)
     setPhotos([])
     setOpenEditPhoto(false)
   }
   
   const handleApprove = (id) => {
      axios.put( node_server + 'photo/approve', {
         CLIENT_PUBLIC_TOKEN,
         id
      })
         .then( res => {
            setPhotos(res.data)
         } )
   }

   const handleCloseOnePhoto = () => {
      setShowOnePhoto(false)
   }

   const handleEdit = async (id) => {
      let photoTags = ''
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      await axios.get(node_server + `photo/${id}`)
         .then( res => {
            console.log('handleEdit res.data is', res.data)
            const len = res.data.photoTags.length
            
            res.data.photoTags.forEach((element,i) => {
               if (i < len - 1) {
                  photoTags = photoTags + element.tagText + ','
               }
               else {
                  photoTags = photoTags + element.tagText 
               }
            });
            setCaption(res.data.caption)
            setFullFilename(res.data.fullFilename)
            setId(res.data._id)
            setPhotoTagString(photoTags)
            setSmallFilename(res.data.smallFilename)
            setThumbnailFilename(res.data.thumbnailFilename)
            setTpsClass(res.data.tpsClass)
            axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
         })
      setOpenEditPhoto(true)
   }

   const handleCloseEditPhoto = () => {
      setOpenEditPhoto(false)
   }

   
   const handleSearchText = (e) => {
      setSearchText(e.target.value)
   }
   
   const handleSelect = (fullFilename, caption) => {
      setFullFilename(fullFilename)
      setCaption(caption)
      setShowOnePhoto(true)
   }
   
   const handleSubmit = (id) => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get( node_server + `photo/caption/${searchText}` )
      .then( res => {
         setPhotos(res.data)
         if (res.data.length === 0 && selectedSearchOption !== '') handleSnackBar()
            axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
      } )
   }
   
   const handleSearchByTag = async (tag) => {
      setTag(tag)
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      await axios.get( node_server + `photo/photoTag/${tag}` )
      .then( res => {
         setPhotos(res.data)
         if (res.data.length === 0 && selectedSearchOption !== '') handleSnackBar()
            axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
      } )
   }
   
   const narrowPhotoTagDisplay = (e) => {
      setNarrowPhotoTags(e.target.value)
   }

   const photosToDisplay = photos.map( p => {
      return(
         <Grid container key={p._id} >
            <Grid style={{ width: "25%", height: "25%" }}>
               <img src={p.smallFilename} style={{ width: "100%", height: "100%" }} onClick={() => handleSelect(p.fullFilename, p.caption)} />
            </Grid>
            <Grid style={{ width: "65%" }}>
               <Typography style={{ textAlign: 'left', marginTop: '1rem', marginLeft: '1rem' }} >
                  {p.caption}
               </Typography>
            </Grid>
            {superUser
               ?
               <Grid>
                  <Grid>
                     <Button variant="contained" color="primary" onClick={() => handleApprove(p._id)}>
                        Approve
                     </Button>
                  </Grid>
                  <Grid style={{marginTop: '2rem'}} >
                     <Button variant="contained" color="primary" onClick={() => handleEdit(p._id)}>
                        Edit
                     </Button>
                  </Grid>
               </Grid>
               :
               null
            }
         </Grid>
      )
   })

   const photoTagsToDisplay = photoTags.map( t => {
      if (t.includes(narrowPhotoTags)) {
         return (
            <Grid key={t} >
               <Typography align='left' style={{marginLeft: '1rem'}} onClick={() => handleSearchByTag(t)}>
                  {t}
               </Typography>
            </Grid>
         )
      }
   })

   return (
      <Container style={{ marginTop: '5rem' }}>
         <Grid >
            {superUser
               ?
               <MySelect name="searchSelection" label="Search Options" options={superUserPhotoSearchOptions} onChange={onChange} value={selectedSearchOption} />
               :
               <MySelect name="searchSelection" label="Search Options" options={photoSearchOptions} onChange={onChange} value={selectedSearchOption} />
            }

            {selectedSearchOption === 'Search Photo Captions'
               ?
               <Grid item xs={12} sm={4} style={{width: '33%'}} >
                  <TextField
                     label='Search Terms'
                     onChange={handleSearchText}
                     style={{width: '300px'}}
                     variant='outlined'
                  />
                  <Grid>
                     <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                        Submit
                     </Button>
                  </Grid>
               </Grid>
               :
               null
            }

            {selectedSearchOption === 'Search by Photo Tag'
               ?
               <div style={{marginBottom: '1rem', width: '100%'}} >
                  <Grid container >
                     <Grid container xs={12} style={{ alignItems: 'flex-start', width: '25%', marginBottom: '1rem' }} >
                        <TextField
                           label='Search Tags For:'
                           onChange={narrowPhotoTagDisplay}
                           style={{width: '430px'}}
                           variant='outlined'
                        />
                     </Grid>
                     <Grid style={{ width: '35%' }} >
                        <Paper className='photoTags' style={{ backgroundColor: "rgba(0, 0, 255, 0.05)" }} >
                           <Grid>
                              { photoTagsToDisplay }
                           </Grid>
                        </Paper>
                  </Grid>
                  <Grid style={{ width: '65%' }} >
                     <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '1rem', marginTop: '2rem' }} >
                        Tag: { tag }
                     </Typography>
                  </Grid>
                  </Grid>
               </div>
               :
               null
            }

            {photos.length > 0 && !showOnePhoto && !openEditPhoto
               ?
               <Grid>
                  { photosToDisplay }
               </Grid>
               :
               null
            }
            {photos.length > 0 && showOnePhoto
               ?
               <Grid container style={{marginBottom: '5rem'}}>
                  <Grid style={{ width: "100%", height: "100%" }}>
                     <img src={fullFilename} style={{ width: "100%", height: "100%" }} />
                  </Grid>
                  <Grid style={{ width: "100%" }}>
                     <Typography style={{ textAlign: 'center', marginTop: '1rem', marginLeft: '1rem' }} >
                        {caption}
                     </Typography>
                  </Grid>
                  <Grid container justifyContent="center" style={{ marginTop: "2rem" }} >
                     <Button variant="contained" color="primary" onClick={() => handleCloseOnePhoto()}>
                        Close
                     </Button>
                  </Grid>
               </Grid>
               :
               null
            }
         </Grid>
         <Grid>
            {openEditPhoto
               ?
               <EditPhoto
                  eCaption={caption}
                  eFullFilename={fullFilename}
                  handleCloseEditPhoto={handleCloseEditPhoto}
                  id={id}
                  ePhotoTags={photoTagString}
                  eSmallFilename={smallFilename}
                  title={'Add Photo Page'}
                  eThumbnailFilename={thumbnailFilename}
                  eTpsClass={tpsClass}
               />
               :
               null
            }
         </Grid>

         {snackBarOpen ?
            <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
               <Grid item sm={6}>
                     <Snackbar msg={'There are no photos that match that request'} type={'success'} />
               </Grid>
            </Grid>
            : null
         }
      </Container>
   )

}

export default PhotoSearch