import React from 'react'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

const YbDecadeButtons = ({ decade, handleDecade }) => {

   const handleClick = () => {
      handleDecade()
   }

  return (
   <Grid container justify='flex-start' style={{marginLeft: '3rem'}}>
      <Button onClick={handleClick}>
         <Paper style={{backgroundColor: 'lightgray'}}>
            <Typography variant='h4' style={{color: 'black', padding: '0.5rem'}}>
               {decade}
            </Typography>
         </Paper>
      </Button>
   </Grid>
  )
}

YbDecadeButtons.propTypes = {
   decade: PropTypes.string,
   handleDecade: PropTypes.func
}

export default YbDecadeButtons