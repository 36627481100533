import React, { Component } from 'react'
import { add_announcement } from '../Helpers'
import axios from "axios"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import DatePicker from "react-datepicker";
import Grid from "@material-ui/core/Grid"
import MySelect from "../Components/MySelect"
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from "react-router-dom"
import { store } from "../Redux/Store"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

export default class NewAnnouncement extends Component {

    constructor(props) {
        super(props)
        this.state = {
            announcementPara1: "",
            announcementPara2: "",
            announcementPara3: "",
            announcementPara4: "",
            announcementPara5: "",
            announcementPara6: "",
            announcementPara7: "",
            announcementPara8: "",
            announcementPara9: "",
            announcementPara10: "",
            announcementSynopsis: "",
            expiresDate: new Date(),
            expireTime: "",
            priority: 0,
            navigateBackToAnnouncementPage: false,
            title: "",
            userId: 0
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleDateChange = date => {
        this.setState({expiresDate: date})
    }

    handleSubmit = () => {

        const { signedInUserId } = store.getState()
        const { announcementPara1, announcementPara2, announcementPara3, announcementPara4, announcementPara5, 
                announcementPara6, announcementPara7, announcementPara8, announcementPara9, announcementPara10,
                announcementSynopsis, expiresDate, expireTime, priority, title } = this.state
        
        let newAnnouncementFormData = new FormData()

        newAnnouncementFormData.append('announcementPara1', announcementPara1)
        newAnnouncementFormData.append('announcementPara2', announcementPara2)
        newAnnouncementFormData.append('announcementPara3', announcementPara3)
        newAnnouncementFormData.append('announcementPara4', announcementPara4)
        newAnnouncementFormData.append('announcementPara5', announcementPara5)
        newAnnouncementFormData.append('announcementPara6', announcementPara6)
        newAnnouncementFormData.append('announcementPara7', announcementPara7)
        newAnnouncementFormData.append('announcementPara8', announcementPara8)
        newAnnouncementFormData.append('announcementPara9', announcementPara9)
        newAnnouncementFormData.append('announcementPara10', announcementPara10)
        newAnnouncementFormData.append('announcementSynopsis', announcementSynopsis)
        newAnnouncementFormData.append('expiresDate', expiresDate)
        newAnnouncementFormData.append('expireTime', expireTime)
        newAnnouncementFormData.append('priority', priority)
        newAnnouncementFormData.append('title', title)
        newAnnouncementFormData.append('userId', signedInUserId)

        axios.post(add_announcement, newAnnouncementFormData)
            .then( res => res.data )
            .then( data => {
                if(data.msg === 'Good Insert'){
                    this.setState({navigateBackToAnnouncementPage: true})
                }
                return 1
            })

    }

    render() {

        const priorities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        const { expiresDate, navigateBackToAnnouncementPage } = this.state

        return (
            <Box style={{ marginTop: "6rem" }}>
                <Typography variant="h3">Add a New Announcement</Typography>
                {navigateBackToAnnouncementPage ? <Redirect to="/AnnouncementLanding" /> : null}
                <form >
                    <Grid container direction="row" justify="center" style={{ width: "90%" }} >
                        <Grid item xs={10}>
                            <TextField fullWidth label="Title" name="title" onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography style={{ marginTop: "1.5rem", textAlign: "left" }}>
                                Synopsis ( Please keep the synopsis to 3 - 5 sentences if possible )
                            </Typography>
                            <TextareaAutosize label="Synopsis" name="announcementSynopsis" style={{ width: "100%", fontSize: "1rem" }} onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography style={{ marginTop: "1.5rem", textAlign: "left" }}>
                                Individual Body Paragraphs Go Below
                            </Typography>
                            <TextareaAutosize label="Announcement Paragraph1" name="announcementPara1" style={{ width: "100%", fontSize: "1rem" }} onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <TextareaAutosize label="Announcement Paragraph2" name="announcementPara2" style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <TextareaAutosize label="Announcement Paragraph3" name="announcementPara3" style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <TextareaAutosize label="Announcement Paragraph4" name="announcementPara4" style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <TextareaAutosize label="Announcement Paragraph5" name="announcementPara5" style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <TextareaAutosize label="Announcement Paragraph6" name="announcementPara6" style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <TextareaAutosize label="Announcement Paragraph7" name="announcementPara7" style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <TextareaAutosize label="Announcement Paragraph8" name="announcementPara8" style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <TextareaAutosize label="Announcement Paragraph9" name="announcementPara9" style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <TextareaAutosize label="Announcement Paragraph10" name="announcementPara10" style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={this.handleChange} />
                        </Grid>
                        <Grid item xs={3} style={{ marginTop: "0.5rem" }}>
                            <MySelect name="priority" label="Priority" options={priorities} onChange={this.handleChange} />
                        </Grid>

                            <Grid item xs={3} style={{ marginTop: "1.5rem" }}>
                                <Typography>Expiration</Typography>
                                <DatePicker showTimeSelect selected={expiresDate} onChange={this.handleDateChange}
                                    timeFormat="HH:mm" timeIntervals={15} timeCaption="time" dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            </Grid>

                        <Grid item xs={3} style={{ marginTop: "1.5rem" }}>
                            <Button variant="contained" color="primary" style={{ justifySelf: "left" }} onClick={this.handleSubmit}>Submit</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        )
    }
}
