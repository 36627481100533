import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

const SearchArray = ({ getStuffToShow, list }) => {

   const handleChange = (e) => {
      getStuffToShow(e.target.value)
   }

  return (
     <Grid item xs={12}>
         <TextField variant='outlined' onChange={handleChange} />
         {/* <Grid item xs={12} style={{marginLeft: '1rem', marginBottom: '1rem', height: '250px', overflow: 'scroll', border: '2px solid darkgray', borderRadius: '0.5rem', padding: '1rem' }}>
            {list}
         </Grid> */}
     </Grid>
  )
}

SearchArray.propTypes = {
   getStuffToShow: PropTypes.func,
   list: PropTypes.array
}

export default SearchArray