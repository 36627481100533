/* This is the top level Page that has three Pages below it
 * 
 * <ManagePhotos />
 *      <ManageBioPhotos />
 * 
 *          <GetBioList /> was replaced by <GetBioListPartial />
 *          <MySelect />
 * 
 *          <AddBioPhoto />
 *          <ShowBioPhotoToManage />
 * 
 *      <ManageClassPatches />
 *          <MySelect />
 *          <AddClassPatch />
 *          <Snackbar />
 *          <ShowClassPatchesToManage />
 * 
 *      <ManageClassPhotos />
 *          <MySelect />
 *          <AddClassPhoto />
 *          <Snackbar />
 *          <ShowClassPhotosToManage />
 * 
 *      <ManageHistoryPhotos />
*/
import React, { useEffect, useState } from 'react'
import AddClassPatch from '../Components/AddClassPatch'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import ClassDropdown from '../Components/ClassDropdown'
import Container from '@material-ui/core/Container'
import { getTPSClasses, node_server } from '../Helpers'
import Grid from '@material-ui/core/Grid'
import ShowClassPatchesToManage from '../Components/ShowClassPatchesToManage'
import Snackbar from '../Atoms/Snackbar'
import Typography from '@material-ui/core/Typography'

const ManageClassPatches = props => {

    /* Item 1. Get list of TPS Classes from MySQL DB */
    const [ tpsClasses, setTpsClasses ] = useState([])

    /* Item 2. TPS class selected via <MySelect /> */
    const [ tpsClass, setTpsClass ] = useState('')

    /* Item 3. Get classPatch from MongoDB */
    const [ classPatch, setClassPatch ] = useState({})

    /* If a classPatch exists, display patch */
    const [ showClassPatch, setShowClassPatch ] = useState(false)

    const [ snackbarOpen, setSnackbarOpen ] = useState(false)

    /* 
     * Item 1.
     * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
     */
    useEffect( () => {
        getTPSClasses()
            .then( classes => setTpsClasses(classes) )
            .catch( msg => console.log('There was an error on get', msg) )
    }, [] )

    /* 
     * Item 2.
     * Sent down to <ClassDropdown /> which sends back a String containing the selected TPS Class
     */
    const getSelectedClass = (e) => {
        setTpsClass(e)
    }

    /* 
     * Item 3.
     * Take TPS Class from <MySelect /> and check the MongoDB to see if an entry exists or not
     */
    useEffect( () => {
        if( tpsClass !== '' ){
            axios.get(node_server + `classPatch/GetClassPatch/${tpsClass}`)
                .then( res => res.data )
                .then( data => {
                    if( data ){
                        setClassPatch( data )
                        setShowClassPatch(true)
                    }
                    else{
                        setShowClassPatch(false)
                        setSnackbarOpen(true)
                        setTimeout( () => {
                            setSnackbarOpen(false)
                        }, 2500 )
                    }
                } )
        }
    }, [tpsClass] )

    /*
     * Item 4.
     * Takes updated classPhoto from <AddClassPhoto /> and puts it in local state
     */
    const addClassPatchToLocalState = (classWithPatchAdded) => {
        setClassPatch(classWithPatchAdded)
        setShowClassPatch(true)
    }

    return (
        <Box style={{marginTop: '6rem'}} >
            <Typography variant='h3' >
                Manage Class Patches
            </Typography>

            <Container>
                <ClassDropdown tpsClasses={tpsClasses} getSelectedClass={getSelectedClass} />
            </Container>

            <AddClassPatch addClassPatchToLocalState={addClassPatchToLocalState} classPatch={classPatch} tpsClass={tpsClass} />
            {snackbarOpen ?
            <Grid container item style={{marginTop: '2rem', marginLeft: '30%', width: '40%'}} >
                <Snackbar type='error' msg={'That class does not yet have a class patch in the Database'} />
            </Grid>
            : null}
            {showClassPatch ?
                <ShowClassPatchesToManage classPatch={classPatch} />
            : null}
        </Box>
    )
}

export default ManageClassPatches
