/*
 * Component called by <Landing /> or <AnnouncementLandiing />
 */
import React from 'react'
import { announcementIdAction } from "../Redux/Actions"
import axios from "axios"
import Box from "@material-ui/core/Box"
import { delete_announcement } from '../Helpers'
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import Grid from "@material-ui/core/Grid"
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types'
import { store } from "../Redux/Store"
import Typography from "@material-ui/core/Typography"

const Announcement = ({ announcementPara1, announcementSynopsis, i, id, showOneAnnouncement, editOneAnnouncement, updateAfterDeleteAnnouncement, title }) => {

    const { authorizedToCrudAnnouncements } = store.getState()

    const handleShow = () => {
        store.dispatch(announcementIdAction(id))
        showOneAnnouncement(id)
    }

    const handleEdit = () => {
        store.dispatch(announcementIdAction(id))
        editOneAnnouncement(id)
    }

    const handleDelete = () => {
        let deleteAnnouncementData = new FormData()

        deleteAnnouncementData.append('id', id)

        axios.post(delete_announcement, deleteAnnouncementData)
            .then(res => res.data)
            .then(data => {
                return 1
            })

        updateAfterDeleteAnnouncement(id)
    }

    return (
        <Box key={id} >
            {i === 0 ? null :
                <Box style={{ width: "100%", paddingTop: "1rem" }}>
                    <Divider light />
                </Box>
            }
            <Grid container direction="row">
                <Grid item xs={9} >
                    <Typography style={{ textAlign: "left", marginTop: "1rem" }} >
                        <strong>{title}</strong>
                    </Typography>
                </Grid>
                <Grid item xs={3} >
                    {announcementPara1 === null || announcementPara1 === "" ?
                        null :
                        <IconButton onClick={handleShow}>
                            <MoreVertIcon />
                        </IconButton>
                    }
                    {authorizedToCrudAnnouncements ?
                        <IconButton onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>
                        : null}
                    {authorizedToCrudAnnouncements ?
                        <IconButton value={id} onClick={handleDelete} >
                            <DeleteIcon />
                        </IconButton>
                        : null}
                </Grid>
            </Grid>

            <Typography style={{ textAlign: "left" }} >
                {announcementSynopsis}
            </Typography>
        </Box>
    )
}

Announcement.propTypes = {
    announcementPara1: PropTypes.string,
    announcementSynopsis: PropTypes.string,
    i: PropTypes.number,
    id: PropTypes.number,
    showOneAnnouncement: PropTypes.func,
    editOneAnnouncement: PropTypes.func,
    updateAfterDeleteAnnouncement: PropTypes.func, 
    title: PropTypes.string
}

export default Announcement
