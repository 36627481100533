import React, { useEffect, useState } from 'react'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { node_server } from '../Helpers'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const ReorderCollectionPhotos = ({collectionId, photos, updatePhotosAfterReorder}) => {

    const numberOfCharsToDisplay = 50

    const [ newPhotoArrayToDB, setNewPhotoArrayToDB ] = useState([])

    const movePhotoDown = (i) => {
        let tempPhotos = photos
        const photo = tempPhotos[i + 1]
        tempPhotos[i + 1] = tempPhotos[i]
        tempPhotos[i] = photo
        setNewPhotoArrayToDB(tempPhotos)
    }

    const movePhotoUp = (i) => {
        let tempPhotos = photos
        const photo = tempPhotos[i - 1]
        tempPhotos[i - 1] = tempPhotos[i]
        tempPhotos[i] = photo
        setNewPhotoArrayToDB(tempPhotos)
    }

    useEffect(() => {
        if (newPhotoArrayToDB.length > 0) {
            axios.put(node_server + `photoCollection/UpdatePhotoArray/${collectionId}`, {
                newPhotoArrayToDB
            })
                .then( res => {
                    updatePhotosAfterReorder(res.data.updatedDataFromDB.photos)
                })
        }
    }, [collectionId, newPhotoArrayToDB, updatePhotosAfterReorder])

    const photosToShow = photos.map((photo, i) => {

        return (
            <Grid container item justify='flex-start' xs={12} key={photo._id} style={{marginTop: '1rem'}}>
                <img
                    alt='collection'
                    src={photo.photoFilename}
                    // For Local Development
                    // src='https://images.unsplash.com/photo-1616784155315-666dd4936a8f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1900&q=80'
                    style={{width: '200px'}}
                />
                <Typography variant='h6'>
                    {photo.photoDescription.slice(0,numberOfCharsToDisplay)}
                </Typography>
                {i < photos.length - 1 ?
                    <IconButton onClick={() => movePhotoDown(i)}>
                        <ArrowDownwardIcon />
                    </IconButton>
                    :
                    <IconButton disabled>
                        <ArrowDownwardIcon />
                    </IconButton>
                }
                {i > 0 ?
                    <IconButton onClick={() => movePhotoUp(i)}>
                        <ArrowUpwardIcon />
                    </IconButton>
                    :
                    <IconButton disabled>
                        <ArrowUpwardIcon />
                    </IconButton>
                }
            </Grid>
        )
    })

    return (
        <Grid container style={{marginLeft: '4rem'}}>
            {photosToShow}
        </Grid>
    )
}

ReorderCollectionPhotos.propTypes = {
    collectionId: PropTypes.string,
    photos: PropTypes.array,
    updatePhotosAfterReorder: PropTypes.func
}

export default ReorderCollectionPhotos
