import React, { useState } from 'react'
import axios from "axios"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { reset_password } from '../Helpers'
import { resetPasswordAction } from "../Redux/Actions"
import Snackbar from "../Atoms/Snackbar"
import { store } from "../Redux/Store"
import TextField from "@material-ui/core/TextField"
import { Typography } from '@material-ui/core'

const SuperUserResetPassword = () => {

    const [ password, setPassword ] = useState('')
    const [ password1, setPassword1 ] = useState('')
    const [ snackBarMsg, setSnackBarMsg ] = useState('')
    const [ snackBarOpen, setSnackBarOpen ] = useState(false)
    const [ snackBarType, setSnackBarType ] = useState('')
    const [ username, setUsername ] = useState('')

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }

    const handlePassword1Change = (e) => {
        setPassword1(e.target.value)
    }

    const handleUsernameChange = (e) => {
        setUsername(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        var loginFormData = new FormData()

        loginFormData.append("password", password)
        loginFormData.append("username", username)

        if (password === password1) {
            axios.post(reset_password, loginFormData)
                .then(res => res.data)
                .then(data => {

                    if (data.goodResetPassword) {
                        setSnackBarMsg(data.msg)
                        setSnackBarType('success')
                        setSnackBarOpen(true)
                        setPassword('')
                        setPassword1('')
                        setTimeout(() => {
                            setSnackBarOpen(false)
                            store.dispatch(resetPasswordAction(false))
                        }, 2500)

                    } else {
                        setSnackBarMsg('Password was not Reset')
                        setSnackBarType('error')
                        setSnackBarOpen(true)
                        setPassword('')
                        setPassword1('')
                        setTimeout(() => {
                            setSnackBarOpen(false)
                            setPassword('')
                            setPassword1('')
                        }, 2500)
                    }
                })
        } else {
            setSnackBarMsg('Passwords do not match')
            setSnackBarType('error')
            setSnackBarOpen(true)
            setPassword('')
            setPassword1('')
            setTimeout(() => {
                setSnackBarOpen(false)
            }, 2500)
        }
    }

    return (
        <Box style={{marginTop: '4rem'}}>
            <form>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography variant='h4' data-testid='SURP-title'>
                            This Page is for SuperUsers Only!
                        </Typography>
                    </Grid>
                    <Grid item md={12} >
                        <Grid container justify="center" alignContent="center" direction="row" spacing={2} style={{ marginTop: "5rem" }} >
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Username" name="username" value={username} onChange={handleUsernameChange} data-testid='SURP-username' />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Password" name="password" type="password" value={password} onChange={handlePasswordChange} data-testid='SURP-password' />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField className="AddGrad-text-field" label="Re-enter Password" name="password1" type="password" value={password1} onChange={handlePassword1Change} data-testid='SURP-password1' />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <Button variant="contained" color="primary" onClick={handleSubmit} data-testid='SURP-button' >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {snackBarOpen ?
                <Grid container justify="center" style={{ marginTop: "2rem" }}>
                    <Grid item sm={6}>
                        <Snackbar msg={snackBarMsg} type={snackBarType} data-testid='SURP-snackbar' />
                    </Grid>
                </Grid>
                : null
            }
        </Box>
    )
}
export default SuperUserResetPassword