import React, { useEffect, useState }           from 'react'
import { allGradsAction }                       from "../Redux/Actions"
import axios                                    from 'axios'
import Box                                      from "@material-ui/core/Box"
import DisplayGrads                             from "./DisplayGrads"
import { CLIENT_PUBLIC_TOKEN, node_server }     from '../Helpers'
import { store }                                from "../Redux/Store"

const GetGradsByUserId = props => {

    const [showDownloadEmailButton, setShowDownloadEmailButton] = useState(false)

    useEffect(() => {
        const { allGrads } = store.getState()

        // Take array [{userId: 10}, {userId: 2368}] and convert to array [10, 2368]
        const allGradsArray = allGrads.map(grad => grad.userId)

        axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
        axios.get(node_server + `search/${allGradsArray}`)
            .then(res => res.data)
            .then(data => {
               axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
               store.dispatch(allGradsAction(data.grad))

                /* This only shows the Download E-mail Button if at least one alumni has a valid e-mail listed, otherwise if
                 * the selected list has no valid e-mails for displayed grads the button is not visible */
                data.grad.map(grad => {
                  if (grad.personalEmail !== '' || grad.workEmail !== '') {
                     setShowDownloadEmailButton(true)
                  }
                    return 1
                })
            })
    }, [])

        const { allGrads } = store.getState()


        return (
            <Box style={{ marginTop: "6rem" }}>
                <DisplayGrads allGrads={allGrads} showDownloadEmailButton={showDownloadEmailButton} />
            </Box>
        )
}

export default GetGradsByUserId