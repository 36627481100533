import React from 'react'
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteWarning from './DeleteWarning'
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popup from "reactjs-popup";
import PropTypes from 'prop-types'
import ShowOneGrad from "../Pages/ShowOneGrad"
import { store } from "../Redux/Store"
import { userIdAction } from "../Redux/Actions"

const GradCard = props => {

    const { signedInUserId, userType } = store.getState()
    const { givenFirstName, lastName, militaryRank, personalEmail, track, tpsClass, userId, workEmail } = props

    var email = ''

    if (personalEmail !== '') {
        email = `mailto:${personalEmail}`
    }
    else if (workEmail !== '') {
        email = `mailto:${workEmail}`
    }

    const handleShowOneGradClick = () => {
        store.dispatch(userIdAction(userId))
    }

    return (
        <Card style={{ marginTop: "2rem", width: "350px" }}>
            <CardHeader title={givenFirstName + " " + lastName} subheader={militaryRank} />
            <Divider light />
            <CardContent>
                <Box display="flex">
                    <Box style={{ width: "45%" }}>
                        <p>{track}</p>
                    </Box>
                    <Box style={{ width: "10%" }}>
                        <Divider orientation="vertical" light />
                    </Box>
                    <Box style={{ width: "45%" }}>
                        <p>{tpsClass}</p>
                    </Box>
                </Box>
                <CardActions>
                    <Grid container direction="row">
                        <Grid item xs={4}>
                        <IconButton onClick={handleShowOneGradClick}>
                            <Popup trigger={ <MoreVertIcon /> }
                                closeOnDocumentClick
                                modal={true}
                                closeOnEscape={true}
                            >
                                <Grid item>
                                    <ShowOneGrad />
                                </Grid>
                            </Popup>
                            </IconButton>
                            {personalEmail !== "" || workEmail !== "" ?
                                <IconButton>
                                    <a href={email} target="_blank" rel="noreferrer noopener" style={{ color: "inherit" }}>
                                        <MailOutlineIcon />
                                    </a>
                                </IconButton> : null
                            }
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={2}>
                            {userType === "superUser" || signedInUserId === userId ?
                                    <Popup trigger={<DeleteIcon />}
                                        closeOnDocumentClick
                                        modal={true}
                                        closeOnEscape={true}
                                    >
                                        { close => ( <DeleteWarning close={close} userId={userId} /> ) }
                                    </Popup>
                                 : null
                            }
                        </Grid>
                    </Grid>
                </CardActions>
            </CardContent>
        </Card>
    )
}

GradCard.propTypes = {
    firstName: PropTypes.string
}

export default GradCard
