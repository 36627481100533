/*
 * This is a 2nd level component for Showing the Military Units for one grad via Nodejs / MongoDB
 * 
 * <EditOneBioPage />
 *      <ShowOfficialTitleForOneGrad />
 *      <EditOfficialTitleForOneGrad />
 *      <ShowBioEntriesForOneGrad />
 *      <EditBioEntriesForOneGrad />
 *      <ShowMilitaryUnitsForOneGrad />
 *      <EditMilitaryUnitForOneGrad />
 *      <AddRemoveButtons />
 *      <AddBioEntry />
 *      <AddRemoveButtons />
 *      <AddMilitaryUnitToBio />
 */

import React from 'react'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Grid from "@material-ui/core/Grid"
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import { store } from '../Redux/Store'
import Typography from "@material-ui/core/Typography"

const ShowBioEntriesForOneGrad = props => {

    const { handleEditMilitaryUnitForOneGrad, militaryUnit, moveMilitaryUnitDown, moveMilitaryUnitUp, showEditButtons } = props
    const { signedInUserId, superUser, userIdOfBioToEdit } = store.getState()

    const handleEdit = (bioMilitaryUnitIdToEdit) => {
        handleEditMilitaryUnitForOneGrad(bioMilitaryUnitIdToEdit)
    }

    const handleMoveUp = (index) => {
        moveMilitaryUnitUp(index)
    }

    const handleMoveDown = (index) => {
        moveMilitaryUnitDown(index)
    }

    var militaryUnitsToDisplay = null

    if(militaryUnit !== undefined){
        militaryUnitsToDisplay = militaryUnit.map( (unit, index, array) => {
            return(<Grid key={unit._id}>
                <Grid container>
                    <Grid container item xs={10} alignItems='center'>
                        <Typography variant='h6' style={{ textAlign: 'left' }}>
                            {unit.militaryUnit}
                        </Typography>
                    </Grid>
                    <Grid container item xs={2} alignItems='center'>
                        {((signedInUserId === userIdOfBioToEdit) || superUser ) && showEditButtons ?
                            <IconButton onClick={() => handleEdit(unit._id)}>
                                <Typography variant='h6'>
                                    Edit
                                    </Typography>
                            </IconButton>
                            : null
                        }
                        {((signedInUserId === userIdOfBioToEdit) || superUser ) && showEditButtons && index > 0 ?
                            <IconButton onClick={() => handleMoveUp(index)}>
                                <ArrowUpwardIcon />
                            </IconButton>
                            : null
                        }
                        {((signedInUserId === userIdOfBioToEdit) || superUser ) && showEditButtons && index === 0 ?
                            <IconButton disabled >
                                <ArrowUpwardIcon />
                            </IconButton>
                            : null
                        }
                        {((signedInUserId === userIdOfBioToEdit) || superUser ) && showEditButtons && (index < array.length - 1) ?
                            <IconButton onClick={() => handleMoveDown(index)}>
                                <ArrowDownwardIcon />
                            </IconButton>
                            : null
                        }
                        {((signedInUserId === userIdOfBioToEdit) || superUser ) && showEditButtons && (index === array.length - 1) ?
                            <IconButton disabled >
                                <ArrowDownwardIcon />
                            </IconButton>
                            : null
                        }
                    </Grid>
                </Grid>
            </Grid>
            )
        } )
    }

    return (
        <Grid>
            {militaryUnit.length !== 0 ?
                <Typography variant='h4' style={{ textAlign: 'left' }}>
                    Military Units
                </Typography>
            : null}
            { militaryUnitsToDisplay }
        </Grid>
    )
}

ShowBioEntriesForOneGrad.propTypes = {
    handleEditMilitaryUnitForOneGrad: PropTypes.func,
    militaryUnit: PropTypes.array,
    moveMilitaryUnitDown: PropTypes.func,
    moveMilitaryUnitUp: PropTypes.func,
    showEditButtons: PropTypes.bool
}

export default ShowBioEntriesForOneGrad
