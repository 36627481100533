/*
 * This Component is on the <PersonalAndClassStates /> Component
 * The <PersonalAndClassStats /> is on the SearchByName Component
 */

import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { store } from '../../Redux/Store'
import Typography from '@material-ui/core/Typography'

const Biography = () => {

   const { hasBio } = store.getState()

   return (
      <Container >
         <Grid container >
            <Grid item xs={12} sm={5}>
               <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'left'}} >
                  Personal Biography
               </Typography>
            </Grid>
            {hasBio ?
            <Grid item xs={1}>
               <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'left'}} >
                  Yes
               </Typography>
            </Grid>
            :
            <Grid item xs={1}>
               <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'left'}} >
                  No
               </Typography>
            </Grid>
            }
         </Grid>
      </Container>
   )
}

export default Biography
