/*
 * This gets givenFirstName, lastName, militaryRank, and tpsClass
 * from MySQL to send to MongoDB
 * Called by:
 *      <AddOneBioPage />
 *      <EditABio />
 */

 import React, { useEffect } from 'react'
 import axios from 'axios'
 import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
 import PropTypes from 'prop-types'
 
 const GetBioDataForMongoDB = ({getDataFromMongoDB, userId}) => {

    /* This pulls data from tbl_user */
    useEffect( () => {
        var getBioData = new FormData()

        getBioData.append("userId", userId)
        axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
        axios.get(node_server + `bio/GetBioDataForMongoDB/${userId}`)
        .then( res => res.data )
        .then( data => {
            /* This goes back to <EditABio /> */
            // console.log('get_bio_data_for_mongodb', data)
            getDataFromMongoDB(data)
        } )
    }, [getDataFromMongoDB, userId] )

     return (
         <div>

         </div>
     )
 }

 GetBioDataForMongoDB.propTypes = {
    getDataFromMongoDB: PropTypes.func,
    userId: PropTypes.number,
}
 
 export default GetBioDataForMongoDB
 