/*
 * Top level Page for displaying TPS Yearbook CLass pages, called by <YearbookTOC />
 * Pdf pages are indicated by layoutView: pdfView and will show on ShowOneYearbookPage / Pdf Component
 * The All Classes button will eventually route to show all Yearbook Class pages at once
 * The Classes By Decade button shows available Decades which will eventually route to show all
 *   Yearbook Class pages at once for the selected Decade
 * The Select A Class button allows for selection of a single Yearbook Class page
 */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import EditYearbookPage from '../yb/EditYearbookPage'
import Grid from '@material-ui/core/Grid'
import { node_server } from '../Helpers'
import ShowOneYearbookPage from '../yb/ShowOneYearbookPage'
import YearbookTOC from '../yb/YearbookTOC'

const YearbookDisplayPage = () => {

  const [ pageDataToEdit, setPageDataToEdit ] = useState({})
  const [ pageDataToShow, setPageDataToShow ] = useState({})
  const [ pageToEdit, setPageToEdit ] = useState(-1)
  const [ pageToShow, setPageToShow ] = useState(-1)
  const [ pages, setPages ] = useState([])
  const [ showYearbookTOCPage, setShowYearbookTOCPage ] = useState(true)
  const [ showEditYearbookPage, setShowEditYearbookPage ] = useState(false)
  const [ showOneYearbookPage, setShowOneYearbookPage ] = useState(false)

  /* Currently, this route returns _id, pageIndex, pageName and pageType only */
  useEffect(() => {
    axios.get(node_server + 'yearbook/getAllPages')
      .then( res => {
        setPages(res.data)
      })
  }, [])

  /* Get data for one Yearbook page */
  useEffect(() => {
    if (pageToShow > -1) {
      axios.get(node_server + `yearbook/getOnePage/${pages[pageToShow]._id}`)
      .then( res => {
        setPageDataToShow(res.data)
      })
      .then(() => {
        /* YearbookTOCPage has already been hidden, after data is sent to local state
           ShowOneYearbookPage is shown */
        setShowOneYearbookPage(true)
      })
    }
    
    if (pageToEdit > -1) {
      axios.get(node_server + `yearbook/getOnePage/${pages[pageToEdit]._id}`)
      .then( res => {
        setPageDataToEdit(res.data)
      })
      .then(() => {
        setShowEditYearbookPage(true)
      })
    }
  }, [pages, pageToEdit, pageToShow])

  const cancelEdit = () => {
    setShowEditYearbookPage(false)
    setShowYearbookTOCPage(true)
  }

  const getIndexOfPageToEdit = (i) => {
    setPageToShow(-1)
    setPageToEdit(i)
    setShowYearbookTOCPage(false)
  }

  const getIndexOfPageToShow = (i) => {
    setPageDataToEdit(-1)
    setShowYearbookTOCPage(false)
    /* This triggers an API call above that will eventually show ShowOneYearbookPage */
    setPageToShow(i)
  }

  /* Gets sent to <ShowOneYearbookPage /> and then to <YbPageOne /> to re-show TOC via button at bottom of page */
  const returnToTOC = () => {
    setPageToShow(-1)
    setShowOneYearbookPage(false)
    setShowYearbookTOCPage(true)
  }

  return (
    <Box>
      <Container style={{marginTop: '6rem'}}>

        {showYearbookTOCPage ?
          <Grid>
            <YearbookTOC
              getIndexOfPageToEdit={getIndexOfPageToEdit}
              getIndexOfPageToShow={getIndexOfPageToShow}
              pageData={pages}
            />
          </Grid>
          : null
        }

        {showOneYearbookPage ?
          <Grid>
            <ShowOneYearbookPage pageData={pageDataToShow} returnToTOC={returnToTOC} />
          </Grid>
          : null
        }

        {showEditYearbookPage ?
          <EditYearbookPage cancelEdit={cancelEdit} pageData={pageDataToEdit} topMargin={8} />
          : null
        }

      </Container>
    </Box>
  )
}

export default YearbookDisplayPage
