/*
 * Simple Component that goes to ClassPages. It relies on tpsClass
 * being properly loaded in the Store.
 */
import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { Link } from "react-router-dom"

const ViewYourClassPage = props => {
  return (
   <Grid style={{marginTop: '1rem'}}>
      <Link to="ClassPages" >
         <Button color='primary' variant='contained'>
            View Your Class Page
         </Button>
      </Link>
   </Grid>
  )
}

export default ViewYourClassPage