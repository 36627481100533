/*
 * This Components gets ALL Registerd users from the PHP server and tbl_get_all_grads
 * order by last name
 * 
 * Called by:
 *    <EditAProfile /> Page
 *    <SuperUserAddABio /> Page
 *    SuperUserAddAStory /> Page
 */
import React, { useEffect } from 'react'
import axios from 'axios'
import { pathName } from '../Helpers'
import PropTypes from 'prop-types'

const AllGrads = ({getAllGrads}) => {

  useEffect(() => {
    axios.get(`${pathName}getallgrads.php`)
        .then(res => res.data)
        .then(data => {
            getAllGrads(data.allGrads)
        })
}, [getAllGrads])

  return (
    <div>
      
    </div>
  )
}

AllGrads.propTypes = {
  getAllGrads: PropTypes.func
}

export default AllGrads
