/**
 * This compoenent is called by
 * <AddPhoto />
 * <EditPoto />
 */

import React, { useEffect, useState }                                   from 'react'
import { Grid, TextField, TextareaAutosize, Typography }                from '@material-ui/core'
import PropTypes                                                        from 'prop-types'

const AddEditPhotoFieldsComponent = props => {

   const { eCaption, eFullFilename, ePhotoTags, title, eSmallFilename, eThumbnailFilename, eTpsClass, handleReturn } = props

   const [ caption,              setCaption              ] = useState(eCaption)
   const [ fullFilename,         setFullFilename         ] = useState(eFullFilename)
   const [ photoTags,            setPhotoTags            ] = useState(ePhotoTags)
   const [ smallFilename,        setSmallFilename        ] = useState(eSmallFilename)
   const [ thumbnailFilename,    setThumbnailFilename    ] = useState(eThumbnailFilename)
   const [ tpsClass,             setTpsClass             ] = useState(eTpsClass)

   const changeCaption = (e) => {
      setCaption(e.target.value)
   }

   const changeFullFilename = (e) => {
      setFullFilename(e.target.value)
   }

   const changePhotoTags = (e) => {
      setPhotoTags(e.target.value)
   }

   const changeSmallFilename = (e) => {
      setSmallFilename(e.target.value)
   }

   const changeThumbnailFilename = (e) => {
      setThumbnailFilename(e.target.value)
   }

   const changeTpsClass = (e) => {
      setTpsClass(e.target.value)
   }

   useEffect( () => {
      handleReturn(
         caption,
         fullFilename,
         photoTags,
         smallFilename,
         thumbnailFilename,
         tpsClass
      )
   },)

   return(
      <Grid>
         <Grid>
            <Typography variant='h4' style={{marginTop: '6rem'}} >
               {title}
            </Typography>
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextField label="Full Filename" onChange={changeFullFilename} variant='outlined' style={{ width: '90%' }} value={fullFilename} />
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextField label="Small Filename" onChange={changeSmallFilename} variant='outlined' style={{ width: '90%' }} value={smallFilename} />
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextField label="Thumbnail Filename" onChange={changeThumbnailFilename} variant='outlined' style={{ width: '90%' }} value={thumbnailFilename} />
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextField label="Photo Tag(s), comma delimited, no spaces" onChange={changePhotoTags} variant='outlined' style={{ width: '90%' }} value={photoTags} />
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextField label="TPS Class" onChange={changeTpsClass} variant='outlined' style={{ width: '90%' }} value={tpsClass} />
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextareaAutosize name='Caption' placeholder={'Caption'} minRows={5} style={{ width: '90%', fontSize: '1.5rem', padding: '0.5rem' }} onChange={changeCaption} value={caption} />
         </Grid>
      </Grid>
   )
}

AddEditPhotoFieldsComponent.propTypes = {
   eCaption: PropTypes.string,
   eFullFilename: PropTypes.string,
   ePhotoTags: PropTypes.string,
   title: PropTypes.string,
   eSmallFilename: PropTypes.string,
   eThumbnailFilename: PropTypes.string,
   eTpsClass: PropTypes.string,
   handleReturn: PropTypes.func
}

export default AddEditPhotoFieldsComponent