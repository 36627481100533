/*
 * This is the Page where a new user is directed after their initial login
 * so they can change their password
 */
import React, { useState } from 'react'
import axios from "axios"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { Redirect } from "react-router-dom"
import { CLIENT_PUBLIC_TOKEN, reset_password, node_server } from '../Helpers'
import { resetPasswordAction } from "../Redux/Actions"
import Snackbar from "../Atoms/Snackbar"
import { store } from "../Redux/Store"
import TextField from "@material-ui/core/TextField"

const ResetPassword = () => {

   const [ password, setPassword ] = useState('')
   const [ password1, setPassword1 ] = useState('')
   const [ snackBarMsg, setSnackBarMsg ] = useState('')
   const [ snackBarOpen, setSnackBarOpen ] = useState(false)
   const [ snackBarType, setSnackBarType ] = useState('')

   const { username } = store.getState()

   const handlePasswordChange = (e) => {
      setPassword(e.target.value)
   }

   const handlePassword1Change = (e) => {
      setPassword1(e.target.value)
   }

   const handleSubmit = (e) => {
      e.preventDefault()

      var loginFormData = new FormData()

      loginFormData.append("password", password)
      loginFormData.append("username", username)

      if (password === password1) {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
         axios.post(node_server + 'resetPassword', {
            password,
            password1,
            username
         })
               .then(res => res.data)
               .then(data => {

                  if (data.goodResetPassword) {
                     setSnackBarMsg(data.msg)
                     setSnackBarType('success')
                     setSnackBarOpen(true)
                     setPassword('')
                     setPassword1('')
                     setTimeout(() => {
                           setSnackBarOpen(false)
                           store.dispatch(resetPasswordAction(false))
                     }, 2500)

                  } else {
                     setSnackBarMsg('Password was not Reset')
                     setSnackBarType('error')
                     setSnackBarOpen(true)
                     setPassword('')
                     setPassword1('')
                     setTimeout(() => {
                           setSnackBarOpen(false)
                     }, 2500)
                  }
               })
      } else {
         setSnackBarMsg('Passwords do not match')
         setSnackBarType('error')
         setSnackBarOpen(true)
         setPassword('')
         setPassword1('')
         setTimeout(() => {
               setSnackBarOpen(false)
         }, 2500)
      }
   }

   const { goodLogin, resetPassword } = store.getState()

   return (
      <Box style={{marginTop: '6rem'}}>
            {goodLogin && !resetPassword ? <Redirect to="/Login" /> : null}
            <form>
               <Grid container >
                  <Grid item md={12} >
                        <Grid container justify="center" alignContent="center" direction="row" spacing={2} style={{ marginTop: "5rem" }} >
                           <Grid item xs={12} md={3}>
                              <TextField className="AddGrad-text-field" label="Username" name="username" value={username} />
                           </Grid>
                           <Grid item xs={12} md={3}>
                              <TextField className="AddGrad-text-field" label="Password" name="password" type="password" autoFocus={true} value={password} onChange={handlePasswordChange} />
                           </Grid>
                           <Grid item xs={12} md={3}>
                              <TextField className="AddGrad-text-field" label="Re-enter Password" name="password1" type="password" value={password1} onChange={handlePassword1Change} />
                           </Grid>
                           <Grid item xs={12} md={1}>
                              {(password.length > 0) && (password1.length > 0) && (password === password1) ?
                                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                                       Submit
                                    </Button>
                              :
                                    <Button variant="contained" color="primary" disabled onClick={handleSubmit}>
                                       Submit
                                    </Button>
                              }
                           </Grid>
                        </Grid>
                  </Grid>
               </Grid>
            </form>
            {snackBarOpen ?
               <Grid container justify="center" style={{ marginTop: "2rem" }}>
                  <Grid item sm={6}>
                        <Snackbar msg={snackBarMsg} type={snackBarType} />
                  </Grid>
               </Grid>
               : null
            }
      </Box>
   )
}
export default ResetPassword