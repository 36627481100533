import React from 'react'
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

const History = () => {
    return (
        <Box>
            <Grid
                container spacing={1}
                direction="row"
                justify="center"
                style={{
                    margin: "2rem",
                    marginTop: '5rem'
                }}>
                <Grid item md={10}>
                    <a href='../../History/Commandants.html' target="_blank" >Commandants</a>
                    <Paper>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            Notable Dates:
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            17 December 1903 - First flight of a heavier-than-air powered aircraft by Orville and
                            Wilbur Wright near Kitty Hawk, NC.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            1 August 1907 - The Aeronautical Division, Signal Corps was formed, and was the first
                            heavier-than-air military aviation organization in historhy.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            18 July 1914 - The Aviation Section, Signal Corps was formed.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            1 April 1918 - The Royal Air Force (RAF) was founded in the United Kingdom.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            24 May 1918 - The United States Army Air Service (USAAS) was founded.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            2 July 1926 - The United States Army Air Corps (USAAC) was founded.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            20 June 1941 - The United States Army Air Forces (USAAF or AAF) was founded.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            1943 - The Empire Test Pilot School (ETPS) of the Royal Air Force (RAF) was founded in the United Kingdom.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            9 September 1944 - The Flight Test Training Unit of the Air Technical Command was founded at Wright-Patterson AFB in Dayton, OH.
                            The first class flew the T-6, and was the only class held before the school was re-named.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            1945 - The school was re-designated as The Flight Performance School.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            1945 - The school moved to Vandalia Municipal Airport (now the Dayton International Airport)
                            and was re-designated The Flight Performance School under the command of Lt Col John R. Muehlberg
                            who was the first to carry the title "Commandant."
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            1946 - The school was moved to Patterson Field in Dayton, OH.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            18 September 1947 - The United States Air Force (USAF) was founded.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            x
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            x
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            x
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            1 July 1972 - The school was re-designated The U.S. Air Force Test Pilot School which is
                            the designation it still holds today.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid
                container spacing={1}
                direction="row"
                justify="flex-start"
                style={{
                    margin: "2rem",
                    width: "100%"
                }}>
                <Grid item md={10}>
                    <Paper>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            USAF Test Pilot School (USAFTPS) was founded 9 September 1944 as the Flight Test Training Unit
                            at Wright-Patterson Air Force Base (AFB) in Dayton, OH. It was reportedly inspired by the Royal
                            Air Force's (RAF) Empire Test Pilot School (ETPS) which was founded in 1943.
                        </Typography>
                        <Typography align="left"
                            style={{
                                lineHeight: "1.5rem",
                                letterSpacing: "0.075rem",
                                padding: "0.5rem"
                            }} >
                            The school was moved to its current location at Edwards AFB, CA on 4 February 1951.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default History
