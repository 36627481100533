/*
 * This is the Top level Page for Adding a NEW Photo Collection and
 * for Added NEW Photos to an existing Collection
 */
import React, { useEffect, useState } from 'react'
import AddPhotoCollection from '../Components/AddPhotoCollection'
import AddPhotoToPhotoCollection from '../Components/AddPhotoToPhotoCollection'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { node_server } from '../Helpers'
import ReorderCollectionPhotos from '../Components/ReorderCollectionPhotos'
// import ShowPhotoCollectionToManage from '../Components/ShowPhotoCollectionToManage'
import ShowPhotosFromPhotoCollectionBasicRows from '../Components/ShowPhotosFromPhotoCollectionBasicRows'
import Typography from '@material-ui/core/Typography'
import ViewPhotoCollection from '../Components/ViewPhotoCollection'

const ManagePhotoCollectionPhotos = () => {

    const [ aCollectionWasAdded, setACollectionWasAdded ] = useState(true)
    const [ collectionId, setCollectionId ] = useState('')
    const [ collectionNames, setCollectionNames ] = useState([])
    const [ photos, setPhotos ] = useState([])
    const [ selectedCollection, setSelectedCollection ] = useState('')
    const [ showAddPHotoToPhotoCollection, setShowAddPHotoToPhotoCollection ] = useState(false)
    const [ showCollection, setShowCollection ] = useState(false)
    const [ showReorderCollection, setShowReorderCollection ] = useState(false)

    useEffect(() => {
        if (aCollectionWasAdded) {
            axios.get(node_server + 'photoCollection/GetAllCollections')
            .then(res => {

            /* Alphabetize by collectonName */
            const tempNames = res.data.collectionNames
            let aSwapWasMade = true
            let tempCollectionName = {}
            while (aSwapWasMade) {
                aSwapWasMade = false
                for (let i = 0; i < tempNames.length - 1; i++) {
                    if (tempNames[i].photoCollectionName > tempNames[i + 1].photoCollectionName) {
                        aSwapWasMade = true
                        tempCollectionName = tempNames[i + 1]
                        tempNames[i + 1] = tempNames[i]
                        tempNames[i] = tempCollectionName
                    }
                }
            }

            setCollectionNames(tempNames)
            })
            setACollectionWasAdded(false)
        }
    }, [aCollectionWasAdded])

    useEffect(() => {
        if (selectedCollection !== '') {
            axios.get(node_server + `photoCollection/GetACollection/${selectedCollection}`)
            .then(res => {
                setCollectionId(res.data.collectionFromDB._id)
                setPhotos(res.data.collectionFromDB.photos)
            })
        }
    }, [selectedCollection])

    /* Hides the Collection and the Reorder Component from view when a Photo is being Added and
       unhides the Collection if the button is pressed again */
    useEffect(() => {
        if (showAddPHotoToPhotoCollection) {
            setShowCollection(false)
            setShowReorderCollection(false)
        } else {
            setShowCollection(true)
        }
    }, [showAddPHotoToPhotoCollection])

    /* Hides the Collection and the AddPhoto Component from view when a Collection is being Reorderd and
       unhides the Collection if the button is pressed again */
    useEffect(() => {
        if (showReorderCollection) {
            setShowAddPHotoToPhotoCollection(false)
            setShowCollection(false)
        } else {
            setShowCollection(true)
        }
    }, [showReorderCollection])

    const CheckToSeeIfACollectionWasAdded = () => {
        setACollectionWasAdded(true)
    }

    const addNewPhoto = () => {
        if (selectedCollection !== '') {
            setShowAddPHotoToPhotoCollection(!showAddPHotoToPhotoCollection)
        }
    }

    const closeShowAddPHotoToPhotoCollection = () => {
        setShowAddPHotoToPhotoCollection(false)
    }

    const getSelectedCollection = (e) => {
        setSelectedCollection(e)
        setShowCollection(true)
    }

    const reorderPhotos = () => {
        if (selectedCollection !== '') {
            setShowAddPHotoToPhotoCollection(false)
            setShowCollection(false)
            setShowReorderCollection(!showReorderCollection)
        }
    }

    const updatePhotosAfterReorder = (newPhotoArray) => {
        setPhotos(newPhotoArray)
    }

    return (
        <Box style={{marginTop: '6rem'}}>
            <Typography variant='h3' >
                Manage Photo Collections Photos
            </Typography>
            <Grid container justify='center' style={{marginTop: '2rem'}} >
                <Grid item xs={12} sm={6}>
                    <AddPhotoCollection CheckToSeeIfACollectionWasAdded={CheckToSeeIfACollectionWasAdded} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid>
                        <ViewPhotoCollection collectionNames={collectionNames} getSelectedCollection={getSelectedCollection} />
                    </Grid>
                    {selectedCollection === 'Commandants (Alphabetical)' ?
                        <Grid style={{marginTop: '2rem'}}>
                            <Button disabled variant='outlined' style={{height: '50px', width: '60%'}}>
                                To Add A New Commandant, Use Commandants (Chronological) Above
                            </Button>
                        </Grid>
                        :
                        <Grid style={{marginTop: '2rem'}}>
                            <Button onClick={addNewPhoto} variant='outlined' style={{height: '50px', width: '60%'}}>
                                Add New Photo
                            </Button>
                        </Grid>
                    }
                    <Grid style={{marginTop: '2rem'}}>
                        <Button onClick={reorderPhotos} variant='outlined' style={{height: '50px', width: '60%'}}>
                            Re-Order Photos in Selected Collection
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {/* This Component is where NEW Photo information is entered */}
            {showAddPHotoToPhotoCollection ?
                <AddPhotoToPhotoCollection
                closeShowAddPHotoToPhotoCollection={closeShowAddPHotoToPhotoCollection}
                selectedCollection={selectedCollection}
                />
                :
                null
            }
            {showCollection ?
                <ShowPhotosFromPhotoCollectionBasicRows photos={photos} />
                :
                null
            }
            {showReorderCollection ?
                <ReorderCollectionPhotos collectionId={collectionId} photos={photos} updatePhotosAfterReorder={updatePhotosAfterReorder} />
                :
                null
            }
        </Box>
    )
}

export default ManagePhotoCollectionPhotos