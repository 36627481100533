/*
 * This is the second level component for Showing each TPS class, reading any available
 * journal entries, and adding journal entries if logged in as an alumni member who
 * has CRUD authority for that TPS class
 * 
 * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
 * 
 * Puts tpsClass from dropdown in useState Hook, and sends the currently selected class to the Store
 * 
 * <ClassPages />
 *      <ShowOneClassPage />
 *          <ShowGradsFromOneClass />
 *          <ShowJournalEntriesFromOneClass />
 *          <AddRemoveButtons />
 *          <AddJournalEntry />
 *          <EditJournalEntry />
 */

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { charCount } from '../Helpers'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { node_server } from '../Helpers'
import PropTypes from 'prop-types'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const EditJournalEntry = props => {

    const { incNumJournalEntriesAfterEdit, journal, journalIdToEdit, tpsClass } = props

    const [title, setTitle] = useState('')
    const [journalEntry, setJournalEntry] = useState('')
    const [indexOfMatch, setIndexOfMatch] = useState(-1)

    // These count the number of characters in the title and the journal entry
    const [titleCount, setTitleCount] = useState(0)
    const [journalCount, setJournalCount] = useState(0)

    useEffect( () => {
        // console.log('EditJournalEntry journalIdToEdit is ', journalIdToEdit)
        // console.log('EditJournalEntry journal is ', journal)
        const journalDataToEdit = journal.journalEntry.filter( (journalEntry, index) => {
            if(journalEntry._id === journalIdToEdit){
                setIndexOfMatch(index)
                return journalEntry
            } else {
                return null
            }
        } )

        // console.log('EditJournalEntry journalDataToEdit from .filter() is ', journalDataToEdit)
    
        setTitle(journalDataToEdit[0].title)
        setJournalEntry(journalDataToEdit[0].text)
        setTitleCount(journalDataToEdit[0].title.length)
        setJournalCount(journalDataToEdit[0].text.length)
    }, [journalIdToEdit, journal.journalEntry] )

    // const { signedInUserId } = store.getState()

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
        setTitleCount(e.target.value.length)
    }

    const handleJournalEntryChange = (e) => {
        if (e.target.value.length <= charCount) {
            setJournalEntry(e.target.value)
        }
        setJournalCount(e.target.value.length)
    }

    const handleSubmit = async () => {
        
        journal.journalEntry[indexOfMatch].title = title
        journal.journalEntry[indexOfMatch].text = journalEntry

        axios.put(node_server + 'journal/class', {
            journal
        })
        .then( res => res.data )
        .then( data => {
            incNumJournalEntriesAfterEdit()
        })
        incNumJournalEntriesAfterEdit()
    }

    return (
        <Box style={{ marginTop: '3rem', marginBottom: '3rem' }} >
            <Container>
                <Grid container>

                    <Grid item xs={12}>
                        <Typography variant='h4'>
                            Edit Weekly Journal Entry for TPS Class {tpsClass}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: '3rem', marginBottom: '1rem' }} >
                        <TextField value={title} name='Title' label='Title' variant='outlined' style={{ width: '100%' }} onChange={handleTitleChange} />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                        {journalCount <= charCount ?
                            <TextareaAutosize value={journalEntry} name='journalEntry' placeholder='2,400 Characters Only!' rowsMin={5} style={{ width: '100%', fontSize: '1.5rem', padding: '0.5rem' }} onChange={handleJournalEntryChange} />
                            :
                            <TextareaAutosize name='journalEntry' placeholder='2,400 Characters Only!' rowsMin={5} style={{ width: '100%', color: 'red', fontSize: '1.5rem', padding: '0.5rem' }} onChange={handleJournalEntryChange} />
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='h6' style={{textAlign: 'left', marginBottom: '0.5rem'}}>
                            Characters Remaining: {charCount - journalCount}
                        </Typography>
                    </Grid>

                    <Grid item>
                        {titleCount > 0 && journalCount > 0 ?
                            <Button variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
                            :
                            <Button variant='contained' color='primary' disabled onClick={handleSubmit}>Submit</Button>
                        }
                    </Grid>

                </Grid>
            </Container>
        </Box>
    )
}

EditJournalEntry.propTypes = {
    incNumJournalEntriesAfterEdit: PropTypes.func,
    journal: PropTypes.object,
    journalIdToEdit: PropTypes.string,
    tpsClass: PropTypes.string
}

export default EditJournalEntry