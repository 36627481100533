import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

const PersonalAndClassStatsSearchResults = ({ cancel, searchListToDisplay, submit }) => {
  return (
   <Grid item xs={12}style={{marginBottom: '2rem'}}>
      <Grid container >
         <Grid item xs={12}>
            <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'center'}} >
               Search Results
            </Typography>
         </Grid>
      </Grid>
      <Grid container >
         <Grid item xs={12} style={{marginLeft: '1rem', marginBottom: '1rem', height: '250px', overflow: 'scroll', border: '2px solid darkgray', borderRadius: '0.5rem', padding: '1rem' }}>
            {searchListToDisplay}
         </Grid>
         <Grid item xs={6}>
            <Button color='primary' variant='contained' onClick={submit} style={{marginLeft: '0rem'}}>Save</Button>
         </Grid>
         <Grid item xs={6}>
            <Button color='primary' variant='contained' onClick={cancel} style={{marginLeft: '0rem'}}>Cancel</Button>
         </Grid>
      </Grid>
   </Grid>
  )
}

PersonalAndClassStatsSearchResults.propTypes = {
   cancel: PropTypes.func,
   searchListToDisplay: PropTypes.array,
   submit: PropTypes.func
}

export default PersonalAndClassStatsSearchResults