/* /BioList in the URL
 *
 * This is the top level component for Showing available bios, this is a public facing page
 * 
 * <GetBioListPartial /> Calls the Node server and gets a list of all appropriate bios
 *      If the user is logged in then all bios are retrieved
 *      If the user in not logged in then only public bios are retrieved
 * 
 * <BioList />
 * 
 *      <GetBioListPartial />, used to be <GetBioList />
 * 
 *      <NameFromBioList />
 *          <ShowOneBio />
 */

import React, { useCallback, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import GetBioListPartial from '../Components/GetBioListPartial'
import Grid from '@material-ui/core/Grid'
import NameFromBioList from '../Components/NameFromBioList'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import { store } from '../Redux/Store'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const BioList = props => {

   const [ bioListPartial, setBioListPartial ] = useState([])
   const [ holdBioListPartial, setHoldBioListPartial  ] = useState([])
   const [ detailedSearchText, setDetailedSearchText ] = useState('')
   const [ searchText, setSearchText ] = useState('')

   const { goodLogin } = store.getState()

   const getBioListPartial = useCallback((bioListPartialFromDB) => {
      setBioListPartial(bioListPartialFromDB)
      setHoldBioListPartial(bioListPartialFromDB)
   }, [] )

   const handleDetailedSearchFocus = (e) => {
      setSearchText('')
   }

   const handleDetailedSearch = (e) => {
      setDetailedSearchText(e.target.value.toLowerCase())
   }

   const handleKeywordSearchFocus = (e) => {
      setBioListPartial(holdBioListPartial)
      setDetailedSearchText('')
   }

   const handleKeywordSearch = (e) => {
      setSearchText(e.target.value.toLowerCase())
   }

   const bioListToDisplay = bioListPartial.filter( s => {
      if( s.givenFirstName.toLowerCase().includes(searchText) ||
         s.lastName.toLowerCase().includes(searchText) ||
         s.militaryRank.toLowerCase().includes(searchText) ||
         s.tpsClass.toLowerCase().includes(searchText) ||
         s.officialTitle.toLowerCase().includes(searchText) ){
         return s
      }
      return null
      /* This no longer happens since the entire Bio List is no longer sent back, Searches the bioEntry text array */
      // else if( s.bioEntry.map( i => i.text.toLowerCase() ).toLocaleString().includes( searchText ) ){return s}
      // /* Searches the militaryUnit text array */
      // else if( s.militaryUnit.map( i => i.militaryUnit.toLowerCase() ).toLocaleString().includes( searchText ) ){return s}
      // else{
      //     return null
      // }
   } ).map((bio, index) => (
      <NameFromBioList
         bio={bio}
         index={index}
         key={bio.userId}
         userId={bio.userId}
      />
   ));

   const submitDetailedSearch = () => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + `bio/getDetailedBioInfo/${detailedSearchText}`)
         .then( res => {
               setBioListPartial(res.data)
               /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
               after a GET was made to the Node server. */
               axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
         } )
   }

   return (
      <Box>
         <Container>

         <GetBioListPartial getBioListPartial={getBioListPartial} />

               <Grid container style={{ marginTop: '6rem' }} >
                  <Grid item xs={12}>
                     {goodLogin ?
                           <Typography variant='h4' >
                              Entire List of Available Alumni Bios
                           </Typography>
                           :
                           <Typography variant='h4'>
                              Public List of Alumni Bios
                           </Typography>
                     }
                  </Grid>
               </Grid>
               <Grid container>
                  <Grid item xs={6} style={{ marginTop: '2rem', height: '750px', overflow: 'scroll', border: '2px solid darkgray', borderRadius: '0.5rem', padding: '1rem' }}>
                     {bioListToDisplay}
                  </Grid>
                  <Grid item xs={6}>
                     <Grid item xs={12} style={{marginTop: '2rem'}}>
                        <TextField label='Bio Title Search (faster)' value={searchText} variant='outlined' onChange={handleKeywordSearch} style={{width: '350px'}} onFocus={handleKeywordSearchFocus} />
                     </Grid>
                     <Grid item xs={12} style={{marginTop: '2rem'}}>
                        <TextField label='Detailed Bio Search (slower)' value={detailedSearchText} variant='outlined' onChange={handleDetailedSearch} style={{width: '350px'}} onFocus={handleDetailedSearchFocus} />
                     </Grid>
                     <Grid>
                        <Button variant='outlined' style={{marginTop: '1rem'}} onClick={submitDetailedSearch}>
                           Submit Detailed Search
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>

         </Container>
      </Box>
   )
}

export default BioList