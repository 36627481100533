import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

const YbPageOne = ({returnToTOC}) => {

  const handleReturnToTOC = () => {
    returnToTOC()
  }

  return (
    <Grid container>

      {/* Page Title */}
      <Grid item xs={12}>
        <Typography variant='h4'>
          <Box letterSpacing={2}>
            This Page is In-Work
          </Box>
        </Typography>
      </Grid>

      <Grid container justify='center' style={{marginTop: '2rem', marginBottom: '5rem'}}>
        <Grid>
          <Button variant='outlined' onClick={handleReturnToTOC}>Return to Table of Contents</Button>
        </Grid>
      </Grid>

    </Grid>
  )
}

YbPageOne.propTypes = {
  pageData: PropTypes.object
}

export default YbPageOne
