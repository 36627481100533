export default (state, action) => {

    switch(action.type){
        
      case "ANNOUNCEMENT_ID":
         // console.log('Reducer ANNOUNCEMENT_ID is ', action.announcementId)
         return {
            ...state,
            announcementId: action.announcementId
         }

      case "ALL_GRADS":
         return {
            ...state,
            allGrads: action.allGrads
         }

      case "AUTHORIZED_TO_CRUD_ANNOUNCEMENTS":
         // console.log("Reducer AUTHORIZED_TO_CRUD_ANNOUNCEMENTS is ", action.authorizedToCrudAnnouncements)
         return {
            ...state,
            authorizedToCrudAnnouncements: action.authorizedToCrudAnnouncements
         }

      case "BIO_TBL_USER_DATA":
         return {
            ...state,
            bio_tbl_user_data: action.bio_tbl_user_data
         }

      case "CLASS_DATA":
         // console.log("STORE Reducer CLASS_DATA is ", action.classData)
         return {
            ...state,
            classData: action.classData
         }

         case "CLASS_PAGE":
         // console.log("Reducer CLASS_PAGE is ", action.classPage)
         return {
            ...state,
            classPage: action.classPage
         }
 
      case "GOOD_LOGIN":
         return {
            ...state,
            goodLogin: action.goodLogin
         }

      case "HAS_BIO":
         // console.log("Reducer HAS_BIO is ", action.hasBio)
         return {
            ...state,
            hasBio: action.hasBio
         }

      case "ID_OF_STORY_TO_SHOW":
         // console.log('Reducer ID_OF_STORY_TO_SHOW id is', action.idOfStoryToShow)
         return {
            ...state,
            idOfStoryToShow: action.idOfStoryToShow
         }

      case "IS_ACTIVE":
         return {
            ...state,
            isActive: action.isActive
         }

      case "IS_ADMIN":
         return {
            ...state,
            admin: action.admin
         }

      case "IS_ALUMNI":
         return {
            ...state,
            alumni: action.alumni
         }

      case "IS_SUPER_USER":
         return {
            ...state,
            superUser: action.superUser
         }

      case "STORE_JWT":
         return {
            ...state,
            jwt: action.jwt
         }

      case "LOGOUT":
         return {
            ...state,
            logout: action.logout
         }

      case "PHP_FILENAME":
         return {
            ...state,
            phpFileName: action.phpFileName
         }

      case "RESET_PASSWORD":
         return {
            ...state,
            resetPassword: action.resetPassword
         }

      case "SHOW_ALUMNI_BIO_NAVBAR_BUTTON":
         return {
            ...state,
            showAlumniBioNavbarButton: action.showAlumniBioNavbarButton
         }

      case "SHOW_CLASS_PAGE_NAVBAR_BUTTON":
         return {
            ...state,
            showClassPagesNavbarButton: action.showClassPagesNavbarButton
         }

      case "SHOW_HISTORY_NAVBAR_BUTTON":
         return {
            ...state,
            showHistoryNavbarButton: action.showHistoryNavbarButton
         }

         case "SHOW_HOME_NAVBAR_BUTTON":
         return {
            ...state,
            showHomeNavbarButton: action.showHomeNavbarButton
         }

      case "SHOW_MENU_NAVBAR_BUTTON":
         return {
            ...state,
            showMenuNavbarButton: action.showMenuNavbarButton
         }

      case "SIGNED_IN_USER_ID":
         // console.log('SIGNED_IN_USER_ID Redcuer signedInUserId is ', action.signedInUserId)
         return {
            ...state,
            signedInUserId: action.signedInUserId
         }

      case "STORY":
         // console.log("STORY Reducer story is ", action.story)
         return {
            ...state,
            story: action.story
         }

      case "TPS_CLASS":
         // console.log('Reducer TPS_CLASS is ', action.tpsClass)
         return {
            ...state,
            tpsClass: action.tpsClass
         }

         case "UPDATE_AB_HONTS":
         // console.log('Reducer UPDATE_AB_HONTS is ', action.abHonts)
         return {
            ...state,
            classData: {...state.classData, "abHonts": action.abHonts}
         }

         case "UPDATE_C_DOT":
         // console.log('Reducer UPDATE_C_DOT is ', action.cDot)
         return {
            ...state,
            classData: {...state.classData, "cDot": action.cDot}
         }

         case "UPDATE_CLASS_LEADER":
         // console.log('Reducer UPDATE_CLASS_LEADER is ', action.classLeader)
         return {
            ...state,
            classData: {...state.classData, "classLeader": action.classLeader}
         }

         case "UPDATE_DISTINGUISHED_ALUMNUS":
         // console.log('Reducer UPDATE_DISTINGUISHED_ALUMNUS is ', action.distinguishedAlumnus)
         return {
            ...state,
            classData: {...state.classData, "distinguishedAlumnus": action.distinguishedAlumnus}
         }

         case "UPDATE_LIETHEN_TITTLE":
         // console.log('Reducer UPDATE_LIETHEN_TITTLE is ', action.liethenTittle)
         return {
            ...state,
            classData: {...state.classData, "liethenTittle": action.liethenTittle}
         }

         case "UPDATE_ONIZUKA_PROP_WASH":
         // console.log('Reducer UPDATE_ONIZUKA_PROP_WASH is ', action.onizukaPropWash)
         return {
            ...state,
            classData: {...state.classData, "onizukaPropWash": action.onizukaPropWash}
         }

         case "UPDATE_OUTSTANDING_FTE":
         // console.log('Reducer UPDATE_OUTSTANDING_FTE is ', action.outstandingFTE)
         return {
            ...state,
            classData: {...state.classData, "outstandingFTE": action.outstandingFTE}
         }

         case "UPDATE_PROP_WASH":
         // console.log('Reducer UPDATE_PROP_WASH is ', action.onizukaPropWash)
         return {
            ...state,
            classData: {...state.classData, "propWash": action.propWash}
         }

         case "UPDATE_RAYMOND_JONES":
         // console.log('Reducer UPDATE_RAYMOND_JONES is ', action.raymondJones)
         return {
            ...state,
            classData: {...state.classData, "raymondJones": action.raymondJones}
         }

      case "USER_ID":
         // console.log('Reducer USER_ID is ', action.userId)
         return {
            ...state,
            userId: action.userId
         }

      case "USER_ID_OF_BIO_TO_ADD":
      // console.log('Reducer USER_ID_OF_BIO_TO_ADD is ', action.userIdOfBioToAdd)
      return {
         ...state,
         userIdOfBioToAdd: action.userIdOfBioToAdd
      }

      case "USER_ID_OF_BIO_TO_EDIT":
      // console.log('Reducer USER_ID_OF_BIO_TO_EDIT is ', action.userIdOfBioToEdit)
      return {
         ...state,
         userIdOfBioToEdit: action.userIdOfBioToEdit
      }

      case "USER_ID_OF_BIO_TO_SHOW":
      // console.log('Reducer USER_ID_OF_BIO_TO_SHOW is ', action.userIdOfBioToShow)
      return {
         ...state,
         userIdOfBioToShow: action.userIdOfBioToShow
      }

      case "USER_ID_OF_PROFILE_TO_EDIT":
         // console.log('Reducer USER_ID_OF_PROFILE_TO_EDIT is ', action.userIdOfProfileToEdit)
         return {
            ...state,
            userIdOfProfileToEdit: action.userIdOfProfileToEdit
      }

      case "USER_ID_OF_STORY_TO_ADD":
         // console.log('Reducer USER_ID_OF_STORY_TO_ADD is ', action.userIdOfStoryToAdd)
         return {
            ...state,
            userIdOfStoryToAdd: action.userIdOfStoryToAdd
         }

      case "USERID_AUTHORIZED_TO_CRUD_WEEKLY_UPDATE":
         // console.log('Reducer USERID_AUTHORIZED_TO_CRUD_WEEKLY_UPDATE userIdAuthorizedToCrudWeeklyUpdate is ', action.userIdAuthorizedToCrudWeeklyUpdate)
      return {
         ...state,
         userIdAuthorizedToCrudWeeklyUpdate: action.userIdAuthorizedToCrudWeeklyUpdate
      }
      
      case "USERNAME":
         return {
            ...state,
            username: action.username
         }

      case "USER_TYPE":
         return {
            ...state,
            userType: action.userType
         }

      default:
      return state
    }
 }