/*
 * This is so a superUser can add a Story to a registered user
 * Need to get userId of user
 * <SuperUserAddAStory />
 *
 * Once a user is selected, check MongoDB to see if that user already has a bio
 * < />
 */
import React, { useCallback, useEffect, useState } from 'react'
import AllGrads from '../Components/AllGrads'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { Redirect } from 'react-router-dom'
import Select from '@material-ui/core/Select'
import { store } from '../Redux/Store'
import Typography from '@material-ui/core/Typography'
import { userIdOfStoryToAddAction } from "../Redux/Actions"

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const SuperUserAddAStory = () => {

    const classes = useStyles();

    const [ allGradsList, setAllGradsList ] = useState([])
    const [ redirectToAddAStoryPage, setRedirectToAddAStoryPage ] = useState(false)
    const [ userIdOfStoryToAdd, setUserIdOfStoryToAdd ] = useState('')

    useEffect( () => {
        store.dispatch(userIdOfStoryToAddAction(userIdOfStoryToAdd))
        if(userIdOfStoryToAdd > 9){
            setRedirectToAddAStoryPage(true)
        }
    }, [userIdOfStoryToAdd] )

    const menuItemsToDisplay = allGradsList.map( grad => {
        return(<MenuItem key={grad.userId} value={grad.userId}>
                    { grad.lastName + ', ' +  grad.givenFirstName + ', ' + grad.tpsClass }
                </MenuItem>)
    } )

    const getAllGrads = useCallback((gradsFromAllGrads) => {
        setAllGradsList(gradsFromAllGrads)
    }, [] )

    const handleChange = (e) => {
        setUserIdOfStoryToAdd(e.target.value)
    }

    return (
        <Box style={{marginTop: '7rem'}}>
            { redirectToAddAStoryPage ? <Redirect to='/AddAStoryPage' /> : null }
            <AllGrads getAllGrads={getAllGrads} />
            <Container>
                <Grid container justify='center'>
                    <Grid item xs={12}>
                        <Typography variant='h4'>
                            Add a Story (SuperUsers Only)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: '2rem'}}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Add a Story to User</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={userIdOfStoryToAdd}
                                onChange={handleChange}
                                label="Select a Bio to Edit"
                                style={{width: '400px'}}
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                { menuItemsToDisplay }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default SuperUserAddAStory
