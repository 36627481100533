/*
 * This is the second level component for Showing each TPS class, reading any available
 * journal entries, and adding journal entries if logged in as an alumni member who
 * has CRUD authority for that TPS class
 * 
 * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
 * 
 * Puts tpsClass from dropdown in useState Hook, and sends the currently selected class to the Store
 * 
 * <ClassPages />
 *      <ShowOneClassPage />
 *          <ShowOneClassPatch />
 *          <ShowGradsFromOneClass />
 *          <ShowClassPhoto />
 *          <ShowJournalEntriesFromOneClass />
 *          <AddRemoveButtons />
 *          <AddJournalEntry />
 *          <EditJournalEntry />
 */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import AddJournalEntry from '../Components/AddJournalEntry'
import AddRemoveButtons from '../Components/AddRemoveButtons'
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import EditJournalEntry from '../Components/EditJournalEntry'
import { node_server } from '../Helpers'
import Grid from "@material-ui/core/Grid"
import MoreInfoComponent from './MoreInfoComponent'
import PropTypes from 'prop-types'
import ShowClassPhoto from './ShowClassPhoto'
import ShowGradsFromOneClass from './ShowGradsFromOneClass'
import ShowJournalEntriesFromOneClass from './ShowJournalEntriesFromOneClass'
import { store } from '../Redux/Store'
import Typography from "@material-ui/core/Typography"

const ShowOneClassPage = ({ tpsClass }) => {

    const { signedInUserId, userIdAuthorizedToCrudWeeklyUpdate } = store.getState()

    const [classPatch, setClassPatch] = useState({})
    const [classPhoto, setClassPhoto] = useState({})

    const [addEntry, setAddEntry] = useState(false)
    const [allowAddJournalEntry, setAllowAddJournalEntry] = useState(true)
    const [allowEditJournalEntry, setAllowEditJournalEntry] = useState(false)
    const [journalIdToEdit, setJournalIdToEdit] = useState('')
    const [journal, setJournal] = useState({})

    //=========================================================================
    // Sent to <AddJournalEntry />
    // These are used to trigger the useEffect Hook that gets journals for the selected class
    // A journal is updated in AddJournalEntry and the newly added entry shows up to the user
    const [numberOfJournalEntries, setNumberOfJournalEntries] = useState(0)

    const handleIncrementNumberOfJournalEntries = async () => {
        await setNumberOfJournalEntries(numberOfJournalEntries + 1)
    }
    //=========================================================================
    // Sent to <EditJournalEntry />
    const incNumJournalEntriesAfterEdit = async () => {
        await setNumberOfJournalEntries(numberOfJournalEntries + 1)
        await setAllowAddJournalEntry(true)
        await setAllowEditJournalEntry(false)
    }
    //=========================================================================

    // Sent to <AddRemoveButtons /> to control display of <AddJournalEntry />
    const handleShowJournalUpdate = async () => {
        await setAddEntry(!addEntry)
    }

    // Sent to <ShowJournalEntriesFromOneClass />
    const handleEditOneJournalEntry = async (id) => {
        await setJournalIdToEdit(id)
        await setAllowAddJournalEntry(false)
        await setAllowEditJournalEntry(true)
    }

    // Sent to <ShowJournalEntriesFromOneClass /> so it sends back the retrieved journal
    // that can then be sent to <EditJournalEntry />
    const handleGetJournal = async (journal) => {
        await setJournal(journal)
    }

    /* Call MongoDB and gets classPhoto if it exists */
    useEffect( () => {
        if( tpsClass !== '' ){
            axios.get(node_server + `classPhoto/GetClassPhoto/${tpsClass}`)
            .then( res => res.data )
            .then( data => {
                if( data ){
                    setClassPhoto( data )
                    // setShowClassPhoto(true)
                }
                else{
                    setClassPhoto({})
                    // setShowClassPhoto(false)
                }
            } )
        }
    }, [tpsClass] )

    /* Call MongoDB and gets classPatch if it exists */
    useEffect( () => {
        if( tpsClass !== '' ){
            axios.get(node_server + `classPatch/GetClassPatch/${tpsClass}`)
            .then( res => res.data )
            .then( data => {
                if( data ){
                    setClassPatch( data )
                    // setShowClassPhoto(true)
                }
                else{
                    setClassPatch({})
                    // setShowClassPhoto(false)
                }
            } )
        }
    }, [tpsClass] )

    return (
        <Box style={{ marginTop: '3rem' }} >
            <Container>
                {classPatch.tpsClass !== undefined ?
                <Grid item xs={12} >
                     {classPatch.classPatchFilename.startsWith('https://')
                        ?
                        <img
                              style={{width: '30%'}}
                              alt={classPatch.tpsClass}
                              src={`${classPatch.classPatchFilename}`}
                        />
                        :
                        <img
                              style={{width: '30%'}}
                              alt={classPatch.tpsClass}
                              src={`/images/class-patches/${classPatch.tpsClass}/${classPatch.classPatchFilename}`}
                        />
                     }
                </Grid>
                :
                null}
                <Grid item xs={12} style={{ marginTop: '2rem', marginBottom: '2rem' }} >
                    <Typography variant='h3'>
                        TPS Class: {tpsClass.toLocaleUpperCase()}
                    </Typography>
                </Grid>

                <ShowGradsFromOneClass tpsClass={tpsClass} />

                <MoreInfoComponent tpsClass={tpsClass} />

                <ShowClassPhoto classPhoto={classPhoto} />

                <ShowJournalEntriesFromOneClass
                    tpsClass={tpsClass}
                    numberOfJournalEntries={numberOfJournalEntries}
                    handleEditOneJournalEntry={handleEditOneJournalEntry}
                    handleGetJournal={handleGetJournal}
                />

                {(signedInUserId === userIdAuthorizedToCrudWeeklyUpdate) && allowAddJournalEntry ?
                    <AddRemoveButtons
                        addEntry={addEntry}
                        handleAddRemoveButtons={handleShowJournalUpdate}
                        msg1='Hide Weekly Journal Entry'
                        msg2='Add a Weekly Journal Entry'
                    />
                    : null
                }

                {addEntry && allowAddJournalEntry ?
                    <Grid>
                        <AddJournalEntry tpsClass={tpsClass} handleIncrementNumberOfJournalEntries={handleIncrementNumberOfJournalEntries} />
                    </Grid>
                    : null
                }

                {allowEditJournalEntry ?
                    <Grid>
                        <EditJournalEntry
                            tpsClass={tpsClass}
                            incNumJournalEntriesAfterEdit={incNumJournalEntriesAfterEdit}
                            journalIdToEdit={journalIdToEdit}
                            journal={journal}
                        />
                    </Grid>
                    : null
                }

            </Container>
        </Box>
    )
}

ShowOneClassPage.propTypes = {
    classPhoto: PropTypes.object,
    tpsClass: PropTypes.string
}

export default ShowOneClassPage
