/*
 * The displays the Menu button for logged in users
 */
import React, { useState }             from 'react';
import Button                          from '@material-ui/core/Button';
import { Link }                        from "react-router-dom"
import Menu                            from '@material-ui/core/Menu';
import MenuItem                        from '@material-ui/core/MenuItem';
import { store }                       from "../Redux/Store"
import Typography                      from "@material-ui/core/Typography"

export default function SimpleMenu() {

   const [anchorEl, setAnchorEl] = useState(null);

   const { alumni, authorizedToCrudAnnouncements, hasBio, showMenuNavbarButton, userType } = store.getState()

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   return (
      <div>
         {showMenuNavbarButton ?
               <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{border: "1px solid white", marginLeft: '1rem'}} >
                  <Typography style={{ color: "white" }}>
                     Menu
                  </Typography>
               </Button>
               :
               null
         }
         <Menu
               id="simple-menu"
               anchorEl={anchorEl}
               keepMounted
               open={Boolean(anchorEl)}
               onClose={handleClose}
         >
               {userType === 'superUser' ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/Admin/AddNewClassPage" style={{ textDecoration: "none", color: "inherit" }}>
                           Admin - Add a New Class
                     </Link>
                  </MenuItem>
                  : null
               }
               {userType === 'superUser' ?
                    <MenuItem onClick={handleClose}>
                        <Link to="/AddPhoto" style={{ textDecoration: "none", color: "inherit" }}>
                            Admin - Add a New Photo
                        </Link>
                    </MenuItem>
                  : null
               }
               {userType === 'superUser' ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/SuperUserAddABio" style={{ textDecoration: "none", color: "inherit" }}>
                           Add a Bio
                     </Link>
                  </MenuItem>
                  : null
               }
               {userType === 'superUser' ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/SuperUserAddAStory" style={{ textDecoration: "none", color: "inherit" }}>
                           Add a Story
                     </Link>
                  </MenuItem>
                  : null
               }
               {userType === 'superUser' ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/MoreInfo" style={{ textDecoration: "none", color: "inherit" }}>
                           Add More Info to Alum or TPS Class
                     </Link>
                  </MenuItem>
                  : null
               }
               {userType === 'superUser' ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/Analytics" style={{ textDecoration: "none", color: "inherit" }}>
                           Analytics
                     </Link>
                  </MenuItem>
                  : null
               }
               {authorizedToCrudAnnouncements ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/AnnouncementLanding" style={{ textDecoration: "none", color: "inherit" }}>
                           Announcements
                     </Link>
                  </MenuItem>
                  : null
               }
               {userType === 'superUser' ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/EditABio" style={{ textDecoration: "none", color: "inherit" }}>
                           Edit a Bio
                     </Link>
                  </MenuItem>
                  : null
               }
               {userType === 'superUser' ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/EditAProfile" style={{ textDecoration: "none", color: "inherit" }}>
                           Edit a Profile
                     </Link>
                  </MenuItem>
                  : null
               }
               {userType === 'superUser' ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/ManagePhotos" style={{ textDecoration: "none", color: "inherit" }}>
                           Manage Photos
                     </Link>
                  </MenuItem>
                  : null
               }
               {userType === 'superUser' ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/SuperUserResetPassword" style={{ textDecoration: "none", color: "inherit" }}>
                           Reset A Password
                     </Link>
                  </MenuItem>
                  : null
               }
               {alumni & !hasBio ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/AddOneBioPage" style={{ color: "inherit", textDecoration: "none" }}>
                           Add a Bio
                     </Link>
                  </MenuItem>
                  : null
               }
               {alumni && hasBio ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/EditOneBioPage" style={{ color: "inherit", textDecoration: "none" }}>
                           Edit Your Bio
                     </Link>
                  </MenuItem>
                  : null
               }

               {/* Comment out per issue #204 */}
               {/* {classPage ?
                  null
                  :
                  <MenuItem onClick={handleClose}>
                     <Link to="/AddClassPage" style={{ color: "inherit", textDecoration: "none" }}>
                           Add Class Page
                     </Link>
                  </MenuItem>
               } */}

               <MenuItem onClick={handleClose}>
                  <Link to="/Search" style={{ color: "inherit", textDecoration: "none" }}>
                     Search
                  </Link>
               </MenuItem>
               {alumni ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/UpdateGrad" style={{ textDecoration: "none", color: "inherit" }}>
                           Update Profile
                     </Link>
                  </MenuItem>
                  : null
               }
               {userType === 'superUser' ?
                  <MenuItem onClick={handleClose}>
                     <Link to="/YearbookAdminPage" style={{ textDecoration: "none", color: "inherit" }}>
                           Yearbook Admin
                     </Link>
                  </MenuItem>
                  : null
               }
         </Menu>
      </div>
   );
}
