/*
 * The <PersonalAndClassStats /> is on the SearchByName Component
 *
 * From the Store:
 *    hasBio
 * 
 * The Prop Wash award was implemented first, and was done differently than
 * the Top Pilot or Top FTE. It was working, so it was left as is instead of
 * refactoring.
 */

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import AbHonts from '../Components/PersonalAndClassStats/AbHonts'
import CDot from '../Components/PersonalAndClassStats/CDot'
import Biography from '../Components/PersonalAndClassStats/Biography'
import ClassLeader from '../Components/PersonalAndClassStats/ClassLeader'
import DistinguishedAlumnus from '../Components/PersonalAndClassStats/DistinguishedAlumnus'
import LiethenTittle from '../Components/PersonalAndClassStats/LiethenTittle'
import OnizukaPropWash from '../Components/PersonalAndClassStats/OnizukaPropWash'
import OutstandingFTE from '../Components/PersonalAndClassStats/OutstandingFTE'
import PropWash from '../Components/PersonalAndClassStats/PropWash'
import RaymondJones from '../Components/PersonalAndClassStats/RaymondJones'

import PersonalAndClassStatsSearchResults from './PersonalAndClassStatsSearchResults'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { awardYears, get_all_grads_from_tbl_auth, node_server } from '../Helpers'
import { store } from '../Redux/Store'
import Typography from '@material-ui/core/Typography'
import { classDataAction, updateAbHontsAction, updateCDotAction, updateClassLeaderAction, updateDistinguishedAlumnusAction,
         updateLiethenTittleAction, updateOnizukaPropWashAction, updateOutstandingFTEAction,
         updatePropWashAction, updateRaymondJonesAction } from '../Redux/Actions'

const PersonalAndClassStats = () => {

   const [ abHonts, setAbHonts ] = useState(false)
   const [ allGrads, setAllGrads ] = useState([])
   const [ allGradsForDistinguishedAlumni, setAllGradsForDistinguishedAlumni ] = useState([])
   const [ cDot, setCDot ] = useState(false)
   const [ dataToSubmit, setDataToSubmit ] = useState({})
   const [ distinguishedAlumnus, setDistinguishedAlumnus ] = useState(false)
   const [ editItem, setEditItem ] = useState('')
   const [ liethenTittle, setLiethenTittle ] = useState(false)
   const [ onizukaPropWash, setOnizukaPropWash ] = useState(false)
   const [ outstandingFTE, setOutStandingFTE ] = useState(false)
   const [ propWash, setPropWash ] = useState(false)
   const [ raymondJones, setRaymondJones ] = useState(false)
   const [ searchResults, setSearchResults ] = useState([])

   const [ showSearchResultsBelowClassLeader, setShowSearchResultsBelowClassLeader ] = useState(false)
   const [ showSearchResultsBelowAbHonts, setShowSearchResultsBelowAbHonts ] = useState(false)
   const [ showSearchResultsBelowLiethenTittle, setShowSearchResultsBelowLiethenTittle ] = useState(false)
   const [ showSearchResultsBelowOutStandingFTE, setShowSearchResultsBelowOutStandingFTE ] = useState(false)
   const [ showSearchResultsBelowRaymondJones, setShowSearchResultsBelowRaymondJones ] = useState(false)
   const [ showSearchResultsBelowOnizukaPropWash, setShowSearchResultsBelowOnizukaPropWash ] = useState(false)
   const [ showSearchResultsBelowPropWash, setShowSearchResultsBelowPropWash ] = useState(false)
   const [ showSearchResultsBelowCDot, setShowSearchResultsBelowCDot ] = useState(false)
   const [ showSearchResultsBelowDistinguishedAlumnus, setShowSearchResultsBelowDistinguishedAlumnus ] = useState(false)

   const { classData, tpsClass } = store.getState()
   

   /* Get all grads from tbl_auth for use in Search. This is an array of
      all greds. */
   useEffect( () => {
      /* Somehow I was getting the headers.common set to a GET to the Node server on Login which was
         causing a CORS error, could not track it down, so re-setting it here so the initial land on
         /Search does not get the CORS error. */
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}

      axios.get(get_all_grads_from_tbl_auth)
         .then( res => {
            setAllGrads( showCurrentClass(res.data.allGradsFromTblAuth) )
            setAllGradsForDistinguishedAlumni( removeCurrentClass(res.data.allGradsFromTblAuth) )
         } )
         .catch(error => {
            console.log('<PersonalAndClassStats /> .cattch error  is', error)
         })
   }, [] )

   /* Set Awards based on TPS Class, for example, Class 1982b was the
      first class to select a Distinguished Alumnus */
   useEffect( () => {
      for (let i = 0; i < awardYears.abHonts.length; i++) {
         if (awardYears.abHonts[i] === tpsClass.toLocaleLowerCase()) {
            setAbHonts(true)
         }
      }

      for (let i = 0; i < awardYears.cDot.length; i++) {
         if (awardYears.cDot[i] === tpsClass.toLocaleLowerCase()) {
            setCDot(true)
         }
      }

      for (let i = 0; i < awardYears.distinguishedAlumnus.length; i++) {
         if (awardYears.distinguishedAlumnus[i] === tpsClass.toLocaleLowerCase()) {
            setDistinguishedAlumnus(true)
         }
      }

      for (let i = 0; i < awardYears.liethenTittle.length; i++) {
         if (awardYears.liethenTittle[i] === tpsClass.toLocaleLowerCase()) {
            setLiethenTittle(true)
         }
      }

      for (let i = 0; i < awardYears.outstandingFTE.length; i++) {
         if (awardYears.outstandingFTE[i] === tpsClass.toLocaleLowerCase()) {
            setOutStandingFTE(true)
         }
      }

      /* Set Awards based on TPS Class, for example, for TPS Class 1987b,
         the Prop Wash was the first Onizuka Prop Wash */
      for (let i = 0; i < awardYears.onizukaPropWash.length; i++) {
         if (awardYears.onizukaPropWash[i] === tpsClass.toLocaleLowerCase()) {
            setOnizukaPropWash(true)
         }
      }

      for (let i = 0; i < awardYears.propWash.length; i++) {
         if (awardYears.propWash[i] === tpsClass.toLocaleLowerCase()) {
            setPropWash(true)
         }
      }

      for (let i = 0; i < awardYears.raymondJones.length; i++) {
         if (awardYears.raymondJones[i] === tpsClass.toLocaleLowerCase()) {
            setRaymondJones(true)
         }
      }
      
   }, [tpsClass] )

   /* Remove class members of users class so they can't add them to Distinguished Alumnus.
      Use .split on username to strip out those with the same TPS Class */
   const removeCurrentClass = (a) => {
      return a.filter( a => {
         let aClass = a.username.split('.')

         if (aClass[2]) {
            aClass = aClass[2].toLocaleLowerCase()

            if (aClass !== tpsClass.toLocaleLowerCase()) {
               return a
            }
         }
         return null
      } )
   }

   /* Show only alumni from the class of the logged on alumni member */
   const showCurrentClass = (a) => {
      return a.filter( a => {
         let aClass = a.username.split('.')

         if (aClass[2]) {
            aClass = aClass[2].toLocaleLowerCase()

            if (aClass === tpsClass.toLocaleLowerCase()) {
               return a
            }
         }
         return null
      } )
   }

   const handleSearch = (e) => {
         let results = ''
         if (editItem === 'distinguishedAlumnus') {
            results = allGradsForDistinguishedAlumni.filter( (g) => {
               if (g.username.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())) {
                 return g
               }
               return null
             })
         }
         else {
            results = allGrads.filter( (g) => {
               if (g.username.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())) {
                 return g
               }
               return null
             })
         }
         setSearchResults(results)
   }

   const searchListToDisplay = searchResults.map( (d, i) => {
      return (
        <Grid key={d.userId} item xs={12}>
          <Button onClick={() => handleSelectFromSearch({_id_: d._id, userId: d.userId, username: d.username})}>
            <Typography variant='subtitle1'>
              {d.username}
            </Typography>
          </Button>
        </Grid>
      )
   } )

   const handleSelectFromSearch = (g) => {
      let nameToSave = g.username.toLocaleLowerCase()
      const numDots = nameToSave.split('.')
      let firstName = ''
      let lastName = ''
      if (numDots.length === 3) {
        firstName = numDots[0][0].toLocaleUpperCase() + numDots[0].slice(1,numDots[0].length)
        lastName = numDots[1][0].toLocaleUpperCase() + numDots[1].slice(1,numDots[1].length)
      } else if (numDots.length === 4) {
        const firstInitial = numDots[0][0].toLocaleUpperCase() + '.'
        const secondInitial = numDots[1][0].toLocaleUpperCase() + '.'
        firstName = firstInitial + secondInitial
        lastName = numDots[2][0].toLocaleUpperCase() + numDots[2].slice(1,numDots[2].length)
      }

      setDataToSubmit({...dataToSubmit, id: g.userId, name: firstName + ' ' + lastName})

      /* This shows the single alum selected from the search list */
      setSearchResults([g])
   }

   const editClick = (e) => {
      setEditItem(e.editItem)
      setDataToSubmit({_id: classData._id})
   }

   const cancel = () => {
      setEditItem('')
      setShowSearchResultsBelowClassLeader(false)
      setShowSearchResultsBelowAbHonts(false)
      setShowSearchResultsBelowLiethenTittle(false)
      setShowSearchResultsBelowOutStandingFTE(false)
      setShowSearchResultsBelowRaymondJones(false)
      setShowSearchResultsBelowOnizukaPropWash(false)
      setShowSearchResultsBelowPropWash(false)
      setShowSearchResultsBelowCDot(false)
      setShowSearchResultsBelowDistinguishedAlumnus(false)
   }

   const submit = () => {
      /* The IF is to PUT to the database, I tried to make this more dynamic but ran into trouble */
      if (Object.keys(classData).length > 0 ) {

         if (editItem === 'abHonts') {
            axios.put(node_server + 'classLeaderAwardData/updateAbHonts', dataToSubmit)
            .then( res => {
               if (res.data.status === 200) {
                  setEditItem('')

                  /* This is needed so the store updates which re-renders the component */
                  store.dispatch(updateAbHontsAction(res.data.data.abHonts))
               }
            })
         }
         else if (editItem === 'cDot') {
            axios.put(node_server + 'classLeaderAwardData/updateCDot', dataToSubmit)
            .then( res => {
               if (res.data.status === 200) {
                  setEditItem('')

                  /* This is needed so the store updates which re-renders the component */
                  store.dispatch(updateCDotAction(res.data.data.cDot))
               }
            })
         }
         else if (editItem === 'classLeader') {
            axios.put(node_server + 'classLeaderAwardData/updateClassLeader', dataToSubmit)
            .then( res => {
               if (res.data.status === 200) {
                  setEditItem('')

                  /* This is needed so the store updates which re-renders the component */
                  store.dispatch(updateClassLeaderAction(res.data.data.classLeader))
               }
            })
         }
         else if (editItem === 'distinguishedAlumnus') {
            axios.put(node_server + 'classLeaderAwardData/updateDistinguishedAlumnus', dataToSubmit)
            .then( res => {
               if (res.data.status === 200) {
                  setEditItem('')

                  /* This is needed so the store updates which re-renders the component */
                  store.dispatch(updateDistinguishedAlumnusAction(res.data.data.distinguishedAlumnus))
               }
            })
         }
         else if (editItem === 'liethenTittle') {
            axios.put(node_server + 'classLeaderAwardData/updateLiethenTittle', dataToSubmit)
            .then( res => {
               if (res.data.status === 200) {
                  setEditItem('')

                  /* This is needed so the store updates which re-renders the component */
                  store.dispatch(updateLiethenTittleAction(res.data.data.liethenTittle))
               }
            })
         }
         else if (editItem === 'onizukaPropWash') {
            axios.put(node_server + 'classLeaderAwardData/updateOnizukaPropWash', dataToSubmit)
               .then( res => {
                  if (res.data.status === 200) {
                     setEditItem('')

                     /* This is needed so the store updates which re-renders the component */
                     store.dispatch(updateOnizukaPropWashAction(res.data.data.onizukaPropWash))
                  }
               }
            )
         }
         else if (editItem === 'outstandingFTE') {
            axios.put(node_server + 'classLeaderAwardData/updateOutstandingFTE', dataToSubmit)
               .then( res => {
                  if (res.data.status === 200) {
                     setEditItem('')

                     /* This is needed so the store updates which re-renders the component */
                     store.dispatch(updateOutstandingFTEAction(res.data.data.outstandingFTE))
                  }
               }
            )
         }
         else if (editItem === 'propWash') {
            axios.put(node_server + 'classLeaderAwardData/updatePropWash', dataToSubmit)
               .then( res => {
                  if (res.data.status === 200) {
                     setEditItem('')

                     /* This is needed so the store updates which re-renders the component */
                     store.dispatch(updatePropWashAction(res.data.data.propWash))
                  }
               }
            )
         }
         else if (editItem === 'raymondJones') {
            axios.put(node_server + 'classLeaderAwardData/updateRaymondJones', dataToSubmit)
               .then( res => {
                  if (res.data.status === 200) {
                     setEditItem('')

                     /* This is needed so the store updates which re-renders the component */
                     store.dispatch(updateRaymondJonesAction(res.data.data.raymondJones))
                  }
               }
            )
         }
      }
      /* The ELSE is to POST to the database */
      else {

         if (editItem === 'abHonts') {
            dataToSubmit.tpsClass = tpsClass
            axios.post(node_server + 'classLeaderAwardData/addAbHonts', dataToSubmit)
            .then( res => {
               if (res.data.status === 200) {
                  setEditItem('')
                  
                  store.dispatch(classDataAction(res.data.data))
               }
            })
         }
         else if (editItem === 'cDot') {
            dataToSubmit.tpsClass = tpsClass
            axios.post(node_server + 'classLeaderAwardData/addCDot', dataToSubmit)
            .then( res => {
               if (res.data.status === 200) {
                  setEditItem('')
                  
                  store.dispatch(classDataAction(res.data.data))
               }
            })
         }
         else if (editItem === 'classLeader') {
            dataToSubmit.tpsClass = tpsClass
            axios.post(node_server + 'classLeaderAwardData/addClassLeader', dataToSubmit)
            .then( res => {
               if (res.data.status === 200) {
                  setEditItem('')
                  
                  store.dispatch(classDataAction(res.data.data))
               }
            })
         }
         else if (editItem === 'distinguishedAlumnus') {
            dataToSubmit.tpsClass = tpsClass
            axios.post(node_server + 'classLeaderAwardData/addDistinguishedAlumnus', dataToSubmit)
            .then( res => {
               if (res.data.status === 200) {
                  setEditItem('')
                  
                  store.dispatch(classDataAction(res.data.data))
               }
            })
         }
         else if (editItem === 'liethenTittle') {
            dataToSubmit.tpsClass = tpsClass
            axios.post(node_server + 'classLeaderAwardData/addLiethenTittle', dataToSubmit)
               .then( res => {
                  if (res.data.status === 200) {
                     setEditItem('')

                     /* This is needed so the store updates which re-renders the component */
                     store.dispatch(classDataAction(res.data.data))
                  }
               }
            )
         }
         else if (editItem === 'onizukaPropWash') {
            dataToSubmit.tpsClass = tpsClass
            axios.post(node_server + 'classLeaderAwardData/addOnizukaPropWash', dataToSubmit)
               .then( res => {
                  if (res.data.status === 200) {
                     setEditItem('')

                     /* This is needed so the store updates which re-renders the component */
                     store.dispatch(classDataAction(res.data.data))
                  }
               }
            )
         }
         else if (editItem === 'outstandingFTE') {
            dataToSubmit.tpsClass = tpsClass
            axios.post(node_server + 'classLeaderAwardData/addOutstandingFTE', dataToSubmit)
               .then( res => {
                  if (res.data.status === 200) {
                     setEditItem('')

                     /* This is needed so the store updates which re-renders the component */
                     store.dispatch(classDataAction(res.data.data))
                  }
               }
            )
         }
         else if (editItem === 'propWash') {
            dataToSubmit.tpsClass = tpsClass
            axios.post(node_server + 'classLeaderAwardData/addPropWash', dataToSubmit)
               .then( res => {
                  if (res.data.status === 200) {
                     setEditItem('')

                     /* This is needed so the store updates which re-renders the component */
                     store.dispatch(classDataAction(res.data.data))
                  }
               }
            )
         }
         else if (editItem === 'raymondJones') {
            dataToSubmit.tpsClass = tpsClass
            axios.post(node_server + 'classLeaderAwardData/addRaymondJones', dataToSubmit)
               .then( res => {
                  if (res.data.status === 200) {
                     setEditItem('')

                     /* This is needed so the store updates which re-renders the component */
                     store.dispatch(classDataAction(res.data.data))
                  }
               }
            )
         }
      }
      setShowSearchResultsBelowClassLeader(false)
      setShowSearchResultsBelowAbHonts(false)
      setShowSearchResultsBelowLiethenTittle(false)
      setShowSearchResultsBelowOutStandingFTE(false)
      setShowSearchResultsBelowRaymondJones(false)
      setShowSearchResultsBelowOnizukaPropWash(false)
      setShowSearchResultsBelowPropWash(false)
      setShowSearchResultsBelowCDot(false)
      setShowSearchResultsBelowDistinguishedAlumnus(false)
   }

   const classLeaderFocus = () => { setShowSearchResultsBelowClassLeader(true) }
   const abHontsFocus = () => { setShowSearchResultsBelowAbHonts(true) }
   const liethenTittleFocus = () => { setShowSearchResultsBelowLiethenTittle(true) }
   const outStandingFTEFocus = () => { setShowSearchResultsBelowOutStandingFTE(true) }
   const raymondJonesFocus = () => { setShowSearchResultsBelowRaymondJones(true) }
   const onizukaPropWashFocus = () => { setShowSearchResultsBelowOnizukaPropWash(true) }
   const propWashFocus = () => { setShowSearchResultsBelowPropWash(true) }
   const cDotFocus = () => { setShowSearchResultsBelowCDot(true) }
   const distinguishedAlumnusFocus = () => { setShowSearchResultsBelowDistinguishedAlumnus(true) }

    return (
        <Box style={{height: '500px', marginTop: '2rem'}} >

            <Container >
               <Typography variant='h5' style={{marginTop: '1rem'}} >
                    Your Personal and Class Stats
                </Typography>
            </Container>

            <Grid container>
               <Grid item xs={12} sm={8} >
                  <Container >
                     <Grid container >
                        <Grid item xs={12}>
                           <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'center'}} >
                           Class Award Data
                           </Typography>
                        </Grid>
                     </Grid>
                  </Container>

                  <ClassLeader classData={classData} editClick={editClick} editItem={editItem} handleSearch={handleSearch} handleFocus={classLeaderFocus} />
                  {showSearchResultsBelowClassLeader ? <PersonalAndClassStatsSearchResults cancel={cancel} searchListToDisplay={searchListToDisplay} submit={submit} /> : null}
                  
                  {abHonts ? <AbHonts classData={classData} editClick={editClick} editItem={editItem} handleSearch={handleSearch} handleFocus={abHontsFocus} /> : null }
                  {showSearchResultsBelowAbHonts ? <PersonalAndClassStatsSearchResults cancel={cancel} searchListToDisplay={searchListToDisplay} submit={submit} /> : null}

                  {liethenTittle ? <LiethenTittle classData={classData} editClick={editClick} editItem={editItem} handleSearch={handleSearch} handleFocus={liethenTittleFocus} /> : null }
                  {showSearchResultsBelowLiethenTittle ? <PersonalAndClassStatsSearchResults cancel={cancel} searchListToDisplay={searchListToDisplay} submit={submit} /> : null}

                  {outstandingFTE ? <OutstandingFTE classData={classData} editClick={editClick} editItem={editItem} handleSearch={handleSearch} handleFocus={outStandingFTEFocus} /> : null }
                  {showSearchResultsBelowOutStandingFTE ? <PersonalAndClassStatsSearchResults cancel={cancel} searchListToDisplay={searchListToDisplay} submit={submit} /> : null}

                  {raymondJones ? <RaymondJones classData={classData} editClick={editClick} editItem={editItem} handleSearch={handleSearch} handleFocus={raymondJonesFocus} /> : null }
                  {showSearchResultsBelowRaymondJones ? <PersonalAndClassStatsSearchResults cancel={cancel} searchListToDisplay={searchListToDisplay} submit={submit} /> : null}

                  {onizukaPropWash ? <OnizukaPropWash classData={classData} editClick={editClick} editItem={editItem} handleSearch={handleSearch} handleFocus={onizukaPropWashFocus} /> : null }
                  {showSearchResultsBelowOnizukaPropWash ? <PersonalAndClassStatsSearchResults cancel={cancel} searchListToDisplay={searchListToDisplay} submit={submit} /> : null}

                  {propWash ? <PropWash classData={classData} editClick={editClick} editItem={editItem} handleSearch={handleSearch} handleFocus={propWashFocus} /> : null }
                  {showSearchResultsBelowPropWash ? <PersonalAndClassStatsSearchResults cancel={cancel} searchListToDisplay={searchListToDisplay} submit={submit} /> : null}

                  {cDot ? <CDot classData={classData} editClick={editClick} editItem={editItem} handleSearch={handleSearch} handleFocus={cDotFocus} /> : null }
                  {showSearchResultsBelowCDot ? <PersonalAndClassStatsSearchResults cancel={cancel} searchListToDisplay={searchListToDisplay} submit={submit} /> : null}

                  {distinguishedAlumnus ? <DistinguishedAlumnus classData={classData} editClick={editClick} editItem={editItem} handleSearch={handleSearch} handleFocus={distinguishedAlumnusFocus} /> : null }
                  {showSearchResultsBelowDistinguishedAlumnus ? <PersonalAndClassStatsSearchResults cancel={cancel} searchListToDisplay={searchListToDisplay} submit={submit} /> : null}

                  <Biography />

               </Grid>

            </Grid>

        </Box>
    )
}

export default PersonalAndClassStats
