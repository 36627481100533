/*
 * This is the Top level component for Adding One Bio via Nodejs / MongoDB
 * This is the page navigated to when a user wants to add a bio
 * 
 * Calls the Node server and gets a list of all the TPS Classes from tbl_class_page
 * 
 * <AddOneBioPage />
 *      <ShowOfficialTitleForOneGrad />
 *      <ShowBioEntriesForOneGrad />
 *      <ShowMilitaryUnitsForOneGrad />
 *      <AddBioHeader />
 *      <AddRemoveButtons />
 *      <AddBioEntry />
 *      <AddRemoveButtons />
 *      <AddMilitaryUnitToBio />
 */

import React, { useCallback, useState } from 'react'
import AddBioEntry from '../Components/AddBioEntry'
import AddBioHeader from '../Components/AddBioHeader'
import AddMilitaryUnitToBio from '../Components/AddMilitaryUnitToBio'
import AddRemoveButtons from '../Components/AddRemoveButtons'
import axios from 'axios'
import { bioTblUserDataAction, hasBioAction } from '../Redux/Actions'
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import GetBioDataForMongoDB from '../Components/GetBioDataForMongoDB'
import Grid from "@material-ui/core/Grid"
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import Paper from "@material-ui/core/Paper"
import ShowBioEntriesForOneGrad from '../Components/ShowBioEntriesForOneGrad'
import ShowMilitaryUnitsForOneGrad from '../Components/ShowMilitaryUnitsForOneGrad'
import ShowOfficialTitleForOneGrad from '../Components/ShowOfficialTitleForOneGrad'
import { store } from '../Redux/Store'

const AddOneBioPage = props => {

   const { bio_tbl_user_data, superUser, userIdOfBioToAdd, userIdOfBioToEdit, userIdOfBioToShow, username } = store.getState()
   const { givenFirstName, lastName, militaryRank, tpsClass } = bio_tbl_user_data

   const [bio, setBio] = useState({})
   const [bioEntry, setBioEntry] = useState([])
   const [bioIndex, setBioIndex] = useState(0)
   const [militaryUnit, setMilitaryUnit] = useState([])
   const [militaryUnitIndex, setMilitaryUnitIndex] = useState(0)
   const [newBioBool, setNewBioBool] = useState(true)
   const [officialTitle, setOfficialTitle] = useState('')
   const [officialTitleCount, setOfficialTitleCount] = useState(0)
   const [publicBio, setPublicBio] = useState(false)

   // Toggles <AddBioHeader /> to off upon initial entry of that data
   const [ showAddBioHeader, setShowAddBioHeader ] = useState(true)

   // Toggles <AddBioEntryForOneGrad />
   const [addBioEntryBool, setAddBioEntryBool] = useState(true)

   // Toggles <AddMilitaryUnitForOneGrad />
   const [addMilitaryUnitBool, setAddMilitaryUnitBool] = useState(false)

   const getDataFromMongoDB = useCallback((bioFromMongoDB) => {
      store.dispatch( bioTblUserDataAction( bioFromMongoDB ) )
   }, [] )

   // Sent to <AddBioHeader /> as a prop to return the official title of the bio to useState Hook here
   // for submit to the db
   const handleOfficialTitle = (e) => {
      setOfficialTitle(e.target.value)
      setOfficialTitleCount(e.target.value.length)
   }

   // Sent to <AddBioHeader /> as a prop to return whether the bio is public or private
   // If moved, e.target.checked = TRUE
   // If moved back, e.target.checked = FALSE
   const handlePublic = async (e) => {
      if (e.target.checked) {
         setPublicBio(true)
      } else {
         setPublicBio(false)
      }
   }

   // Sent to <AddRemoveButtons />
   const handleAddRemoveButtons = () => {
      setAddBioEntryBool(!addBioEntryBool)
   }

   // Sent to <AddRemoveButtons />
   const handleAddRemoveButtonsMilitaryUnits = () => {
      setAddMilitaryUnitBool(!addMilitaryUnitBool)
   }

   const submitBioEntry = (sectionText, sectionTitle) => {
      /* 
      * ADD new bio is triggered by AddBioEntry only when
      * <AddBioHeader /> officialTitle is not blank,
      * <AddBioEntry /> sectionText and sectionTitle are not blank
      */

      if(newBioBool){
         /* This sets the userId to that of a new user, or if signed in as
            a superUser it makes sure the userId of the user selected is sent
            to the db as opposed to the userId of the superUser */
         let userId = userIdOfBioToShow
         if( superUser ){
            userId = userIdOfBioToAdd
         }

         // This hits the Nodejs / MongoDB server
         axios.post(node_server + 'bio', {
            bioIndex: bioIndex,
            CLIENT_PUBLIC_TOKEN,
            givenFirstName: givenFirstName,
            lastName: lastName,
            militaryRank: militaryRank,
            tpsClass: tpsClass,
            officialTitle: officialTitle,
            publicBio: publicBio,
            sectionText: sectionText,
            sectionTitle: sectionTitle,
            userId: userId
         })
            .then( res => res.data )
            .then( data => {
               // console.log('AddOneBioPage MongoDB axios.post data is ', data)
               setBio(data)
               setBioIndex(bioIndex + 1)
               setNewBioBool(false)
               setOfficialTitle(data.officialTitle)
               setPublicBio(data.publicBio)
               setBioEntry(data.bioEntry)
               setMilitaryUnit(data.militaryUnit)
               store.dispatch(hasBioAction(true))
               setShowAddBioHeader(false)
            })
         }
      // UPDATE existing bioEntry
      else {
         bio.bioEntry.push({
            bioIndex: bioIndex,
            text: sectionText,
            title: sectionTitle,
         })
         // This hits the Nodejs / MongoDB server
         axios.put(node_server + 'bio', {
            bio,
            CLIENT_PUBLIC_TOKEN
         })
            .then( res => res.data )
            .then( data => {
               setBio(data)
               setBioIndex(bioIndex + 1)
               setOfficialTitle(data.officialTitle)
               setPublicBio(data.publicBio)
               setBioEntry(data.bioEntry)
            })
      }
   }

   // Sent to <AddMilitaryUnitToBio />
   const submitMilitaryUnit = (militaryUnit) => {
      bio.militaryUnit.push({militaryUnitIndex: militaryUnitIndex, militaryUnit: militaryUnit})
      // This hits the Nodejs / MongoDB server
      axios.put(node_server + 'bio', {
         bio,
         CLIENT_PUBLIC_TOKEN
      })
         .then( res => res.data )
         .then( data => {
            setBio(data)
            setMilitaryUnitIndex(militaryUnitIndex + 1)
            setMilitaryUnit(data.militaryUnit)
         })
   }

   return (
      <Box style={{marginTop: '6rem'}} >
         {(userIdOfBioToEdit !== "") && !superUser ? <GetBioDataForMongoDB getDataFromMongoDB={getDataFromMongoDB} userId={userIdOfBioToEdit}/> : null}
         {/* This gets user data from tbl_auth when adding a new bio to an existing user */}
         {superUser ? <GetBioDataForMongoDB getDataFromMongoDB={getDataFromMongoDB} userId={userIdOfBioToAdd}/> : null}
         <Container>
            <Grid>
               {!newBioBool ?
                  <Paper style={{ backgroundColor: 'rgba(211,211,211,0.5)', padding: '1rem' }}>
                     <Grid>
                        <ShowOfficialTitleForOneGrad
                           officialTitle={bio.officialTitle}
                           showEditButtons={false}
                        />
                     </Grid>
                     <Grid style={{marginTop: '1rem'}} >
                        <ShowBioEntriesForOneGrad
                           bioEntry={bioEntry}
                           showEditButtons={false}
                        />
                     </Grid>
                     <Grid style={{marginTop: '1rem'}} >
                        <ShowMilitaryUnitsForOneGrad
                           militaryUnit={militaryUnit}
                           showEditButtons={false}
                        />
                     </Grid>
                  </Paper>
               : null}

               {showAddBioHeader ?
               <   AddBioHeader handleOfficialTitle={handleOfficialTitle} handlePublic={handlePublic} userIdOfBioToAdd={userIdOfBioToAdd.toString(10)} username={username} />
               : null
               }

               {bio.length !== 0 ?
                  <AddRemoveButtons
                     addEntry={addBioEntryBool}
                     handleAddRemoveButtons={handleAddRemoveButtons}
                     msg1='Hide Bio Entry'
                     msg2='Add A Bio Entry'
                  />
               : null }

               <AddBioEntry
                  addEntry={addBioEntryBool}
                  officialTitleCount={officialTitleCount}
                  submitBioEntry={submitBioEntry}
               />

               {bio.length !== 0 && !addBioEntryBool ?
                  <AddRemoveButtons
                     addEntry={addMilitaryUnitBool}
                     handleAddRemoveButtons={handleAddRemoveButtonsMilitaryUnits}
                     msg1='Hide Military Unit Entry'
                     msg2='Add A Military Unit'
                  />
               : null }

                  <AddMilitaryUnitToBio
                     addEntry={addMilitaryUnitBool}
                     submitMilitaryUnit={submitMilitaryUnit}
                  />

            </Grid>
         </Container>
      </Box>
   )
}

export default AddOneBioPage
