/* 
 * Functional Component
 * Has:
 *      The text at the top of the AddBio page
 *      The slider Switch for Private or Public
 *      The Official Title
*/

import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { store } from '../Redux/Store'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const AddBioHeader = ({handleOfficialTitle, handlePublic, userIdOfBioToAdd, username}) => {

    const { alumni, hasBio, superUser } = store.getState()

    // officialTitle received from <AddBio /> as a prop, send TextField input back
    const handleChange = (e) => {
        handleOfficialTitle(e)
    }

    // privacy received from <AddBio /> as a prop, send back:
    // If private then nothing is sent back
    // If public then e.target.value = 'public'
    const handlePublicSwitch = (e) => {
        handlePublic(e)
    }

    return (
        <Box style={{ marginTop: '5rem', marginBottom: '2rem' }}>
            <Container>

                <Grid container>

                    {!hasBio && alumni ?
                        <Grid container justify='center' item xs={12}>
                            <Typography variant='h4' style={{ marginTop: '1rem' }} >
                                Bio Page for {username}
                            </Typography>
                        </Grid>
                    : null }

                    {superUser ?
                        <Grid container justify='center' item xs={12}>
                            <Typography variant='h4' style={{ marginTop: '1rem' }} >
                                Bio Page for {userIdOfBioToAdd}
                            </Typography>
                        </Grid>
                    : null }

                    <Grid container alignItems='flex-end' item xs={12} style={{marginTop: '1rem'}}>
                        <Grid container alignItems='center' item xs={12} md={2}>
                            <Grid item>Private</Grid>
                            <Grid item >
                                <Switch data-testid='AddBioHeader-switch' value='public' onChange={handlePublicSwitch} />
                            </Grid>
                            <Grid item>Public</Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField label="Official Title" name="oficialTitle" onChange={handleChange} data-testid='AddBioHeader-officialTitle' style={{ width: '650px' }} />
                        </Grid>
                    </Grid>

                </Grid>
                
            </Container>
        </Box>
    )
}

AddBioHeader.propTypes = {
    handleOfficialTitle: PropTypes.func,
    handlePublic: PropTypes.func,
    userIdOfBioToAdd: PropTypes.string,
    username: PropTypes.string
}

export default AddBioHeader
