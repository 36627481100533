/*
 * This is the second level component for Showing each TPS class, reading any available
 * journal entries, and adding journal entries if logged in as an alumni member who
 * has CRUD authority for that TPS class
 * 
 * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
 * 
 * Puts tpsClass from dropdown in useState Hook, and sends the currently selected class to the Store
 * 
 * < />
 *      < />
 *          < />
 *          < />
 *          < />
 *          < />
 *          < />
 */

import React from 'react'
import Grid from "@material-ui/core/Grid"
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import { store } from '../Redux/Store'
import Typography from "@material-ui/core/Typography"

const ShowBioEntriesForOneGrad = props => {

    const { handleEditOfficialTitle, officialTitle, publicBio, showEditButtons } = props
    const { signedInUserId, superUser, userIdOfBioToEdit } = store.getState()

    const handleClick = () => {
        handleEditOfficialTitle()
    }

    return (
        <Grid container>
            <Grid container item xs={1} alignItems='center'>
                { ((signedInUserId === userIdOfBioToEdit) || superUser) && showEditButtons ?
                    <IconButton onClick={handleClick}>
                        {publicBio ? 
                            <Typography variant='h6'>
                                Public Bio
                            </Typography>
                        :
                            <Typography variant='h6'>
                                Private Bio
                            </Typography>
                        }
                    </IconButton>
                    : null
                }
            </Grid>
            <Grid container item xs={10} justifyContent='center' alignItems='center'>
                <Typography variant='h4' >
                    {officialTitle}
                </Typography>
            </Grid>
            <Grid container item xs={1} alignItems='center'>
                { ((signedInUserId === userIdOfBioToEdit) || superUser) && showEditButtons ?
                    <IconButton onClick={handleClick}>
                        <Typography variant='h6'>
                            Edit
                        </Typography>
                    </IconButton>
                    : null
                }
            </Grid>
        </Grid>
    )
}

ShowBioEntriesForOneGrad.propTypes = {
    handleEditOfficialTitle: PropTypes.func,
    officialTitle: PropTypes.string,
    publicBio: PropTypes.bool,
    showEditButtons: PropTypes.bool
}

export default ShowBioEntriesForOneGrad
