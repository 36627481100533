/*
 * This is the Top level component for Showing One Bio via Nodejs / MongoDB
 * 
 * Calls the Nodejs server and gets the bio of a single user
 * 
 * <ShowOneBioPage />
 *      <ShowOfficialTitleForOneGrad />
 *      <ShowBioEntriesForOneGrad />
 *      <ShowMilitaryUnitsForOneGrad />
 *      <ShowUserStoryButtons />
 * 
 * Stores a Story that may contain an array of multiple stories in the Store for
 * use by <ShowAStoryPage />
 */
import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import GetOneBio from "../Data/GetOneBio"
import Grid from "@material-ui/core/Grid"
import MoreInfoComponent from '../Components/MoreInfoComponent'
import { node_server } from '../Helpers'
import Paper from "@material-ui/core/Paper"
import ShowOfficialTitleForOneGrad from '../Components/ShowOfficialTitleForOneGrad'
import ShowBioEntriesForOneGrad from '../Components/ShowBioEntriesForOneGrad'
import ShowMilitaryUnitsForOneGrad from '../Components/ShowMilitaryUnitsForOneGrad'
import ShowBioPhoto from '../Components/ShowBioPhoto'
import ShowUserStoryButtons from '../Components/ShowUserStoryButtons'
import { store } from '../Redux/Store'
import { storyAction } from '../Redux/Actions'

const ShowOneBioPage = props => {

   const { userIdOfBioToShow } = store.getState()

   const [ bio, setBio ] = useState({})
   const [ bioEntry, setBioEntry ] = useState([])
   const [ militaryUnit, setMilitaryUnit ] = useState([])
   const [ numUserStories, setNumUserStories ] = useState(0)
   const [ photoFilename, setPhotoFilename ] = useState('')
   const [ showPhoto, setShowPhoto ] = useState(false)
   const [ showBioPhoto, setShowBioPhoto ] = useState(false)
   const [ story, setStory ] = useState({})

   const getBio = useCallback( (data) => {
      setBio(data)
      setBioEntry(data.bioEntry)
      setMilitaryUnit(data.militaryUnit)
      setPhotoFilename(data.diplomaPhotoFilename)
      if( data.diplomaPhotoFilename !== undefined ){ setShowPhoto(true) }
      if( data.bioPhoto !== undefined ){ setShowBioPhoto(true) }
  }, [] )

   /* Get stories from DB */
   useEffect( () => {
      axios.get(node_server + `story/GetStory/${userIdOfBioToShow}`)
         .then( res => res.data )
         .then( data => {
               /* If a user Story already exists */
               if(data !== ''){
                  /* Store the object locally */
                  setStory(data)
                  /* Storey the object in the store */
                  store.dispatch(storyAction(data))
                  // setStoryText(data.storyText)
                  // console.log('WhowOneBioPage, user ', userIdOfBioToShow, ' has ', data.stories.length, ' stories and that is ', data)
                  /* This is used to index in the array */
                  setNumUserStories(data.stories.length)
               }
               /* If the user does NOT have a Story yet */
               // else{ console.log('WhowOneBioPage, user ', userIdOfBioToShow, 'has no stories yet') }
         } )
   }, [userIdOfBioToShow] )

   const picPathname = `../images/${photoFilename}`

   return (
      <Box style={{marginTop: '6rem'}} >
         <GetOneBio getBio={getBio} id={Number(userIdOfBioToShow)} />
         <Container>
               <Grid>
                  <Paper style={{ backgroundColor: 'rgba(211,211,211,0.5)', padding: '1rem' }}>
                     <Grid>
                           <ShowOfficialTitleForOneGrad
                              officialTitle={bio.officialTitle}
                           />
                     </Grid>
                     <Grid style={{marginTop: '1rem'}} >
                           <ShowBioEntriesForOneGrad
                              bioEntry={bioEntry}
                           />
                     </Grid>
                     <Grid style={{marginTop: '1rem'}} >
                           <ShowMilitaryUnitsForOneGrad
                              militaryUnit={militaryUnit}
                           />
                     </Grid>
                  </Paper>

                  { (numUserStories > 0) ? <ShowUserStoryButtons story={story} /> : null}

                  <MoreInfoComponent tpsClass={userIdOfBioToShow} />

                  <Grid style={{marginTop: '2rem'}}>
                     {showPhoto ?
                           <img
                              style={{width: '100%'}}
                              alt="TPS Diploma"
                              src={picPathname}
                           />
                     : null}
                  </Grid>
                  <Grid style={{marginTop: '2rem'}}>
                     {showBioPhoto ?
                           <ShowBioPhoto bio={bio} />
                     : null}
                  </Grid>
               </Grid>
         </Container>
      </Box>
   )
}

export default ShowOneBioPage
