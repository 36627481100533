import React, { useEffect, useState } from 'react'
import { allGradsAction } from "../Redux/Actions"
import axios from 'axios'
import Box from "@material-ui/core/Box"
import DisplayGrads from "./DisplayGrads"
import Grid from "@material-ui/core/Grid"
import { pathName } from '../Helpers'
import { store } from "../Redux/Store"

const GetGrads = props => {

    const [showDownloadEmailButton, setShowDownloadEmailButton] = useState(false)

    useEffect(() => {

        const { phpFileName } = store.getState()


        axios.get(`${pathName}${phpFileName}.php`)
            .then(res => res.data)
            .then(data => {

                store.dispatch(allGradsAction(data.allGrads))

                /* This only shows the Download E-mail Button if at least one alumni has a valid e-mail listed, otherwise if
                 * the selected list has no valid e-mails for displayed grads the button is not visible */
                data.allGrads.map(grad => {
                    if (grad.personalEmail !== "" || grad.workEmail !== "") {
                        setShowDownloadEmailButton(true)
                    }
                    return 1
                })
            })
    }, [])

    const { allGrads } = store.getState()

    return (
        <Box style={{marginTop: '5rem'}}>
            <Grid container spacing={2} style={{ margin: "1rem" }} alignItems="center">
            </Grid>
            <DisplayGrads allGrads={allGrads} showDownloadEmailButton={showDownloadEmailButton} />
        </Box>
    )
}

export default GetGrads