/*
 * This is the Component for Adding a NEW Photo, Parent Page is ManagePhotoCollectionPhotos
 */
import React, { useState } from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { node_server } from '../Helpers'
import PropTypes from 'prop-types'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'

const AddPhotoToPhotoCollection = ({closeShowAddPHotoToPhotoCollection, selectedCollection}) => {

    const [ description, setDescription ] = useState('')
    const [ filename, setFilename ] = useState('')
    const [ height, setHeight ] = useState(0)
    const [ lastNameFirstName, setLastNameFirstName ] = useState('')
    const [ width, setWidth ] = useState(0)

    const descriptionChange = (e) => {
        setDescription(e.target.value)
    }
    const filenameChange = (e) => {
        switch (selectedCollection) {
            case 'Astronauts (Alphabetical)':
                setFilename('/images/' + e.target.value)
                break
            case 'Class Patches':
                setFilename('/images/class-patches/' + e.target.value)
                break
            case 'Commandants (Chronological)':
                setFilename('/images/' + e.target.value)
                break
            default:
                setFilename(e.target.value)
                break
        }
    }

    const heightChange = (e) => {
        setHeight(Number(e.target.value))
    }

    const lastNameFirstNameChange = (e) => {
        setLastNameFirstName(e.target.value)
    }

    const square = () => {
        setHeight(1)
        setWidth(1)
    }

    const tall = () => {
        setHeight(4)
        setWidth(3)
    }

    const wide = () => {
        setHeight(3)
        setWidth(4)
    }

    const widthChange = (e) => {
        setWidth(Number(e.target.value))
    }

    const onSubmit = () => {
        axios.post(node_server + 'photoCollection/AddPhoto', {
            description,
            filename,
            height,
            lastNameFirstName,
            selectedCollection,
            width
        })
            .then( res => {
                
            })

        closeShowAddPHotoToPhotoCollection()
    }

    return (
        <Grid container style={{marginTop: '2rem'}}>
            {selectedCollection === 'Commandants (Chronological)' ?
                <Grid container justify='flex-start' item xs={12}>
                    <TextField label='lastname,firstname' onChange={lastNameFirstNameChange} variant='outlined' style={{width: '30%', marginLeft: '10.5rem', marginBottom: '1rem'}} />
                </Grid>
                : null
            }
            <Grid container justify='flex-start' item xs={12}>
                <TextField label='Photo Filename, Astronauts userId/filename, Class patch, filename only' onChange={filenameChange} variant='outlined' style={{width: '70%', marginLeft: '10.5rem'}} />
            </Grid>
            <Grid container justify='flex-start' alignItems='center' item xs={12} style={{marginTop: '1rem'}}>
                <Grid>
                    <TextField label='Width Aspect Ratio' onChange={widthChange} variant='outlined' style={{width: '70%', marginLeft: '10.5rem'}} />
                </Grid>
                <Grid>
                    <TextField label='Height Aspect Ratio' onChange={heightChange} variant='outlined' style={{width: '70%', marginLeft: '7rem'}} />
                </Grid>
                <Grid>
                    <Button onClick={square} variant='contained' style={{marginLeft: '3rem'}}>Square Aspect</Button>
                </Grid>
                <Grid>
                    <Button onClick={tall} variant='contained' style={{marginLeft: '3rem'}}>Tall Aspect</Button>
                </Grid>
                <Grid>
                    <Button onClick={wide} variant='contained' style={{marginLeft: '3rem'}}>Wide Aspect</Button>
                </Grid>
            </Grid>
            <Grid container justify='flex-start' item xs={12} style={{marginTop: '1rem'}}>
                <TextareaAutosize placeholder='Photo Description' onChange={descriptionChange} rowsMin={5} style={{width: '70%', marginLeft: '10.5rem'}} />
            </Grid>
            <Grid container justify='flex-start' item xs={3} style={{marginTop: '1rem'}}>
                {filename.length > 0 && description.length > 0 && height > 0 && width > 0 ?
                    <Button color='primary' onClick={onSubmit} variant='contained' style={{width: '70%', marginLeft: '10.5rem'}} >
                        Add New Photo
                    </Button>
                    :
                    <Button color='primary' disabled onClick={onSubmit} variant='contained' style={{width: '70%', marginLeft: '10.5rem'}} >
                        Add New Photo
                    </Button>
                }
            </Grid>
        </Grid>
    )
}

AddPhotoToPhotoCollection.propTypes = {
    closeShowAddPHotoToPhotoCollection: PropTypes.func,
    selectedCollection: PropTypes.string
}

export default AddPhotoToPhotoCollection
