/**
 * 
 */

import React, { useState }                                           from 'react'
import axios                                                         from 'axios'
import AddEditPhotoFieldsComponent                                   from '../Components/AddEditPhotoFieldsComponent'
import { CLIENT_PUBLIC_TOKEN, node_server }                          from '../Helpers'
import { Button, Container, Grid }                                   from '@material-ui/core'
import { Redirect }                                                  from 'react-router-dom'
import Snackbar                                                      from '../Atoms/Snackbar'
import { store }                                                     from '../Redux/Store'

const AddPhoto = () => {

   const [ caption,                 setCaption              ] = useState('')
   const [ fullFilename,            setFullFilename         ] = useState('')
   const [ photoTags,               setPhotoTags            ] = useState('')
   const [ redirectToPhotoSearch,   setRedirectToPhotoSearch ] = useState(false)
   const [ smallFilename,           setSmallFilename        ] = useState('')
   const [ thumbnailFilename,       setThumbnailFilename    ] = useState('')
   const [ snackBarOpen,            setSnackBarOpen         ] = useState(false)
   const [ tpsClass,                setTpsClass             ] = useState('')

   const { username } = store.getState()

   const handleSnackBar = () => {
      setSnackBarOpen(true)
      setTimeout(() => {
         setRedirectToPhotoSearch(true)
     }, 2500)
   }

   const handleReturn = (caption, fullFilename, photoTags, smallFilename, thumbnailFilename, tpsClass) => {
      setCaption(caption)
      setFullFilename(fullFilename)
      setPhotoTags(photoTags)
      setSmallFilename(smallFilename)
      setThumbnailFilename(thumbnailFilename)
      setTpsClass(tpsClass)
   }

   const handleSubmit = async () => {
      let tags = photoTags.split(',')
      let tagArray = []
      tags.forEach(element => {
         tagArray.push({ tagText: element.trim() })
      });
      axios.post( node_server + 'photo', 
      {
         addedBy: username,
         CLIENT_PUBLIC_TOKEN,
         caption,
         fullFilename,
         photoTags: tagArray,
         smallFilename,
         thumbnailFilename,
         tpsClass
      })
      .then( res => {
         if (res.data._id) handleSnackBar()
      })
   }

   return(
      <Container>
         { redirectToPhotoSearch ? <Redirect to='/PhotoSearch' /> : null }
         <AddEditPhotoFieldsComponent
            eCaption={caption}
            eFullFilename={fullFilename}
            handleReturn={handleReturn}
            ePhotoTags={photoTags}
            eSmallFilename={smallFilename}
            title={'Add Photo Page'}
            eThumbnailFilename={thumbnailFilename}
            eTpsClass={tpsClass}
         />

         <Grid item style={{ marginTop: '1rem' }}>
            <Button variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
         </Grid>

         {snackBarOpen ?
            <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
               <Grid item sm={6}>
                     <Snackbar msg={'Your photo was added!'} type={'success'} />
               </Grid>
            </Grid>
            : null
         }
      </Container>
   )
}

export default AddPhoto