import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const EditClassPhoto = ({photoToEdit, updatedDataForDB}) => {

    const [ caption, setCaption ] = useState('')
    const [ classPhotoWidth, setClassPhotoWidth ] = useState('')
    const [ filename, setFilename ] = useState('')


    useEffect( () => {
        setCaption(photoToEdit[0].classPhotoCaption)
        setClassPhotoWidth(photoToEdit[0].classPhotoWidth)
        setFilename(photoToEdit[0].classPhotoFilename)
    }, [photoToEdit] )

    const handleCaptionChange = (e) => {
        setCaption(e.target.value)
    }

    const handleClassPhotoWidthChange = (e) => {
        setClassPhotoWidth(e.target.value)
    }

    const handleFilenameChange = (e) => {
        setFilename(e.target.value)
    }

    const handleSubmit = () => {
        updatedDataForDB({
            caption: caption,
            classPhotoWidth: classPhotoWidth,
            filename: filename
        })
    }

    return (
        <Grid style={{marginTop: '3rem'}}>
            <Container>
                <Grid>
                    <form>
                        <Typography variant='h4' >
                            Edit Class Photo
                        </Typography>
                        <Grid container justify='space-between'>
                            <Grid item xs={12} sm={10} style={{marginTop: '1rem'}} >
                                <TextField value={filename} label='Filename' placeholder='Enter Filename Here' style={{width: '100%'}} onChange={handleFilenameChange} />
                            </Grid>
                            <Grid item xs={12} sm={1} style={{marginTop: '1rem'}} >
                                <TextField value={classPhotoWidth} label='Photo Width' style={{width: '100%'}} onChange={handleClassPhotoWidthChange} />
                            </Grid>
                        </Grid>
                        <Grid>
                            <TextareaAutosize value={caption} placeholder='Enter Photo Caption Here' rowsMin={4} style={{width: '100%', marginTop: '2rem'}} onChange={handleCaptionChange} />
                        </Grid>
                        <Grid container item justify='flex-start' style={{marginTop: '1.5rem'}} >
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Container>
        </Grid>
    )
}

EditClassPhoto.propTypes = {
    photoToEdit: PropTypes.array,
    updatedDataForDB: PropTypes.func
}

export default EditClassPhoto
