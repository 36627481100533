/*
 * This Component is on the <PersonalAndClassStates /> Component
 * The <PersonalAndClassStats /> is on the SearchByName Component
 */

import React from 'react'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { store } from '../../Redux/Store'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const PropWash = ({ editClick, editItem, handleFocus, handleSearch }) => {

   const { classData } = store.getState()

   const editLocalClick = (e) => {
      editClick(e)
   }

   const handleLocalSearch = (e) => {
      handleSearch(e)
      handleFocus()
   }

   return (

   <Container >
      <Grid container >
         <Grid item xs={12} sm={5}>
            <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'left'}} >
            Prop Wash
            </Typography>
         </Grid>
         {classData.propWash ?
         <Grid item xs={12} sm={6}>
            <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'left'}} >
               {classData.propWash.propWashName}
               <Button onClick={() => editLocalClick({
                  editItem: 'propWash'
               })} style={{marginLeft: '2rem'}}>Edit</Button>
            </Typography>
         </Grid>
         :
         <Grid item xs={12} sm={6}>
            <Typography variant='h6' style={{marginTop: '1rem', textAlign: 'left'}} >
               Unknown
               <Button onClick={() => editLocalClick({
                  editItem: 'propWash',
               })} style={{marginLeft: '2rem'}}>Edit</Button>
            </Typography>
         </Grid>
         }
         {editItem === 'propWash' ?
         <Grid item xs={7}>
               <TextField label='Search for Prop Wash' variant='outlined' style={{width: '100%', marginLeft: '0.3rem'}} onFocus={handleLocalSearch} onChange={handleLocalSearch} />
         </Grid>
         :
         null
         }
      </Grid>
   </Container>
   )
}

PropWash.propTypes = {
   editClick: PropTypes.func,
   editItem: PropTypes.string,
   handleFocus: PropTypes.func,
   handleSearch: PropTypes.func
 }

export default PropWash
