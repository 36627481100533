/*
 * This is the second level component for Showing each TPS class, reading any available
 * journal entries, and adding journal entries if logged in as an alumni member who
 * has CRUD authority for that TPS class
 * 
 * Calls the PHP server and gets a list of all the TPS Classes from tbl_class_page
 * 
 * Puts tpsClass from dropdown in useState Hook, and sends the currently selected class to the Store
 * 
 * <ClassPages />
 *      <ShowOneClassPage />
 *          <ShowGradsFromOneClass />
 *          <ShowJournalEntriesFromOneClass />
 *          <AddRemoveButtons />
 *          <AddJournalEntry />
 *          <EditJournalEntry />
 */

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Grid from "@material-ui/core/Grid"
import IconButton from '@material-ui/core/IconButton'
import { node_server } from '../Helpers'
import Paper from "@material-ui/core/Paper"
import PropTypes from 'prop-types'
import { store } from '../Redux/Store'
import Typography from "@material-ui/core/Typography"

const ShowJournalEntriesFromOneClass = props => {

    const { handleEditOneJournalEntry, handleGetJournal, numberOfJournalEntries, tpsClass } = props

    const { signedInUserId, userIdAuthorizedToCrudWeeklyUpdate } = store.getState()

    const [journalEntryArray, setJournalEntryArray] = useState([])

    // Calls the Node server and gets the selected class info from db tps_alumni, collection journals
    useEffect(() => {
        axios.get(node_server + `journal/class/${tpsClass}`)
            .then(res => res.data)
            .then(data => {
                if (data === '') {
                    setJournalEntryArray({ text: 'There are no weekly journal entries for this class' })
                } else {
                    setJournalEntryArray(data.journalEntry)
                    handleGetJournal(data)
                }
            })
    }, [tpsClass, numberOfJournalEntries, handleGetJournal])

    const handleClick = (id) => {
        handleEditOneJournalEntry(id)
    }

    var journalEntriesToDisplay = null

    if(journalEntryArray.length > 0){
    journalEntriesToDisplay = journalEntryArray.map( journalEntry => {
        return(<Grid key={journalEntry._id} style={{ marginTop: '2rem' }}>
                    <Paper style={{ backgroundColor: 'rgba(211,211,211,0.5)', padding: '1rem' }}>
                        <Grid container>
                            <Grid container item xs={11} alignItems='center'>
                                <Typography variant='h4' style={{ textAlign: 'left' }}>
                                    {journalEntry.title}
                                </Typography>
                            </Grid>
                            <Grid container item xs={1} alignItems='center'>
                                {signedInUserId === userIdAuthorizedToCrudWeeklyUpdate ?
                                <IconButton onClick={() => handleClick(journalEntry._id)}>
                                    <Typography variant='h6'>
                                        Edit
                                    </Typography>
                                </IconButton>
                                : null
                                }
                            </Grid>
                        </Grid>
                        <Typography variant='h6' style={{ textAlign: 'left' }}>
                            {journalEntry.text}
                        </Typography>
                    </Paper>
                </Grid>)
    } ) } 

    return (
        <Grid>
            { journalEntriesToDisplay }
        </Grid>
    )
}

ShowJournalEntriesFromOneClass.propTypes = {
    handleEditOneJournalEntry: PropTypes.func,
    handleGetJournal: PropTypes.func,
    numberOfJournalEntries: PropTypes.number,
    tpsClass: PropTypes.string
}

export default ShowJournalEntriesFromOneClass
