/*
 * This page adds a story and is used by both alumni and superUsers
 *
 * <AddAStoryPage />
 *      <AddAStoryTitleComponent />
 *      <ShowAStoryTitle />
 *      <ShowAStoryComponent />
 *      <AddAStoryTextBlockComponent />
 */
import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const ShowAStoryTitle = ({storyTitle}) => {
    return (
        <Box>
            <Container>
                <Grid style={{marginTop: '2rem'}}>
                    <Typography variant='h5'>
                        {storyTitle}
                    </Typography>
                </Grid>
            </Container>
        </Box>
    )
}

ShowAStoryTitle.propTypes = {
    storyTitle: PropTypes.string
}

export default ShowAStoryTitle
