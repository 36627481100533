import React, { useEffect, useState } from 'react'
import axios from "axios"
import Box from '@material-ui/core/Box'
import { get_single_announcement } from '../Helpers'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Paper from '@material-ui/core/Paper'
import { Redirect } from "react-router-dom"
import { store } from "../Redux/Store"
import Typography from '@material-ui/core/Typography'

const ShowOneAnnouncement = () => {

    const [ announcement, setAnnouncement ] = useState('')
    const [ backToAnnouncementPage, setBackToAnnouncementPage ] = useState(false)

    useEffect( () => {
        const { announcementId } = store.getState()

        let getOneAnnouncementData = new FormData()

        getOneAnnouncementData.append('id', announcementId)

        axios.post(get_single_announcement, getOneAnnouncementData)
            .then(res => res.data)
            .then(data => {
                setAnnouncement(data.announcement)
                return 1
            })
    }, [] )

    const handleBack = () => {
        setBackToAnnouncementPage(true)
    }

    const { announcementPara1, announcementPara2, announcementPara3, announcementPara4, announcementPara5,
        announcementPara6, announcementPara7, announcementPara8, announcementPara9, announcementPara10,
        announcementSynopsis, title } = announcement

    return (
        <Box style={{marginTop: '8rem'}}>
            { (backToAnnouncementPage) ? <Redirect to="/" /> : null }
            <Grid container direction="row" style={{ margin: "4rem", textAlign: "left", width: "90%" }}>
                <Paper style={{ padding: "2rem", backgroundColor: "rgba(211, 211, 211, 0.25)" }}>
                    <Grid item xs={12} style={{marginBottom: '1rem'}}>
                        <IconButton onClick={handleBack} style={{border: '0.5px solid white', backgroundColor: 'blue'}}>
                            <KeyboardBackspaceIcon style={{color: 'white'}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <strong>Title:</strong> {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
                        <Typography variant="h6">
                            <strong>Synopsis:</strong> {announcementSynopsis}
                        </Typography>
                    </Grid>
                    {announcementPara1 !== null ?
                        <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
                            <Typography variant="h6">
                                <strong>Body:</strong>
                            </Typography>
                        </Grid>
                        : null}
                    {announcementPara1 !== null ?
                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                            <Typography>{announcementPara1}</Typography>
                        </Grid>
                        : null}
                    {announcementPara2 !== null ?
                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                            <Typography>{announcementPara2}</Typography>
                        </Grid>
                        : null}
                    {announcementPara3 !== null ?
                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                            <Typography>{announcementPara3}</Typography>
                        </Grid>
                        : null}
                    {announcementPara4 !== null ?
                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                            <Typography>{announcementPara4}</Typography>
                        </Grid>
                        : null}
                    {announcementPara5 !== null ?
                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                            <Typography>{announcementPara5}</Typography>
                        </Grid>
                        : null}
                    {announcementPara6 !== null ?
                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                            <Typography>{announcementPara6}</Typography>
                        </Grid>
                        : null}
                    {announcementPara7 !== null ?
                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                            <Typography>{announcementPara7}</Typography>
                        </Grid>
                        : null}
                    {announcementPara8 !== null ?
                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                            <Typography>{announcementPara8}</Typography>
                        </Grid>
                        : null}
                    {announcementPara9 !== null ?
                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                            <Typography>{announcementPara9}</Typography>
                        </Grid>
                        : null}
                    {announcementPara10 !== null ?
                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                            <Typography>{announcementPara10}</Typography>
                        </Grid>
                        : null}
                </Paper>
            </Grid>
        </Box>
    )
}

export default ShowOneAnnouncement
