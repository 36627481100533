/**
 * 
 */

import React, { useState }                                           from 'react'
import axios                                                         from 'axios'
import AddEditPhotoFieldsComponent                                   from '../Components/AddEditPhotoFieldsComponent'
import PropTypes                                                     from 'prop-types'
import { CLIENT_PUBLIC_TOKEN, node_server }                          from '../Helpers'
import { Button, Container, Grid }                                   from '@material-ui/core'
import Snackbar                                                      from '../Atoms/Snackbar'
import { store }                                                     from '../Redux/Store'

const EditPhoto = props => {

   const { eCaption, eFullFilename, ePhotoTags, eSmallFilename, eThumbnailFilename, eTpsClass, handleCloseEditPhoto, id } = props

   const [ caption,              setCaption              ] = useState('')
   const [ fullFilename,         setFullFilename         ] = useState('')
   const [ msg,                  setMsg                  ] = useState('')
   const [ photoTags,            setPhotoTags            ] = useState('')
   const [ smallFilename,        setSmallFilename        ] = useState('')
   const [ thumbnailFilename,    setThumbnailFilename    ] = useState('')
   const [ snackBarOpen,         setSnackBarOpen         ] = useState(false)
   const [ tpsClass,             setTpsClass             ] = useState('')
   const [ type,                 setType                 ] = useState('')

   const { username } = store.getState()

   const handleSnackBar = () => {
      setSnackBarOpen(true)
      setTimeout(() => {
         setSnackBarOpen(false)
         handleCloseEditPhoto()
     }, 2500)
   }

   const handleReturn = (caption, fullFilename, photoTags, smallFilename, thumbnailFilename, tpsClass) => {
      setCaption(caption)
      setFullFilename(fullFilename)
      setPhotoTags(photoTags)
      setSmallFilename(smallFilename)
      setThumbnailFilename(thumbnailFilename)
      setTpsClass(tpsClass)
   }

   const handleSubmit = async () => {
      let tags = photoTags.split(',')
      let tagArray = []
      tags.forEach(element => {
         tagArray.push({ tagText: element.trim() })
      });
      axios.post( node_server + 'photo/update', 
      {
         addedBy: username,
         CLIENT_PUBLIC_TOKEN,
         caption,
         fullFilename,
         id,
         photoTags: tagArray,
         smallFilename,
         thumbnailFilename,
         tpsClass
      })
      .then( res => {
         if (res.data._id) {
            setMsg('Your photo was edited!')
            setType('success')
            handleSnackBar()
         }
         if (res.data === 'error') {
            setMsg('There was an error on edit.')
            setType('error')
            handleSnackBar()
         }
         setCaption('')
         setFullFilename('')
         setPhotoTags('')
         setSmallFilename('')
         setThumbnailFilename('')
         setTpsClass('')
      })
   }

   return(
      <Container style={{marginBottom: '5rem'}}>
         {snackBarOpen ?
            <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
               <Grid item sm={6}>
                     <Snackbar msg={msg} type={type} />
               </Grid>
            </Grid>
            : null
         }
         <AddEditPhotoFieldsComponent
            eCaption={eCaption}
            eFullFilename={eFullFilename}
            ePhotoTags={ePhotoTags}
            eSmallFilename={eSmallFilename}
            handleReturn={handleReturn}
            title={'Edit Photo Page'}
            eThumbnailFilename={eThumbnailFilename}
            eTpsClass={eTpsClass}
         />

         <Grid item style={{ marginTop: '1rem' }}>
            <Button variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
         </Grid>

      </Container>
   )
}

AddEditPhotoFieldsComponent.propTypes = {
   eCaption: PropTypes.string,
   eFullFilename: PropTypes.string,
   ePhotoTags: PropTypes.string,
   handleCloseEditPhoto: PropTypes.func
}

export default EditPhoto