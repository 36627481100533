/*
 *  Alumni edits a bio entry paragraph in their bio
 */
import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const EditBioEntryForOneGrad = props => {

    const { bio, bioEntryIdToEdit, handleCancelEditBioEntryForOneGrad, handleSubmitEditBioEntryForOneGrad } = props
    
    const [sectionText, setSectionText] = useState('')
    const [sectionTextCount, setSectionTextCount] = useState(0)
    const [sectionTitle, setSectionTitle] = useState('')
    const [indexToEdit, setIndexToEdit] = useState(-1)

    useEffect( () => {
        bio.bioEntry.filter( (bioEntry, index) => {
            if(bioEntry._id === bioEntryIdToEdit){
                setSectionText(bioEntry.text)
                setSectionTitle(bioEntry.title)
                setIndexToEdit(index)
            }
            return null
        } )
    }, [bio.bioEntry, bioEntryIdToEdit] )

    const handleSectionTextChange = (e) => {
        setSectionTextCount(e.target.value.length)
        setSectionText(e.target.value)
    }

    const handleSectionTitleChange = (e) => {
        setSectionTitle(e.target.value)
    }

    const handleSubmit = () => {
        bio.bioEntry[indexToEdit].text = sectionText
        bio.bioEntry[indexToEdit].title = sectionTitle
        handleCancelEditBioEntryForOneGrad()
        handleSubmitEditBioEntryForOneGrad(bio)
    }

    const handleCancel = () => {
        handleCancelEditBioEntryForOneGrad()
    }

    return (
        <Box style={{ marginTop: '3rem', marginBottom: '3rem' }} >
            <Container>
                <Grid container>

                    <Grid item xs={12} style={{ marginTop: '3rem', marginBottom: '1rem' }} >
                        <TextField value={sectionTitle} autoFocus={true} name='Title' label='Title' variant='outlined' style={{ width: '100%' }} onChange={handleSectionTitleChange} />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                        <TextareaAutosize value={sectionText} name='bioEntry' placeholder='2,400 Characters Only!' rowsMin={5} style={{ width: '100%', fontSize: '1.5rem', padding: '0.5rem' }} onChange={handleSectionTextChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='h6' style={{textAlign: 'left', marginBottom: '0.5rem'}}>
                            Characters Remaining: {2400 - sectionTextCount}
                        </Typography>
                    </Grid>

                    <Grid container style={{marginTop: '2rem'}}>
                        <Grid item xs={12} md={1} style={{marginRight: '2rem'}}>
                            <Button variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Button variant='contained' color='primary' onClick={handleCancel}>Cancel</Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    )
}

EditBioEntryForOneGrad.propTypes = {
    bio: PropTypes.object,
    bioEntryIdToEdit: PropTypes.string,
    handleCancelEditBioEntryForOneGrad: PropTypes.func,
    handleEditBioEntryForOneGrad: PropTypes.func,
    handleSubmitEditBioEntryForOneGrad: PropTypes.func
}

export default EditBioEntryForOneGrad
