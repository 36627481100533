/*
 * This is the top level Page for displaying photos to users. It is
 * reached from the PHOTOS button on the navbar and the user does
 * NOT have to be signed in to see the photos.
 */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { node_server } from '../Helpers'
// import PhotoCollectionsPopper from '../Components/PhotoCollectionsPopper'
// import Popper from '@material-ui/core/Popper';
// import ShowPhotosFromPhotoCollection from '../Components/ShowPhotosFromPhotoCollection'
import ShowPhotosFromPhotoCollectionBasicRows from '../Components/ShowPhotosFromPhotoCollectionBasicRows'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

const PhotoCollections = () => {

    const numPhotosToGetOnIntialLoad = 10

    const [ collectionNames, setCollectionNames ] = useState([])
    const [ photos, setPhotos ] = useState([])
    const [ selectedCollection, setSelectedCollection ] = useState('')

    /* Get ALL Collections Names and ALL Photos, but filter down to the first X photos
       as dictated by numPhotosToGetOnIntialLoad, this is typically the first X number
       of photos out of the first Collection retrieved from the db */
    useEffect(() => {
        if (selectedCollection === '') {
            axios.get(node_server + 'photoCollection/GetAllCollections')
            .then(res => {
                let photoArray = res.data.collectionNames.map((photo) => photo.photos)
                setPhotos(photoArray.flat().filter((photo, i) => {
                    if (i < numPhotosToGetOnIntialLoad) {
                        return photo
                    }
                    return null
                }))

                /* Alphabetize by collectonName */
                const tempNames = res.data.collectionNames
                let aSwapWasMade = true
                let tempCollectionName = {}
                while (aSwapWasMade) {
                    aSwapWasMade = false
                    for (let i = 0; i < tempNames.length - 1; i++) {
                        if (tempNames[i].photoCollectionName > tempNames[i + 1].photoCollectionName) {
                            aSwapWasMade = true
                            tempCollectionName = tempNames[i + 1]
                            tempNames[i + 1] = tempNames[i]
                            tempNames[i] = tempCollectionName
                        }
                    }
                }
                setCollectionNames(tempNames)
            })
        }
    }, [selectedCollection])

    // /* Get ALL Collections Names and ALL Photos */
    // useEffect(() => {
    //     if (selectedCollection === 'Show All Photos') {
    //         axios.get(node_server + 'photoCollection/GetAllCollections')
    //         .then(res => {
    //             /* The Commandants are in the database twice, so I need to strip out one
    //                of the collections in order to not show each Commandant twice */
    //             const collectionsToShow = res.data.collectionNames.filter((collection) => {
    //                 if (collection.photoCollectionName === 'Commandants (Alphabetical)') {
    //                     return null
    //                 }
    //                 return collection
    //             })

    //             let photoArray = collectionsToShow.map((photo) => photo)
    //             photoArray = photoArray.map((photo) => photo.photos)
    //             setPhotos(photoArray.flat())

    //         })
    //     }
    // }, [selectedCollection])

    /* Get a particular Collection */
    useEffect(() => {
        if (selectedCollection !== '' && selectedCollection !== 'Show All Photos') {
            axios.get(node_server + `photoCollection/GetACollection/${selectedCollection}`)
            .then(res => {
                if (res.data.collectionFromDB.photoCollectionName === 'Commandants (Chronological)'){
                    console.log('Getting ready to put Commandants (Chronological) Collection into Alphabetical Order')
                    setPhotos(res.data.collectionFromDB.photos)
                } else {
                    setPhotos(res.data.collectionFromDB.photos)
                }
            })
        }
    }, [selectedCollection])

    const collectionNamesForDropdown = collectionNames.map((name, i) => <MenuItem key={i} value={name.photoCollectionName}>{name.photoCollectionName}</MenuItem>)

    const getSelectedCollection = (e) => {
        setSelectedCollection(e.target.value)
    }

    return (
        <Grid>
            <Grid
                container
                alignContent='center'
                style={{
                    backgroundImage: 'url("/images/photo-collections/343641main_E-USAF-X-15_cropped.jpg")',
                    // backgroundImage: 'url("https://images.unsplash.com/photo-1616161424040-735b60c86af2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '25rem',
                    marginTop: '2rem'
                }}
            >
                <Grid container item justifyContent='flex-start' xs={12} style={{marginLeft: '5rem'}} >
                    <Typography variant='h3' style={{color: 'white', marginTop: '6rem'}}>
                        TPS Photo Collections
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid container style={{marginLeft: '5rem', marginTop: '1rem'}}>
                        <FormControl variant='outlined'>
                            <InputLabel>Available Collections</InputLabel>
                            <Select
                                label='Available Collections'
                                onChange={getSelectedCollection}
                                value={selectedCollection}
                                style={{
                                    backgroundColor: 'white',
                                    borderRadius: '0.55rem',
                                    width: '500px'
                                }}
                            >
                                {collectionNamesForDropdown}
                                {/* <MenuItem value='Show All Photos'>Show All Photos</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <ShowPhotosFromPhotoCollectionBasicRows photos={photos} selectedCollection={selectedCollection} />
        </Grid>
    )
}

export default PhotoCollections
